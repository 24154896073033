
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActiveHoursTable } from '@client/components/ActiveHours/index';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';

@Component({
  components: {
    ActiveHours: ActiveHoursTable,
  },
})
export default class SectionActiveHours extends Vue {
  @Prop({ default: false })
  private isIcon!: boolean;
  @Prop()
  private storeId!: string;
  @Prop()
  private activeHoursSchedule!: ActiveHoursSchedule;
  @Prop()
  private storeActiveHoursSchedule!: ActiveHoursSchedule;
  @Prop()
  private gondolaId!: string;
  @Prop()
  private isMissingAzureId!: boolean;
  @Prop()
  private isDisabled!: boolean;
  @Prop()
  private activeHoursJobFailed!: boolean;

  private showError: boolean = false;

  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();

  async onSaveHandler(submittedActiveHoursSchedule: ActiveHoursSchedule): Promise<void> {
    const isFinalized: boolean = await this.activeHoursStore.submitSectionActiveHours(
      this.storeId,
      this.gondolaId,
      submittedActiveHoursSchedule
    );
    this.showError = !isFinalized;
  }
  async onResetHandler(): Promise<void> {
    await this.activeHoursStore.resetSectionActiveHours(this.storeId, this.gondolaId);
  }

  closeErrorModal(): void {
    this.showError = false;
  }
}
