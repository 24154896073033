enum PublishState {
  INITIALIZING = 'INITIALIZING',
  ACCEPTED = 'ACCEPTED',
  DIMENSION_MISMATCH = 'DIMENSION_MISMATCH',
  PUBLISHING = 'PUBLISHING',
  PUBLISHING_FAILED = 'PUBLISHING_FAILED',
  PUBLISHED = 'PUBLISHED',
  CANCELLED = 'CANCELLED',
  EXECUTING = 'EXECUTING',
}

export default PublishState;
