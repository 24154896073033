
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { DeviceSearchResult, Gondola, GondolaPublishing, Device, Store } from '@client/models';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import DeviceDetail from '@client/components/DeviceDetail/DeviceDetail.vue';
import DeviceDetailLoader from '@client/components/DeviceDetail/DeviceDetailLoader.vue';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import StoreAndSectionActiveHours from '@client/models/ActiveHoursModels/StoreAndSectionActiveHours';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';

@Component({
  components: { DeviceDetailLoader, DeviceDetail },
})
export default class DeviceDetailView extends Vue {
  /* DECLARATIONS */
  private devicesStore: DevicesStore = useDevicesStore();
  private storesStore: StoresStore = useStoresStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private deviceActiveHours: Optional<StoreAndSectionActiveHours> = null;

  private deviceData: Optional<DeviceSearchResult> = null;
  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    await this.onRouteChanged();
  }

  /* METHODS */

  @Watch('$route.fullPath')
  async onRouteChanged(): Promise<void> {
    this.breadcrumbsStore.replace({
      path: '/devices/',
      title: {
        key: this.$i18nTranslationKeys.devices.overviewBreadcrumb.$path,
      },
    });
    this.breadcrumbsStore.push({
      path: `${this.$route.fullPath}`,
      title: {
        key: this.$i18nTranslationKeys.deviceDetail.breadcrumb.$path,
        params: { longId: this.deviceId },
      },
    });
    await this.storesStore.fetch();
    this.deviceData = await this.devicesStore.getDevice(this.deviceId);
  }

  @Watch('deviceStore')
  async onStoreLoad(): Promise<void> {
    if (this.deviceStore) {
      await this.activeHoursStore.fetchActiveHours(this.deviceStore._id);
      this.deviceActiveHours = this.activeHoursStore.getActiveHoursByStoreId(this.deviceStore._id);
    }
  }

  onDeviceSync(updatedRail: Device): void {
    if (this.deviceData) {
      this.deviceData.device = updatedRail;
    }
  }
  /* GETTERS */
  get deviceId(): string {
    return this.$route.params.longId;
  }

  get device(): Optional<Device> {
    return this.deviceData?.device;
  }

  get lastPublishing(): Optional<GondolaPublishing> {
    return this.deviceData?.publishing;
  }

  get deviceStore(): Optional<Store> {
    if (!this.device) {
      return undefined;
    }
    return this.storesStore.getStoreById(this.device.storeId);
  }

  get deviceGondola(): Optional<Gondola> {
    if (!this.device) {
      return undefined;
    }
    return this.storesStore.getGondolaByDeviceId(this.device.longId)?.gondolaModel;
  }

  get isSearching(): boolean {
    return this.devicesStore.isSearching || this.storesStore.isFetching;
  }
}
