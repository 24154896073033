
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { Optional } from '@common/types';
import { TranslateResult } from 'vue-i18n';
import { PrefillOption } from '@client/definitions/dateselector';
import { getDateRangePrefill, getDateTimePrefill, getIsoDate, isEndDateBeforeStartDate } from './utils';
import { DateFilterPrefill, defaultEndTime, defaultStartTime } from './types';
import Moment from 'moment';
import TimePicker from '@client/components/TimePicker/TimePicker.vue';
import { isEndTimeBeforeStartTime, timeValidationRegex } from '@client/utils/DateTimeUtils';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { END_OF_DAY_TIME_WITH_SECONDS, START_OF_DAY_TIME } from '@client/models';

/**
 * Array of date prefill options
 */
export const datePrefillOptions = (): Array<PrefillOption> => [
  {
    text: i18n.t(TranslationKeys.filtersAndPagination.dateFilterPrefill.oneHour.$path),
    value: DateFilterPrefill.ONE_HOUR,
  },
  {
    text: i18n.t(TranslationKeys.filtersAndPagination.dateFilterPrefill.oneDay.$path),
    value: DateFilterPrefill.ONE_DAY,
  },
  {
    text: i18n.t(TranslationKeys.filtersAndPagination.dateFilterPrefill.oneWeek.$path),
    value: DateFilterPrefill.ONE_WEEK,
  },
  {
    text: i18n.t(TranslationKeys.filtersAndPagination.dateFilterPrefill.oneMonth.$path),
    value: DateFilterPrefill.ONE_MONTH,
  },
  {
    text: i18n.t(TranslationKeys.filtersAndPagination.dateFilterPrefill.custom.$path),
    value: DateFilterPrefill.CUSTOM,
  },
];

/**
 * Renders a date picker that returns an array of one or two [string,string?] iso string dates
 * Needs an array of strings variable as model
 */
@Component({
  components: { TimePicker },
})
export default class DatePicker extends Vue {
  /**
   * Loading state
   * @private
   */
  @Prop({ type: Boolean })
  private isLoading!: boolean;

  /**
   * v-model value of the component
   * @private
   */
  @Model('change', { default: '' })
  private dateRangeValue!: [string?, string?];
  /**
   * Is the default display of the component compact
   * @private
   */
  @Prop({ type: Boolean, default: true })
  private isCompact?: boolean;
  /**
   * If true, this will prompt a close icon and clears the value on icon click
   * @private
   */
  @Prop({ type: Boolean, default: true })
  private isClearable?: boolean;
  /**
   * Default selected datetime prefill value
   * @private
   */
  @Prop()
  private defaultSelectedDateTimePrefill?: DateFilterPrefill;
  /**
   * Class or classes to assign to the external div of the component
   * @private
   */
  @Prop()
  private wrapperClass?: string;
  /**
   * If false, this will not trigger the onChange event when the user selects only one value
   * @private
   */
  @Prop({ type: Boolean, default: true })
  private allowSingleValue?: boolean;
  @Prop({ default: '' })
  /**
   * Cypress selector id
   */
  private dataCy?: string;
  @Prop()
  private minimumDate?: string;
  @Prop({ default: new Date().toISOString() })
  private maximumDate?: string;
  private pickerDateRange: [string?, string?] = [];
  private selectedDateTimePrefill: string = '';
  private dateFocused: boolean = false;
  private dateModalOpen: boolean = false;

  private startTime: string = defaultStartTime;
  private endTime: string = defaultEndTime;

  /* LIFECYCLE EVENTS */

  created(): void {
    if (this.defaultSelectedDateTimePrefill) {
      this.selectedDateTimePrefill = this.defaultSelectedDateTimePrefill;
      this.onDatePrefillSelectionChange(this.defaultSelectedDateTimePrefill, false);
    }
  }

  /* METHODS */

  onClearDateRange(): void {
    this.$emit('change', []);
    this.startTime = defaultStartTime;
    this.endTime = defaultEndTime;
  }

  @Watch('selectedDateTimePrefill')
  onDatePrefillChange(): void {
    this.$emit('prefillChange', this.selectedDateTimePrefill);
  }

  @Watch('pickerDateRange', { deep: true })
  @Watch('endTime')
  @Watch('startTime')
  onDateValueChange(): void {
    if (this.selectedDateTimePrefill !== DateFilterPrefill.CUSTOM) {
      return;
    }
    (this.$refs.endTime as TimePicker | undefined)?.onChangeOrInput(this.endTime);
    if (this.pickerDateRange.length === 1 && !this.allowSingleValue) {
      return;
    }
    const formattedEndTime: string = this.endTime === START_OF_DAY_TIME ? END_OF_DAY_TIME_WITH_SECONDS : this.endTime;
    if (this.pickerDateRange.length === 1 && this.pickerDateRange[0]) {
      // If only one date is picked return only start date
      this.$emit('change', [this.getFullDate(this.pickerDateRange[0], this.startTime)]);
    } else {
      let startDate: Moment.Moment = Moment(this.pickerDateRange[0]);
      let endDate: Moment.Moment = Moment(this.pickerDateRange[1]);
      let startTime: string = this.startTime;
      let endTime: string = formattedEndTime;
      // If the user picked times that are reversed, put them in the right order
      if (isEndTimeBeforeStartTime(this.startTime, this.endTime)) {
        startTime = formattedEndTime;
        endTime = this.startTime;
      }
      // If the user picked dates that are reversed, put them in the right order
      if (endDate.isBefore(startDate)) {
        startDate = Moment(this.pickerDateRange[1]);
        endDate = Moment(this.pickerDateRange[0]);
      }
      this.$emit('change', [
        this.getFullDate(startDate.format('YYYY-MM-DD'), startTime),
        this.getFullDate(endDate.format('YYYY-MM-DD'), endTime),
      ]);
    }
  }

  onDatePrefillSelectionChange(option: DateFilterPrefill, emitChange: boolean): void {
    this.selectedDateTimePrefill = option;
    if (option === DateFilterPrefill.CUSTOM) {
      this.pickerDateRange[0] = this.dateRangeValue[0];
      this.pickerDateRange[1] = this.dateRangeValue[1] || this.dateRangeValue[0];
      this.dateModalOpen = true;
      return;
    } else {
      const timeRangeValue: [string, string] = getDateTimePrefill(option);
      this.startTime = timeRangeValue[0];
      this.endTime = timeRangeValue[1];
    }
    if (emitChange) {
      this.emitChange();
    }
  }

  emitChange(): void {
    if (!this.selectedDateTimePrefill) {
      return;
    }
    const dateRange: Array<string> = getDateRangePrefill(this.selectedDateTimePrefill as DateFilterPrefill);
    this.$emit('change', [
      this.getFullDate(dateRange[0], this.startTime),
      this.getFullDate(dateRange[1], this.endTime),
    ]);
  }

  getFullDate(date: Optional<string>, time: Optional<string>): string {
    if (!date || !time) {
      return '';
    }
    return getIsoDate(date, time);
  }

  validateStartTime(value: string): Array<string | TranslateResult> {
    const isValid: boolean = timeValidationRegex.test(value);
    if (isValid) {
      this.startTime = value;
      (this.$refs.endTime as TimePicker | undefined)?.onChangeOrInput(this.endTime);
      return [];
    }
    return [this.$t(this.$i18nTranslationKeys.schedules.errors.startTime.$path)];
  }

  validateEndTime(value: string): Array<string | TranslateResult> {
    const isValidValue: boolean = timeValidationRegex.test(value);
    const endTime: string = value === START_OF_DAY_TIME ? END_OF_DAY_TIME_WITH_SECONDS : value;
    const isEndTimeBigger: boolean = isEndDateBeforeStartDate(
      this.getFullDate(this.dateRangeValue[0], this.startTime),
      this.getFullDate(this.dateRangeValue[1], endTime)
    );
    if (isValidValue && !isEndTimeBigger) {
      this.endTime = value;
      return [];
    }
    return [this.$t(this.$i18nTranslationKeys.schedules.errors.endTime.$path)];
  }

  onFocusDateField(): void {
    this.dateFocused = true;
  }

  onFocusOutDateField(): void {
    this.dateFocused = false;
  }

  /* GETTERS */

  get selectedDateTimeText(): Optional<TranslateResult> {
    if (this.dateRangeValue.filter(Boolean).length === 0) {
      this.selectedDateTimePrefill = '';
      return '';
    }
    if (this.selectedDateTimePrefill !== DateFilterPrefill.CUSTOM) {
      return (
        datePrefillOptions().find(
          (prefillOption: PrefillOption) => prefillOption.value === this.selectedDateTimePrefill
        )?.text || ''
      );
    }
    if (this.dateRangeValue.filter(Boolean).length === 1) {
      return `${this.dateRangeValue[0]?.split('T')[0]} ${this.startTime}`;
    }
    return `${this.dateRangeValue[0]?.split('T')[0]} ${this.startTime} - ${this.dateRangeValue[1]?.split('T')[0]} ${
      this.endTime === START_OF_DAY_TIME ? '23:59' : this.endTime
    }`;
  }

  get datePrefillOptions(): Array<PrefillOption> {
    return datePrefillOptions();
  }

  get dateCompactClass(): Optional<string> {
    if (this.dateFocused || this.selectedDateTimePrefill || !this.isCompact) {
      return null;
    }
    return 'date-compact';
  }

  get isDataLoading(): boolean {
    return this.isLoading;
  }
}
