import { defineStore, StoreDefinition, Store } from 'pinia';
import {
  Aisle,
  Gondola,
  Gondola as GondolaModel,
  GondolaPublishing,
  Device,
  Store as StoreModel,
} from '@client/models';
import { Optional } from '@common/types';
import { GondolaPublishingJSON } from '@common/publishing/types';
import { getGondolaAisles } from '@client/utils/StoreUtils';
import Vue from 'vue';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { StoreJSON } from '@common/stores/types';
import Config from '@client/utils/config';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { StoreGondolaWrapper } from '@client/stores/stores';
import { BaseError } from '@common/error/types';

export interface StoresGetters {
  getStoreById: (state: StoresState) => (id: string) => Optional<StoreModel>;
  getGondolaById: (state: StoresState) => (storeId: string, gondolaId: string) => Optional<Gondola>;
  getGondolaByDeviceId: (state: StoresState) => (deviceId: string) => Optional<StoreGondolaWrapper>;
  getAisleOfStore: (state: StoresState) => (storeId: string, aisleName: string) => Optional<Aisle>;
  getAisleNamesOfStore: (state: StoresState) => (storeId: string) => Array<string>;
  getStoresWithValidAzureId: (state: StoresState) => () => Array<StoreModel>;
  getGondolasWithSameLayout: (state: StoresState) => (layoutHash: string) => Array<Gondola>;
  getStoreWithGondolaId: (state: StoresState) => (gondolaId: string) => Optional<StoreModel>;
}

export interface StoresActions {
  add(newStore: StoreModel): Promise<string>;
  updateStores(storesToUpdate: StoreModel[]): void;
  setUpdatingStoreLoadingIndicator(loadingState: boolean): void;
  setDeletingStoreLoadingIndicator(loadingState: boolean): void;
  resetLoadingIndicators(): void;
  clear(): void;
  setFetched(fetched: boolean): void;
  setIsFetching(isFetching: boolean): void;
  updateLastPublishingForGondola(gondola: GondolaModel, gondolaPublishingModel: GondolaPublishing): void;
  populateDevice(store: StoreModel, device: Device): void;
  updateStoreHash(storeId: string, hash: string): void;
  fetch(): Promise<void>;
  fetchStore(storeId: string, skipSync?: boolean): Promise<Optional<StoreModel>>;
  fetchPublishings(storeId: string): Promise<void>;
  updatePublishing(gondolaPublishing: GondolaPublishing): Promise<void>;
  updateStoreIdAzure(storeIdAzure: string, storeId: string): Promise<void>;
  updateStoreName(storeName: string, storeId: string): Promise<void>;
  updateStoreTimezone(timezone: string, storeId: string): Promise<void>;
  deleteStore(storeId: string): Promise<void>;
  addGondola(gondola: GondolaModel, storeId: string, force: boolean): Promise<void>;
  editGondola(gondola: GondolaModel, storeId: string, force: boolean): Promise<void>;
  deleteGondola(gondolaId: string, storeId: string): Promise<void>;
  populateDevices(storeId: string): Promise<void>;
  populateStoreGondolas(store: StoreModel): void;
  syncDevices(storeId: string): Promise<void>;
  setStoreSyncingState(storeId: string, isStoreSyncing: boolean): void;
}

export interface StoresState {
  stores: Array<StoreModel>;
  loadingIndicator: {
    delete: boolean;
    update: boolean;
  };
  fetched: boolean;
  /*
  Fetching status used to prevent concurrent calls
  If this is set to true and a fetch is called, it will be ignored
   */
  isFetching: boolean;
}

export type StoresStoreDefinition = StoreDefinition<'stores', StoresState, StoresGetters, StoresActions>;

export type StoresStore = Store<'stores', StoresState, StoresGetters, StoresActions>;

export const useStoresStore: StoresStoreDefinition = defineStore('stores', {
  state: (): StoresState => ({
    stores: new Array<StoreModel>(),
    loadingIndicator: {
      delete: false,
      update: false,
    },
    fetched: false,
    isFetching: false,
  }),
  getters: {
    getStoreById: (state: StoresState) => (storeId: string) => {
      return state.stores.find((store: StoreModel) => store._id === storeId);
    },
    getGondolaById:
      (state: StoresState) =>
      (storeId: string, gondolaId: string): Optional<Gondola> => {
        return state.stores
          .find((store: StoreModel) => store._id === storeId)
          ?.gondolas.find((gondola: GondolaModel) => gondola._id === gondolaId);
      },
    getGondolaByDeviceId:
      (state: StoresState) =>
      (deviceId: string): Optional<StoreGondolaWrapper> => {
        // no filter, return nothing..
        if (!deviceId) return null;
        let searchResult: Optional<StoreGondolaWrapper> = null;
        state.stores.forEach((store: StoreModel) => {
          store.gondolas.forEach((gondola: GondolaModel) => {
            const found: boolean = gondola.railGrid.some((row: Device[]) =>
              row.some((col: Device) => col.longId === deviceId || col.shortId === deviceId)
            );
            if (found) {
              searchResult = { storeModel: store, gondolaModel: gondola };
              return;
            }
          });
        });
        return searchResult;
      },
    getAisleOfStore:
      (state: StoresState) =>
      (storeId: string, aisleName: string): Optional<Aisle> => {
        return state.stores
          .find((store: StoreModel) => store._id === storeId)
          ?.aisles.find((aisle: Aisle) => aisle.name === aisleName);
      },
    getAisleNamesOfStore:
      (state: StoresState) =>
      (storeId: string): Array<string> => {
        const store: Optional<StoreModel> = state.stores.find((store: StoreModel) => store._id === storeId);
        return !store ? [] : store.aisles.map((aisle: Aisle) => aisle.name);
      },
    getStoresWithValidAzureId: (state: StoresState) => (): Array<StoreModel> => {
      return state.stores.filter((localStore: StoreModel) => localStore.idAzure);
    },
    getGondolasWithSameLayout:
      (state: StoresState) =>
      (layoutHash: string): Array<Gondola> => {
        return state.stores
          .flatMap((store: StoreModel) => store.gondolas)
          .filter((gondola: Gondola) => gondola.layoutHash === layoutHash);
      },
    getStoreWithGondolaId:
      (state: StoresState) =>
      (gondolaId: string): Optional<StoreModel> => {
        return state.stores.find((store: StoreModel) =>
          store.gondolas.some((gondola: Gondola) => gondola._id === gondolaId)
        );
      },
  },
  actions: {
    async add(newStore: StoreModel): Promise<string> {
      const response: AxiosResponse<{ _id: string; hash: string }> = await axios.post(`${Config.getApiUrl()}/stores/`, {
        name: newStore.name,
        customerId: newStore.customerId,
        timezone: newStore.timezone,
        aisles: [],
      });

      if (response.status === 200) {
        newStore._id = response.data._id;
        newStore.hash = response.data.hash;
        this.stores.push(newStore);
      } else {
        console.warn(`Unhandled response status for adding a store ${response.status}`);
      }
      return newStore._id;
    },
    updateStores(storesToUpdate: StoreModel[]): void {
      storesToUpdate.forEach((storeToUpdate: StoreModel) => {
        const index: number = this.stores.findIndex((store: StoreModel) => store._id === storeToUpdate._id);
        this.stores.splice(index, 1, storeToUpdate);
      });
    },
    setUpdatingStoreLoadingIndicator(loadingState: boolean): void {
      this.loadingIndicator.update = loadingState;
    },
    setDeletingStoreLoadingIndicator(loadingState: boolean): void {
      this.loadingIndicator.delete = loadingState;
    },
    resetLoadingIndicators(): void {
      this.loadingIndicator.update = false;
      this.loadingIndicator.delete = false;
    },
    clear(): void {
      Vue.set(this, 'stores', new Array<StoreModel>());
    },
    setFetched(fetched: boolean): void {
      this.fetched = fetched;
    },
    setIsFetching(isFetching: boolean): void {
      this.isFetching = isFetching;
    },
    updateLastPublishingForGondola(gondola: GondolaModel, gondolaPublishingModel: GondolaPublishing): void {
      Vue.set(gondola, 'lastPublishing', gondolaPublishingModel);
    },
    populateDevice(store: StoreModel, device: Device): void {
      store.gondolas.forEach((gondola: GondolaModel) => {
        gondola.railGrid = gondola.railGrid.map((row: Array<Device>) =>
          row.map((gondolaDevice: Device) => {
            return gondolaDevice.shortId && device.shortId === gondolaDevice.shortId ? device : gondolaDevice;
          })
        );
      });
    },
    updateStoreHash(storeId: string, hash: string): void {
      const store: Optional<StoreModel> = this.stores.find((store: StoreModel) => store._id === storeId);
      if (!store) {
        return;
      }
      store.hash = hash;
    }, //both action and mutation
    async fetch(): Promise<void> {
      if (!this.isFetching) {
        this.setIsFetching(true);
        const response: AxiosResponse<Array<StoreJSON>> = await axios.get(`${Config.getApiUrl()}/stores`);
        if (response.status === 200) {
          const stores: Array<StoreModel> = response.data.map((value: StoreJSON) => {
            return StoreModel.fromJSON(value);
          });
          Vue.set(this, 'stores', stores);
          this.setFetched(true);
          this.setIsFetching(false);
        }
      }
    },
    async fetchStore(storeId: string, skipSync: boolean = false): Promise<Optional<StoreModel>> {
      const response: AxiosResponse<StoreJSON> = await axios.get(
        `${Config.getApiUrl()}/stores/${storeId}?skipSync=${skipSync}`
      );

      if (response.status !== 200) {
        console.debug(`Error syncing store with storeId ${storeId}`);
        return;
      }
      const store: StoreModel = StoreModel.fromJSON(response.data);

      this.updateStores([store]);
      return store;
    },
    async fetchPublishings(storeId: string): Promise<void> {
      const gondolaPublishings: {
        gondola: GondolaModel;
        publishing: GondolaPublishing;
      }[] = [];
      const storeGondolas: Optional<Array<GondolaModel>> = this.getStoreById(storeId)?.gondolas;
      try {
        const response: AxiosResponse<Array<GondolaPublishingJSON>> = await axios.get(
          `${Config.getApiUrl()}/publishing/by-store/${storeId}`
        );
        if (response.status === 200) {
          response.data?.forEach((gondolaPublishing: GondolaPublishingJSON) => {
            const gondola: Optional<Gondola> = storeGondolas?.find(
              (storeGondola: Gondola) => storeGondola._id === gondolaPublishing.gondolaId
            );
            if (!gondola) {
              console.error(`Could not match gondola ${gondolaPublishing.gondolaId}`);
              return;
            }
            gondolaPublishings.push({
              gondola: gondola,
              publishing: GondolaPublishing.fromJSON(gondolaPublishing),
            });
          });
        } else {
          console.error(response.data);
        }
      } catch (e: unknown) {
        const error: AxiosError<BaseError> = e as AxiosError<BaseError>;
        console.error(error);
      }

      gondolaPublishings.forEach((gondolaData: { gondola: Gondola; publishing: GondolaPublishing }) => {
        this.updateLastPublishingForGondola(gondolaData.gondola, gondolaData.publishing);
      });
    },
    async updatePublishing(gondolaPublishing: GondolaPublishing): Promise<void> {
      const gondola: Optional<Gondola> = this.getGondolaById(gondolaPublishing.storeId, gondolaPublishing.gondolaId);
      if (!gondola) {
        return;
      }
      if (
        gondola.lastPublishing?._id === gondolaPublishing._id ||
        (gondolaPublishing.activeAt ?? new Date(0)) > (gondola.lastPublishing?.activeAt ?? new Date(0))
      ) {
        this.updateLastPublishingForGondola(gondola, gondolaPublishing);
      }
    },
    async updateStoreIdAzure(storeIdAzure: string, storeId: string): Promise<void> {
      const devicesStore: DevicesStore = useDevicesStore();
      this.setUpdatingStoreLoadingIndicator(true);
      const response: AxiosResponse<StoreJSON> = await axios.patch(
        `${Config.getApiUrl()}/stores/${storeId}`,
        {
          idAzure: storeIdAzure,
        },
        {
          params: {
            hash: this.getStoreById(storeId)?.hash,
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        const store: Optional<StoreModel> = this.getStoreById(storeId);
        if (!store) {
          return;
        }
        devicesStore.clearStoreDevices(store._id);
        store.idAzure = storeIdAzure;
        store.hash = response.data.hash;
        await this.populateDevices(storeId);
        this.setUpdatingStoreLoadingIndicator(false);
      } else {
        console.warn(`Unhandled response status for updateStoreIdAzure ${response.status}`);
      }
    },
    async updateStoreName(storeName: string, storeId: string): Promise<void> {
      this.setUpdatingStoreLoadingIndicator(true);
      const response: AxiosResponse<StoreJSON> = await axios.patch(
        `${Config.getApiUrl()}/stores/${storeId}`,
        {
          name: storeName,
        },
        {
          params: {
            hash: this.getStoreById(storeId)?.hash,
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        const store: Optional<StoreModel> = this.getStoreById(storeId);
        if (!store) {
          return;
        }
        store.name = storeName;
        store.hash = response.data.hash;
        this.loadingIndicator.update = false;
      } else {
        console.warn(`Unhandled response status for updateStoreName ${response.status}`);
      }
    },
    async updateStoreTimezone(timezone: string, storeId: string): Promise<void> {
      this.setUpdatingStoreLoadingIndicator(true);
      const response: AxiosResponse<StoreJSON> = await axios.patch(
        `${Config.getApiUrl()}/stores/${storeId}`,
        {
          timezone: timezone,
        },
        {
          params: {
            hash: this.getStoreById(storeId)?.hash,
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        const store: Optional<StoreModel> = this.getStoreById(storeId);
        if (!store) {
          return;
        }
        store.timezone = timezone;
        store.hash = response.data.hash;
        this.loadingIndicator.update = false;
      } else {
        console.warn(`Unhandled response status for updateStoreTimezone ${response.status}`);
      }
    },
    async deleteStore(storeId: string): Promise<void> {
      this.setDeletingStoreLoadingIndicator(true);
      const response: AxiosResponse<void> = await axios.delete(`${Config.getApiUrl()}/stores/${storeId}`, {
        params: {
          hash: this.getStoreById(storeId)?.hash,
        },
      });
      if (response.status === 200 || response.status === 204) {
        const index: number = this.stores.findIndex((store: StoreModel) => store._id === storeId);
        this.stores.splice(index, 1);
        this.loadingIndicator.delete = false;
      } else {
        console.warn(`Unhandled response status for deleteStore ${response.status}`);
      }
    },
    async addGondola(gondola: GondolaModel, storeId: string, force: boolean): Promise<void> {
      const response: AxiosResponse<{ _id: string; hash: string; forceUpdatedStores: Array<StoreJSON> }> =
        await axios.post(`${Config.getApiUrl()}/stores/${storeId}/gondola`, gondola, {
          params: {
            hash: this.getStoreById(storeId)?.hash,
            force: force,
          },
        });
      if (response.status === 200) {
        gondola._id = response.data._id;
        const store: Optional<StoreModel> = this.getStoreById(storeId);
        if (!store) {
          return;
        }
        store.gondolas.push(gondola);
        store.hash = response.data.hash;
        if (!store.aisles.some((aisle: Aisle) => aisle.name === gondola.aisle)) {
          store.aisles.push(new Aisle(gondola.aisle));
        }

        // update stores that were force updated
        if (response.data.forceUpdatedStores) {
          const storesToUpdate: StoreModel[] = response.data.forceUpdatedStores.map((storesToUpdateJSON: StoreJSON) =>
            StoreModel.fromJSON(storesToUpdateJSON)
          );
          this.updateStores(storesToUpdate);
        }
      } else {
        console.error(response.data);
      }
    },
    async editGondola(gondola: GondolaModel, storeId: string, force: boolean): Promise<void> {
      const response: AxiosResponse<{
        _id: string;
        hash: string;
        forceUpdatedStores: Array<StoreJSON>;
        error?: string;
      }> = await axios.post(`${Config.getApiUrl()}/stores/${storeId}/gondola/${gondola._id}`, gondola, {
        params: {
          hash: this.getStoreById(storeId)?.hash,
          force: force,
        },
      });
      if (response.status === 200) {
        const store: Optional<StoreModel> = this.getStoreById(storeId);
        if (!store) {
          return;
        }
        const index: number = store.gondolas.findIndex((gondola: GondolaModel) => gondola._id === gondola._id);
        store.hash = response.data.hash;
        store.gondolas[index] = gondola;
        if (!store.aisles.some((aisle: Aisle) => aisle.name == gondola.aisle)) {
          store.aisles.push(new Aisle(gondola.aisle));
        }
        store.aisles = getGondolaAisles(store);

        // update stores that were force updated
        if (response.data.forceUpdatedStores) {
          const storesToUpdate: StoreModel[] = response.data.forceUpdatedStores.map((storesToUpdateJSON: StoreJSON) =>
            StoreModel.fromJSON(storesToUpdateJSON)
          );
          this.updateStores(storesToUpdate);
        }

        // repopulate store devices, to be sure data is up-to-date
        await this.populateDevices(storeId);
        await this.fetchPublishings(storeId);
      } else {
        if (!response.data.error) {
          console.error(response.data);
        } else {
          console.warn('Validation Error', response.data.error);
        }
      }
    },
    async deleteGondola(gondolaId: string, storeId: string): Promise<void> {
      const store: Optional<StoreModel> = this.getStoreById(storeId);
      if (!store) {
        return;
      }
      const response: AxiosResponse<{ hash: string }> = await axios.delete(
        `${Config.getApiUrl()}/stores/${storeId}/gondola/${gondolaId}`,
        {
          params: {
            hash: store.hash,
            azureStoreId: store.idAzure,
          },
        }
      );
      if (response.status === 200) {
        const store: Optional<StoreModel> = this.stores.find((_store: StoreModel) => _store._id === storeId);
        if (store) {
          store.hash = response.data.hash;
          const gondolaIndex: number = store.gondolas.findIndex((gondola: GondolaModel) => gondola._id === gondolaId);
          store.gondolas.splice(gondolaIndex, 1);
          store.aisles = getGondolaAisles(store);
        }
        await this.fetchPublishings(storeId);
      } else {
        console.error(response.data);
      }
    },
    async populateDevices(storeId: string): Promise<void> {
      const devicesStore: DevicesStore = useDevicesStore();
      const store: Optional<StoreModel> = this.getStoreById(storeId);
      if (store?.idAzure) {
        await devicesStore.fetch(storeId);
        this.populateStoreGondolas(store);
      }
    },
    async syncDevices(storeId: string): Promise<void> {
      const store: Optional<StoreModel> = this.getStoreById(storeId);
      if (store?.idAzure) {
        this.isFetching = true;
        const response: AxiosResponse<void> = await axios.post(`${Config.getApiUrl()}/stores/${storeId}/sync-devices`, {
          params: {
            storeId,
            sync: true,
          },
        });
        if (response.status >= 400) {
          console.debug(`Received error response when trying to retrieve devices for storeId ${storeId}`);
          this.isFetching = false;
          return;
        }
        this.isFetching = false;
        this.populateStoreGondolas(store);
      }
    },
    populateStoreGondolas(store: StoreModel): void {
      const devicesStore: DevicesStore = useDevicesStore();
      const devices: Array<Device> = devicesStore.getDevicesByStoreId(store._id);
      store.gondolas.forEach((gondola: GondolaModel) => {
        gondola.railGrid = gondola.railGrid.map((row: Array<Device>) =>
          row.map((gondolaRail: Device) => {
            return (
              (gondolaRail.shortId && devices.find((device: Device) => device.shortId === gondolaRail.shortId)) ||
              gondolaRail
            );
          })
        );
      });
    },
    setStoreSyncingState(storeId: string, isStoreSyncing: boolean): void {
      const store: Optional<StoreModel> = this.getStoreById(storeId);
      if (store) {
        store.setStoreSyncing(isStoreSyncing);
      }
    },
  },
});
