import Device from '@client/models/DeviceModels/Device.model';
import GondolaPublishing from './GondolaPublishing.model';
import { DeviceJSON } from '@common/device/types';
import { GondolaJSON } from '@common/gondola-template/types';

export default class Gondola {
  _id!: string;
  railGrid: Device[][];
  positionInAisle: string;
  aisle: string;
  lastPublishing?: GondolaPublishing;
  layoutHash: string;
  activeHoursJobFailed?: boolean;
  activeHoursTransactionIds?: Array<string>;
  constructor(aisle: string, positionInAisle: string, railGrid: Device[][] = [[new Device()]]) {
    this.aisle = aisle;
    this.positionInAisle = positionInAisle;
    this.railGrid = railGrid;
    this.layoutHash = '';
  }

  toJSON(): GondolaJSON {
    const newRailGrid: DeviceJSON[][] = this.railGrid.map((value: Device[]) => {
      return value.map((device: Device) => {
        return device.toJSON();
      });
    });

    return {
      _id: this._id,
      positionInAisle: this.positionInAisle,
      aisle: this.aisle,
      layoutHash: this.layoutHash,
      railGrid: newRailGrid,
    };
  }

  /**
   * Creates a deep copy of the gondola model
   * @param source the object to be cloned
   */
  static clone(source: Gondola): Gondola {
    const clonedRailGrid: Device[][] = source.railGrid.map((value: Device[]) => {
      return value.map((device: Device) => {
        return Device.clone(device);
      });
    });
    const gondola: Gondola = new Gondola(source.aisle, source.positionInAisle, clonedRailGrid);
    gondola._id = source._id;
    return gondola;
  }

  static fromJSON(source: GondolaJSON): Gondola {
    const newRailGrid: Device[][] = source.railGrid.map((value: DeviceJSON[]) => {
      return value.map((device: DeviceJSON) => {
        return Device.fromJSON(device);
      });
    });
    const gondola: Gondola = new Gondola(source.aisle, source.positionInAisle, newRailGrid);
    if (source._id) {
      gondola._id = source._id;
    }
    gondola.layoutHash = source.layoutHash;
    gondola.activeHoursJobFailed = source.activeHoursJobFailed;
    gondola.activeHoursTransactionIds = source.activeHoursTransactionIds;
    return gondola;
  }
}
