import { AxiosError } from 'axios';
import { BaseError } from '@common/error/types';

/**
 * Logs an axios error.
 */
export const logAxiosError = (error: unknown): void => {
  const axiosError: AxiosError<BaseError> = error as AxiosError<BaseError>;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.error(axiosError);
  } else {
    console.warn(`Error with code ${axiosError.status} occurred!\n`);
  }
};
