
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import { Gondola as GondolaModel } from '@client/models';
import { ClientPublishState } from '@client/enums';
import { DebouncedFuncLeading } from 'lodash';

@Component({})
export default class AislePublishingBadge extends Vue {
  /* DECLARATIONS */
  @Prop()
  private sections!: Array<GondolaModel>;

  private debouncedPublishingStatistics: { published: number; failed: number; pending: number; accepted: number } = {
    published: 0,
    failed: 0,
    pending: 0,
    accepted: 0,
  };

  @Watch('sections', { immediate: true, deep: true })
  private handleSectionsChange: DebouncedFuncLeading<() => void> = debounce(
    () => {
      this.debouncedPublishingStatistics = { published: 0, failed: 0, pending: 0, accepted: 0 };
      this.$nextTick(() => {
        this.updatePublishingStatistics();
      });
    },
    300,
    { maxWait: 2000 }
  );

  private updatePublishingStatistics() {
    let published: number = 0;
    let failed: number = 0;
    let pending: number = 0;
    let accepted: number = 0;

    for (const section of this.sections) {
      for (const row of section.lastPublishing?.railGrid || []) {
        for (const device of row) {
          const state: ClientPublishState = device.publishInformation.state;
          if (state === ClientPublishState.PUBLISHED || state === ClientPublishState.EXECUTING) {
            published++;
          } else if (state === ClientPublishState.PUBLISHING_FAILED || state === ClientPublishState.PUBLISHING_DENIED) {
            failed++;
          } else if (state === ClientPublishState.PUBLISHING) {
            pending++;
          } else if (state === ClientPublishState.ACCEPTED) {
            accepted++;
          }
        }
      }
    }

    this.debouncedPublishingStatistics = { published, failed, pending, accepted };
  }
}
