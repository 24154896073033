
import { Component, Prop, Vue } from 'vue-property-decorator';
import StoreAndSectionActiveHours from '@client/models/ActiveHoursModels/StoreAndSectionActiveHours';
import { Gondola } from '@client/models';
import SectionActiveHoursSchedule from '@client/models/ActiveHoursModels/SectionActiveHoursSchedule';
import { Optional } from '@common/types';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import { ScheduleVisibility } from '@common/schedule/types';
import { ActiveHourItem, ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import ScheduleSpanModel from '@client/models/ScheduleModels/ScheduleSpan.model';
import TimeSpanCalendar from '@client/components/TimeSpanCalendar/TimeSpanCalendar.vue';
import { Success } from '@client/styles/colors';

@Component({
  components: { TimeSpanCalendar },
})
export default class ActiveHours extends Vue {
  /* DECLARATIONS */
  @Prop()
  private activeHours!: StoreAndSectionActiveHours;
  @Prop()
  private gondola!: Gondola;
  private isSectionOverride: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */

  private activeHourItemToScheduleSpan(activeHourItem: ActiveHourItem): ScheduleSpanModel {
    const now: Date = new Date();
    const lastWeek: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    const nextWeek: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
    return new ScheduleSpanModel(lastWeek, nextWeek, activeHourItem.schedule[0].start, activeHourItem.schedule[0].end, [
      activeHourItem.day,
    ]);
  }

  private activeHoursScheduleToScheduleSpans(activeHorusSchedule: ActiveHoursSchedule): Array<ScheduleSpanModel> {
    return activeHorusSchedule
      .filter((activeHourItem: ActiveHourItem) => activeHourItem.schedule.length > 0)
      .map((activeHourItem: ActiveHourItem) => this.activeHourItemToScheduleSpan(activeHourItem));
  }

  /* GETTERS */
  get currentGondolaActiveHoursAsSchedules(): ScheduleModel {
    const sectionActiveHours: Optional<SectionActiveHoursSchedule> = this.activeHours.sectionActiveHours.find(
      (activeHours: SectionActiveHoursSchedule) => activeHours.gondolaId === this.gondola._id
    );
    const currentDeviceActiveHoursSchedule: ScheduleModel = new ScheduleModel(
      '',
      'activeHoursSchedule',
      Success._500,
      '',
      [],
      ScheduleVisibility.PUBLIC,
      [],
      true
    );
    if (sectionActiveHours) {
      this.isSectionOverride = true;
      currentDeviceActiveHoursSchedule.spans = this.activeHoursScheduleToScheduleSpans(sectionActiveHours.activeHours);
    } else {
      currentDeviceActiveHoursSchedule.spans = this.activeHoursScheduleToScheduleSpans(
        this.activeHours.storeActiveHours
      );
    }
    return currentDeviceActiveHoursSchedule;
  }
}
