import ImageModel from '@client/models/ContentModels/Image.model';
import { ContentType, LayerVisibility } from '@common/enums';
import { Ancestors, ContentItemPreviewStates, NewVideoJSON, VideoJSON } from '@common/content-item/types';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import { LayerPlaylistItem } from '@client/models';

export default class VideoModel extends ImageModel {
  duration: number = 0;
  fps: number = 0;

  constructor(
    id: string,
    customerId: string,
    name: string,
    hash: string,
    type: ContentType,
    originalName: string,
    url: string,
    previewUrl: string,
    previewState: ContentItemPreviewStates,
    height: number,
    width: number,
    duration: number = 0,
    fps: number = 0,
    offsetX: number = 0,
    offsetY: number = 0,
    zIndex: number = 0,
    checksum: string = '',
    parentFolder?: string,
    ancestors?: Ancestors,
    lastPreviewJobRun?: number
  ) {
    super(
      id,
      customerId,
      name,
      hash,
      type,
      originalName,
      url,
      previewUrl,
      previewState,
      height,
      width,
      offsetX,
      offsetY,
      zIndex,
      checksum,
      parentFolder,
      ancestors,
      lastPreviewJobRun
    );
    this.duration = duration;
    this.fps = fps;
  }

  public setDimensions(
    height: number,
    width: number,
    duration: number = 0,
    offsetX: number = 0,
    offsetY: number = 0,
    zIndex: number = 0
  ): void {
    this.height = height;
    this.width = width;
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.zIndex = zIndex;
    this.duration = duration;
  }

  public static getDefaultEmptyVideo(): VideoModel {
    return new VideoModel('', '', '', '', ContentType.Video, '', '', '', ContentItemPreviewStates.PENDING, 0, 0);
  }

  public static fromJSON(videoJSON: VideoJSON): VideoModel {
    const video: VideoModel = VideoModel.getDefaultEmptyVideo();
    video.setCustomerId(videoJSON.customerId);
    video.setName(videoJSON.name);
    video.setFileType(videoJSON.type);
    video.setHash(videoJSON.hash);
    video.setParentFolder(videoJSON.parentFolder);
    video.setAncestors(videoJSON.ancestors);
    video.originalName = videoJSON.originalName;
    video.previewState = videoJSON.previewState;
    video.url = videoJSON.url;
    video.previewUrl = videoJSON.previewUrl;
    video.height = videoJSON.height;
    video.width = videoJSON.width;
    video.offsetX = videoJSON.offsetX;
    video.offsetY = videoJSON.offsetY;
    video.checksum = videoJSON.checksum;
    video.zIndex = videoJSON.zIndex;
    video._id = videoJSON._id;
    video.setCreatedAt();
    video.lastPreviewJobRun = videoJSON.lastPreviewJobRun || video.createdAtAsTime;
    video.duration = videoJSON.duration ?? 0;
    video.fps = videoJSON.fps ?? 0;
    return video;
  }

  public toJSON(): VideoJSON {
    return {
      ...super.toJSON(),
      duration: this.duration,
      fps: this.fps,
    };
  }

  public toNewJSON(): NewVideoJSON {
    const { _id, ...newVideoJSON }: VideoJSON = this.toJSON();
    return newVideoJSON;
  }

  public toBaseLayer(): BaseLayerModel {
    return new BaseLayerModel(
      this._id,
      this.name,
      this.url,
      this.previewUrl,
      this.type,
      this.height,
      this.width,
      this.duration,
      this.fps,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      undefined,
      []
    );
  }

  public toScheduledLayer(): ScheduleLayerModel {
    return new ScheduleLayerModel(
      this.name,
      this.url,
      this.previewUrl,
      this.type,
      this.height,
      this.width,
      this.duration,
      this.fps,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      undefined,
      LayerVisibility.VISIBLE,
      []
    );
  }

  toPlaylistLayer(hideLabels: boolean = false): LayerPlaylistItem {
    return new LayerPlaylistItem(
      this._id,
      this.name,
      this.url,
      this.previewUrl,
      this.type,
      this.height,
      this.width,
      this.duration,
      this.fps,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      hideLabels,
      ''
    );
  }

  public clone(): VideoModel {
    return new VideoModel(
      this._id,
      this.customerId,
      this.name,
      this.hash || '',
      this.type,
      this.originalName,
      this.url,
      this.previewUrl,
      this.previewState,
      this.height,
      this.width,
      this.duration,
      this.fps,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      this.parentFolder,
      this.ancestors,
      this.lastPreviewJobRun
    );
  }
}
