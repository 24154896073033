
import { Component, Prop, Vue, Model } from 'vue-property-decorator';
import { Optional } from '@common/types';
import { TranslateResult } from 'vue-i18n';

/**
 * Renders an expanding text field
 * Needs a string variable as model
 */
@Component({})
export default class SearchTextField extends Vue {
  @Prop({ type: Boolean })
  private isLoading: boolean = false;
  @Prop()
  private placeholder?: TranslateResult;
  @Prop({ default: '' })
  /**
   * Cypress selector id
   */
  private dataCy?: string;
  @Model('change', { default: '' })
  private value!: string;

  private modelValue: string = '';

  private searchFocused: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */

  searchSelected(): void {
    this.searchFocused = !this.searchFocused;
    // Vuetify needs to use next tick https://github.com/vuetifyjs/vuetify/issues/10659 https://stackoverflow.com/questions/56473731/how-to-focus-v-textarea-programatically-in-vuetify-and-typescript
    this.$nextTick(() => {
      const searchComponent: Vue | undefined = this.$refs['search'] as Vue | undefined;
      if (!searchComponent) {
        return;
      }
      const searchComponentInput: HTMLInputElement = searchComponent.$refs.input as HTMLInputElement;
      if (this.searchFocused) {
        searchComponentInput.focus();
      } else {
        searchComponentInput.blur();
      }
    });
  }

  searchFocusOut(): void {
    // Vuetify needs to use next tick https://github.com/vuetifyjs/vuetify/issues/10659 https://stackoverflow.com/questions/56473731/how-to-focus-v-textarea-programatically-in-vuetify-and-typescript
    this.$nextTick(() => {
      const focusOutDiv: Vue | undefined = this.$refs['searchFocusOut'] as Vue | undefined;
      if (!focusOutDiv) {
        return;
      }
      const focusOutDivElement: HTMLDivElement = focusOutDiv.$el as HTMLDivElement;
      focusOutDivElement?.focus();
      this.searchFocused = false;
    });
  }

  onSearchQueryChange(value: string): void {
    this.$emit('change', value);
  }

  onSearchQueryClear(): void {
    this.modelValue = '';
    this.$emit('change', '');
  }

  onFocusSearchField(): void {
    this.searchFocused = true;
  }

  onFocusOutSearchField(): void {
    this.searchFocused = false;
  }

  /* GETTERS */

  get searchCompactClass(): Optional<string> {
    if (this.searchFocused || this.value) {
      return null;
    }
    if (this.placeholder) {
      return 'search-minimized';
    }
    return 'search-compact';
  }
  get prependIcon(): Optional<string> {
    if (!(this.searchFocused || this.value)) {
      return null;
    }
    return 'mdi-close';
  }
}
