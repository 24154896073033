
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GondolaTemplate } from '@client/models';
import router from '@client/router';
import { Optional } from '@common/types';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component({})
export default class OpenTemplateStoreButton extends Vue {
  @Prop()
  private gondolaTemplateId: Optional<string>;
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  @Prop()
  private positionInAisle: Optional<string>;

  openTemplate(): void {
    if (!this.gondolaTemplateId) {
      return;
    }
    router.push(`/template/${this.gondolaTemplateId}`);
  }

  get templateName(): Optional<string> {
    return this.template?.name || this.gondolaTemplateId;
  }

  get template(): Optional<GondolaTemplate> {
    if (!this.gondolaTemplateId) {
      return undefined;
    }
    return this.gondolaTemplatesStore.getById(this.gondolaTemplateId);
  }

  /**
   * Checks if the published template still exists
   */
  get templateExists(): boolean {
    return !!this.template;
  }
}
