
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola as GondolaModel, HardwareModel, Device, DevicePublishing } from '@client/models';
import { TranslateResult } from 'vue-i18n';
import Moment from 'moment';
import { OnlineStatus } from '@common/enums';
import { ValidationErrorSeverity } from '@common/error/types';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import { DevicesStore, useDevicesStore, DeviceValidationResult } from '@client/stores/devices';
import { StoresStore, useStoresStore, DisplayedGondolaValidationResult } from '@client/stores/stores';
import { getComputedTranslationString } from '@client/plugins/i18n/TypedI18nPlugin';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';

@Component({
  methods: { getComputedTranslationString },
})
export default class GondolaRailGrid extends Vue {
  @Prop()
  private gondola!: GondolaModel;

  private displayedGondolaValidationResult: DisplayedGondolaValidationResult | null = null;
  private sizes: HardwareModel[] = HardwareModel.getAll();
  private showLongId: boolean = false;
  private isValidating: boolean = false;
  private ongoingValidations: Promise<void>[] = [];
  private messagesStore: MessagesStore = useMessagesStore();
  private devicesStore: DevicesStore = useDevicesStore();
  private storesStore: StoresStore = useStoresStore();
  private globalStore: AppGlobalStore = useAppGlobalStore();

  getContainerClass(device: Device): string {
    if (!device.shortId) {
      return this.getDeviceSize(device);
    }
    return `${this.getDeviceSize(device)} device-info-margin`;
  }

  getDeviceSize(device: Device): string {
    return `device-${device.hardwareModel.identifier}`;
  }

  showOnlineStatus(device: Device): boolean {
    return !!device.onlineStatus;
  }

  getOnlineStatusClass(device: Device): string {
    return device.onlineStatus === OnlineStatus.ONLINE ? 'success' : 'error';
  }

  getDevicePublishing(rowIndex: number, colIndex: number): DevicePublishing | undefined {
    if (this.gondola.lastPublishing?.railGrid?.[rowIndex]) {
      return this.gondola.lastPublishing?.railGrid?.[rowIndex][colIndex];
    }
    return undefined;
  }

  showRailGroupIcon(rowIndex: number, colIndex: number): boolean {
    const devicePublishing: DevicePublishing | undefined = this.getDevicePublishing(rowIndex, colIndex);
    return (devicePublishing?.railGroup && !devicePublishing.railGroup.isUnlinked) || false;
  }

  getRailGroupColor(rowIndex: number, colIndex: number): string | undefined {
    const devicePublishing: DevicePublishing | undefined = this.getDevicePublishing(rowIndex, colIndex);
    return devicePublishing?.railGroup.color;
  }

  formatOnlineStatus(device: Device): TranslateResult {
    if (!device.onlineStatus) {
      return '';
    }
    return this.$t(this.$i18nTranslationKeys.device.status[device.onlineStatus].$path, {
      date: Moment(device.onlineStatusLastChanged || new Date()).format('llll'),
    });
  }

  copyLongId(device: Device): void {
    navigator.clipboard.writeText(device.longId);
    this.messagesStore.showMessage(
      this.$t(this.$i18nTranslationKeys.device.copyLongId.$path, { shortId: device.shortId })
    );
    this.showLongId = false;
  }

  addDeviceRow(): void {
    this.$emit('change');
    this.gondola.railGrid.push([new Device()]);
  }

  addDeviceCol(rowIndex: number): void {
    this.$emit('change');
    this.gondola.railGrid[rowIndex].push(new Device());
  }

  deleteDevice(rowIndex: number, colIndex: number): void {
    this.$emit('change');
    this.gondola.railGrid[rowIndex].splice(colIndex, 1);
    if (!this.gondola.railGrid[rowIndex].length) {
      this.gondola.railGrid.splice(rowIndex, 1);
    }
  }

  getValidationErrorMessage(rowIndex: number, colIndex: number): string {
    return this.displayedGondolaValidationResult?.validationErrorMessages?.[rowIndex]?.[colIndex] || '';
  }

  onChangeSize(rowIndex: number, colIndex: number, value: string): void {
    this.gondola.railGrid[rowIndex][colIndex].hardwareModel = HardwareModel.getByIdentifier(value);
  }

  setValidationState(validationState: boolean, e?: FocusEvent): void {
    const inputValue: string | undefined = (e?.target as HTMLInputElement)?.value;
    // If we're setting the validation to false, and the input has a value set, ignore thing since the change event will trigger the validation.
    if (!validationState && inputValue) {
      return;
    }
    this.$emit('setValidationState', validationState);
  }

  get gondolaDeviceIds(): Array<string> {
    const gondolaDeviceIds: Array<string> = [];
    this.gondola.railGrid.forEach((row: Array<Device>) =>
      row.map((device: Device) => {
        if (device.shortId) {
          gondolaDeviceIds.push(device.shortId);
        }
      })
    );
    return gondolaDeviceIds;
  }

  get storeDevices(): Array<Device> {
    const deviceIds: string[] = this.gondolaDeviceIds;
    return this.devicesStore
      .getDevicesByStoreId(this.storeId)
      .filter((device: Device) => !deviceIds.includes(device.shortId));
  }

  getMatchingDeviceModel(shortId: string): Device | undefined {
    const storeDevices: Device[] = this.devicesStore.getDevicesByStoreId(this.storeId);
    return storeDevices.find((item: Device) => item.shortId === shortId);
  }

  /**
   *  Check if the device on the given position has any validation errors set by the server
   */
  hasGondolaValidationError(row: number, col: number): boolean {
    if (
      !this.displayedGondolaValidationResult ||
      !this.displayedGondolaValidationResult.validationErrorMessages ||
      !this.displayedGondolaValidationResult.validationErrorMessages[row] ||
      !this.displayedGondolaValidationResult.validationErrorMessages[row][col]
    ) {
      return false;
    }

    return (
      this.displayedGondolaValidationResult.validationErrorMessages[row][col] != null &&
      this.displayedGondolaValidationResult.validationErrorMessages[row][col] != ''
    );
  }

  /**
   * returns a function, which can be used to determine if a given rail grid slot has an active validation message
   */
  getGondolaValidationErrorMessageEvaluator(row: number, col: number): [() => string | boolean] {
    return [
      () =>
        !this.hasGondolaValidationError(row, col) ||
        (this.displayedGondolaValidationResult != null &&
          this.displayedGondolaValidationResult.validationErrorMessages[row][col]),
    ];
  }

  hasValidDeviceId(device: Device): boolean {
    return !!device.longId;
  }

  validateGondola(row: number, column: number, _device: Device, newSelectedDevice: Device | string | null): void {
    // clear existing errors
    this.displayedGondolaValidationResult = {
      validationErrorMessages: [],
    };
    let device: Device = new Device(_device.hardwareModel, '');
    if (newSelectedDevice) {
      const isString: boolean = typeof newSelectedDevice === 'string';
      const matchingDevice: Device | undefined = this.getMatchingDeviceModel(
        isString ? (newSelectedDevice as string) : (newSelectedDevice as Device).shortId
      );
      if (matchingDevice) {
        device = new Device(
          matchingDevice.hardwareModel,
          matchingDevice._id,
          matchingDevice.longId,
          matchingDevice.shortId
        );
      } else {
        device = new Device(_device.hardwareModel, '', '', isString ? (newSelectedDevice as string) : device.shortId);
      }
    }
    (this.$refs.railForm as Vue & { resetValidation: () => boolean }).resetValidation();
    this.ongoingValidations.push(
      new Promise((resolve: () => void) => {
        this.$emit('setValidationState', true);
        this.isValidating = true;
        device.onlineStatusLoading = true;
        this.gondola.railGrid[row].splice(column, 1, device);
        // make sure some more fields are synced
        this.gondola.railGrid.forEach((_row: Device[]) => {
          _row.forEach((deviceModel: Device) => {
            deviceModel.gondolaId = this.gondolaId;
            deviceModel.storeId = this.storeId;
            deviceModel.customerId = this.globalStore.customer;
            if (!deviceModel.shortId) {
              // no id set! clean/reset all fields
              deviceModel.longId = '';
              deviceModel.onlineStatusLastChanged = undefined;
              deviceModel.onlineStatus = undefined;
            }
          });
        });
        this.$set(this, 'gondola', this.gondola);
        this.devicesStore
          .validateRailGrid(this.gondola.railGrid, this.storeIdAzure)
          .then((validationResult: DeviceValidationResult[][] | undefined) => {
            if (!validationResult) {
              return;
            }
            this.handleDeviceValidation(validationResult, device);
            this.$emit('setValidationState', false);
            this.isValidating = false;
            resolve();
          })
          .catch((e: unknown) => {
            console.error(e);
            this.$emit('setValidationState', false);
            this.isValidating = false;
            // we still want to resolve here, because serious errors will be
            // treated by backend validation on save anyway
            resolve();
          });
      })
    );
  }

  handleDeviceValidation(validationResult: DeviceValidationResult[][], device: Device): void {
    // add validation error, if an error was found
    for (const [rowIndex, deviceValidationResultRow] of validationResult.entries()) {
      for (const [columnIndex, deviceValidationResult] of deviceValidationResultRow.entries()) {
        if (
          this.displayedGondolaValidationResult &&
          !this.displayedGondolaValidationResult?.validationErrorMessages[rowIndex]
        ) {
          this.displayedGondolaValidationResult.validationErrorMessages[rowIndex] = [];
        }

        if (this.displayedGondolaValidationResult && deviceValidationResult.hasError) {
          this.displayedGondolaValidationResult.validationErrorMessages[rowIndex][columnIndex] =
            deviceValidationResult.message;
          if (
            !this.displayedGondolaValidationResult.severity ||
            deviceValidationResult.severity == ValidationErrorSeverity.CRITICAL
          ) {
            // we only want to store the worst Severity of all evaluated devices
            this.displayedGondolaValidationResult.severity = deviceValidationResult.severity;
          }
          device.onlineStatus = undefined;
          device.onlineStatusLastChanged = undefined;
        }

        if (deviceValidationResult.device) {
          // if we got updated data from server, update local device
          this.gondola.railGrid[rowIndex].splice(columnIndex, 1, Device.fromJSON(deviceValidationResult.device));
        }
      }
    }
    this.$set(this, 'displayedGondolaValidationResult', this.displayedGondolaValidationResult);
    this.$emit('updateValidation', this.displayedGondolaValidationResult);
    this.$set(this, 'gondola', this.gondola);
    device.onlineStatusLoading = false;
    (this.$refs.railForm as Vue & { validate: () => boolean }).validate(); // this call might cause that textbox values are reset and/or just set to other values
    this.$emit('setValidationState', false);
    this.isValidating = false;
  }

  customDeviceFilter(item: Device, queryText: string): boolean {
    return (
      item.shortId.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      item.hardwareModel.identifier.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  customDeviceValueComparator(itemA: Device | null, itemB: Device | null): boolean {
    if (!itemA || !itemB) {
      return false;
    }
    return itemA.longId === itemB.longId;
  }

  get storeIdAzure(): string {
    return this.storesStore.getStoreById(this.storeId)?.idAzure || '';
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get gondolaId(): string {
    return this.$route.params.gondolaid;
  }
}
