import { defineStore, Store, StoreDefinition } from 'pinia';
import { DeviceSearchResult, Device, Store as StoreModel, Gondola } from '@client/models';
import Vue from 'vue';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { DeviceSearchResultJSON, DeviceJSON } from '@common/device/types';
import Config from '@client/utils/config';
import { DeviceValidationResult } from '@client/stores/devices';
import { StoreDeviceStatistics } from '@client/models/StoreModels/Store.model';
import { OnlineStatus } from '@common/enums';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Optional } from '@common/types';
import { DeviceCSV } from '@client/models/DeviceOverviewModels';

export interface DevicesGetters {
  getDevices: (state: DevicesState) => () => Array<Device>;
  getDevicesByStoreId: (state: DevicesState) => (storeId: string) => Array<Device>;
  getStoreDevicesStatistics: (state: DevicesState) => (storeId: string) => StoreDeviceStatistics;
  getDeviceById: (state: DevicesState) => (id: string) => Array<Device>;
  getDeviceByLongId: (state: DevicesState) => (id: string) => Device | undefined;
  getDeviceByShortId: (state: DevicesState) => (id: string) => Array<Device>;
  getDeviceCSVByLongIds: (state: DevicesState) => (id: Array<string>) => Array<DeviceCSV>;
}

export interface DevicesActions {
  clear(): void;
  deleteFromState(deviceId: string): void;
  updateDevice(updatedDevice: Device): void;
  fetch(storeId?: string): Promise<void>;
  validateRailGrid(
    railGrid: Array<Array<Device>>,
    storeIdAzure: string
  ): Promise<DeviceValidationResult[][] | undefined>;
  syncDevice(longId: string, storeIdAzure: string): Promise<Optional<Device>>;
  clearStoreDevices(storeId: string): void;
  search(): Promise<void>;
  getDevice(longId: string): Promise<Optional<DeviceSearchResult>>;
}

export interface DevicesState {
  devices: Map<string, Device>;
  searchResults: Array<DeviceSearchResult>;
  isFetching: boolean;
  isSearching: boolean;
}

export type DevicesStoreDefinition = StoreDefinition<'devices', DevicesState, DevicesGetters, DevicesActions>;

export type DevicesStore = Store<'devices', DevicesState, DevicesGetters, DevicesActions>;

export const useDevicesStore: DevicesStoreDefinition = defineStore('devices', {
  state: (): DevicesState => ({
    devices: new Map<string, Device>(),
    searchResults: new Array<DeviceSearchResult>(),
    isFetching: false,
    isSearching: false,
  }),
  getters: {
    getDevices: (state: DevicesState) => () => {
      return Array.from(state.devices.values());
    },
    getDevicesByStoreId: (state: DevicesState) => (storeId: string) => {
      return Array.from<Device>(state.devices.values()).filter((device: Device) => device.storeId === storeId);
    },
    getDeviceById: (state: DevicesState) => (id: string) => {
      return Array.from<Device>(state.devices.values()).find((device: Device) => device._id === id);
    },
    getDeviceByLongId: (state: DevicesState) => (id: string) => {
      return state.devices.get(id);
    },
    getDeviceByShortId: (state: DevicesState) => (id: string) => {
      return Array.from<Device>(state.devices.values()).find((device: Device) => device.shortId === id);
    },
    getStoreDevicesStatistics:
      (state: DevicesState) =>
      (storeId: string): StoreDeviceStatistics => {
        const storeDevices: Array<Device> = Array.from<Device>(state.devices.values()).filter(
          (device: Device) => device.storeId === storeId
        );
        return {
          numberOfUnassignedDevices: storeDevices.filter((device: Device) => !device.gondolaId).length,
          numberOfOnlineDevices: storeDevices.filter(
            (device: Device) => device.gondolaId && device.onlineStatus === OnlineStatus.ONLINE
          ).length,
          numberOfOfflineDevices: storeDevices.filter(
            (device: Device) => device.gondolaId && device.onlineStatus === OnlineStatus.OFFLINE
          ).length,
        };
      },
    getDeviceCSVByLongIds:
      (state: DevicesState) =>
      (longIds: Array<string>): Array<DeviceCSV> => {
        return state.searchResults
          .filter((deviceSearchResult: DeviceSearchResult) => longIds.includes(deviceSearchResult.device.longId))
          .map((deviceSearchResult: DeviceSearchResult): DeviceCSV => {
            const storesStore: StoresStore = useStoresStore();
            const optionalStore: Optional<StoreModel> = storesStore.getStoreById(deviceSearchResult.device.storeId);
            const storeAzureId: string = optionalStore?.idAzure ?? '';
            const deviceGondola: Optional<Gondola> = optionalStore?.gondolas.find(
              (gondola: Gondola) => gondola._id === deviceSearchResult.device.gondolaId
            );
            return deviceSearchResult.device.toDeviceCSV(
              storeAzureId,
              deviceGondola?.aisle,
              deviceGondola?.positionInAisle
            );
          });
      },
  },
  actions: {
    clear(): void {
      Vue.set(this, 'devices', new Map<string, Device>());
    },
    clearStoreDevices(storeId: string) {
      const devices: Array<Device> = Array.from(this.devices.values());
      const devicesToRemove: Array<Device> = devices.filter((device: Device) => device.storeId === storeId);
      devicesToRemove.forEach((device: Device) => {
        this.deleteFromState(device.longId);
      });
    },
    deleteFromState(deviceId: string): void {
      this.devices.delete(deviceId);
    },
    updateDevice(updatedDevice: Device): void {
      this.devices.set(updatedDevice.longId, updatedDevice);
      const storesStore: StoresStore = useStoresStore();
      const store: Optional<StoreModel> = storesStore.getStoreById(updatedDevice.storeId);
      if (store) {
        store.setDevicesStatistics(this.getStoreDevicesStatistics(store._id));
      }
    },
    async fetch(storeId?: string): Promise<void> {
      const storesStore: StoresStore = useStoresStore();
      this.isFetching = true;
      const response: AxiosResponse<Array<DeviceJSON>> = await axios.get(`${Config.getApiUrl()}/devices`, {
        params: {
          storeId,
        },
      });
      if (response.status >= 400) {
        console.debug(`Received error response when trying to retrieve devices for current customer`);
        this.isFetching = false;
        return;
      }
      for (const device of response.data) {
        const updatedDevice: Device = Device.fromJSON(device);
        const existingStore: Optional<StoreModel> = storesStore.getStoreById(updatedDevice.storeId);
        if (!existingStore) {
          continue;
        }
        this.updateDevice(updatedDevice);
        storesStore.populateDevice(existingStore, updatedDevice);
      }
      this.isFetching = false;
    },
    async validateRailGrid(
      railGrid: Array<Array<Device>>,
      storeIdAzure: string
    ): Promise<DeviceValidationResult[][] | undefined> {
      return (
        await axios.post(`${Config.getApiUrl()}/devices/validateRailGrid`, {
          railGrid,
          storeIdAzure,
        })
      ).data;
    },
    async syncDevice(longId: string, storeIdAzure: string): Promise<Optional<Device>> {
      try {
        this.isFetching = true;
        const response: AxiosResponse<DeviceJSON> = await axios.post(
          `${Config.getApiUrl()}/devices/${longId}/sync`,
          undefined,
          {
            params: { storeIdAzure },
          }
        );
        this.isFetching = false;
        if (response.status >= 400) {
          console.debug(
            `Received error response when trying to sync device with longId ${longId} for storeIdAzure ${storeIdAzure}`
          );
          return;
        } else {
          return Device.fromJSON(response.data);
        }
      } catch (e: unknown) {
        this.isFetching = false;
        const error: AxiosError = e as AxiosError;
        console.error(error);
      }
    },
    async search(): Promise<void> {
      if (this.isSearching) {
        return;
      }
      this.isSearching = true;
      const response: AxiosResponse<Array<DeviceSearchResultJSON>> = await axios.get(
        `${Config.getApiUrl()}/devices/search`
      );
      if (response.status === 200) {
        this.searchResults = response.data.map((deviceSearchResultJSON: DeviceSearchResultJSON) =>
          DeviceSearchResult.fromJSON(deviceSearchResultJSON)
        );
      } else {
        console.error(response);
      }
      this.isSearching = false;
    },
    async getDevice(longId: string): Promise<Optional<DeviceSearchResult>> {
      try {
        if (this.isSearching) {
          return;
        }
        this.isSearching = true;
        const response: AxiosResponse<DeviceSearchResultJSON> = await axios.get(
          `${Config.getApiUrl()}/devices/search/${longId}`
        );
        if (response.status < 400) {
          this.isSearching = false;
          return DeviceSearchResult.fromJSON(response.data);
        } else {
          console.error(response);
        }
      } catch (e: unknown) {
        const error: Error | AxiosError = e as Error | AxiosError;
        if (!axios.isAxiosError(error)) {
          console.error(error.message);
          return;
        }
        console.error(error.response?.status, error.response?.statusText);
      }
      this.isSearching = false;
    },
  },
});
