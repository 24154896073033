/**
 * Util that returns only the aisles from a store to which gondolas are assigned.
 */
import { Aisle, Gondola as GondolaModel, Store } from '@client/models';

export function getGondolaAisles(store: Store): Array<Aisle> {
  const aisles: Set<string> = new Set(store.gondolas.map((gondola: GondolaModel) => gondola.aisle));
  return store.aisles.filter((aisle: Aisle) => {
    return aisles.has(aisle.name);
  });
}
