
import { Component, Vue } from 'vue-property-decorator';
import { PublishProcessState, PublishSectionsPageType } from '@client/enums';
import { Optional } from '@common/types';
import isGondolaPublishingUpToDate from '@client/utils/isGondolaPublishingUpToDate';
import {
  DeviceTemplate,
  Gondola,
  GondolaPublishing,
  GondolaTemplate as GondolaTemplateModel,
  Store,
} from '@client/models';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { PublishingsStore, usePublishingsStore } from '@client/stores/publishings';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { ModalDialog, VisualTimer } from '@client/components';
import ForegroundContentModel from '@client/models/ScheduleModels/ForegroundContent.model';
import { ContentType } from '@common/enums';
import SectionsDataTable from '@client/components/SectionsDataTable/SectionsDataTable.vue';
import SectionForPublishing from '@client/models/GondolaModels/SectionsForPublishing.model';
import { EMPTY_LABEL_PLACEHOLDER } from '@client/models/ContentModels/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { BulkPublishingJSON, ValidatedPublishingJSON } from '@common/publishing/types';
import { TranslateResult } from 'vue-i18n';
import { extractPublishValidationError } from '@client/views/StorePages/PublishValidationErrorUtil';

@Component({
  methods: {
    isGondolaPublishingUpToDate,
  },
  computed: {
    PublishSectionsPageType() {
      return PublishSectionsPageType;
    },
  },
  components: { SectionsDataTable, ModalDialog, VisualTimer },
})
export default class PublishSectionsView extends Vue {
  /* DECLARATIONS */
  private pageType: PublishSectionsPageType = PublishSectionsPageType.REPUBLISH;
  private publishProcessState: PublishProcessState = PublishProcessState.NOT_STARTED;
  private selectedGondolaTemplate: Optional<GondolaTemplateModel> = null;
  private sections: Array<SectionForPublishing> = [];
  private selectedSections: Array<SectionForPublishing> = [];
  private DEFAULT_REDIRECT_TIME: number = 3;
  private noSectionsAvailable: boolean = false;
  private validationErrorMessage: TranslateResult = '';

  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private publishingsStore: PublishingsStore = usePublishingsStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private storesStore: StoresStore = useStoresStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();

  $refs!: {
    timer: VisualTimer;
  };

  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    if (this.$route.name?.toLocaleUpperCase() === PublishSectionsPageType.REPUBLISH) {
      this.pageType = PublishSectionsPageType.REPUBLISH;
    } else {
      this.pageType = PublishSectionsPageType.MATCHING_LAYOUTS;
    }
    await this.storesStore.fetch();
    await this.schedulesStore.fetch();
    await this.gondolaTemplatesStore.fetch();
    this.selectedGondolaTemplate = this.gondolaTemplatesStore.getById(this.templateId);
    this.updateBreadCrumbs();
    await this.initSections();
  }

  /* METHODS */
  private updateBreadCrumbs() {
    this.breadcrumbsStore.replace({
      path: '/templates',
      title: { key: this.$i18nTranslationKeys.gondolaTemplateListView.breadcrumb.$path },
    });
    const breadcrumb: string = this.templateText(this.selectedGondolaTemplate) || this.templateId;
    if (this.pageType === PublishSectionsPageType.REPUBLISH) {
      this.breadcrumbsStore.push({
        path: `/republish/${this.templateId}`,
        title: {
          key: this.$i18nTranslationKeys.rePublishView.breadcrumb.$path,
          params: {
            template: breadcrumb,
          },
        },
      });
    } else {
      this.breadcrumbsStore.push({
        path: `/publish/${this.templateId}`,
        title: {
          key: this.$i18nTranslationKeys.rePublishView.publishBreadcrumb.$path,
          params: {
            template: breadcrumb,
          },
        },
      });
    }
  }

  private async initSections(): Promise<void> {
    if (this.pageType === PublishSectionsPageType.REPUBLISH) {
      await this.publishingsStore.fetchByTemplateId({
        templateId: this.templateId,
      });
      const activePublishings: Array<GondolaPublishing> = this.publishingsStore.getPublishingsByGondolaId(
        this.templateId
      );
      this.sections = activePublishings.map((publishing: GondolaPublishing) => {
        // check whether publishing to this section is still possible
        const gondola: Optional<Gondola> = this.storesStore.getGondolaById(publishing.storeId, publishing.gondolaId);
        const isSelectable: boolean = SectionForPublishing.canSectionBePublished(gondola);
        return SectionForPublishing.fromGondolaPublishing(
          publishing,
          this.getStoreName(publishing.storeId),
          publishing.storeId,
          this.templateText(this.selectedGondolaTemplate) ?? this.templateId,
          isSelectable
        );
      });
      this.noSectionsAvailable = this.sections.length === 0;
    } else {
      const templateLayoutHash: string = this.selectedGondolaTemplate?.layoutHash ?? '';
      const gondolas: Array<Gondola> = this.storesStore.getGondolasWithSameLayout(templateLayoutHash);
      const gondolaIds: Array<string> = gondolas.map((gondola: Gondola) => gondola._id);
      await this.publishingsStore.fetchPublishingsForSections(gondolaIds);
      this.sections = gondolas
        .map((gondola: Gondola) => {
          const store: Optional<Store> = this.storesStore.getStoreWithGondolaId(gondola._id);
          if (!store) {
            return undefined;
          }
          return SectionForPublishing.fromGondola(
            gondola,
            store.name,
            store.idAzure,
            store._id,
            SectionForPublishing.canSectionBePublished(gondola)
          );
        })
        .filter((section: Optional<SectionForPublishing>): section is SectionForPublishing => !!section);
      this.sections.forEach((section: SectionForPublishing) => {
        const optionalPublishing: Optional<GondolaPublishing> = this.publishingsStore.getPublishingsForSection(
          section.gondolaId
        );
        if (optionalPublishing) {
          const publishedTemplate: Optional<GondolaTemplateModel> = this.gondolaTemplatesStore.getById(
            optionalPublishing?.gondolaTemplateId ?? ''
          );
          if (!publishedTemplate) {
            return;
          }
          section.setPublishingInformation(optionalPublishing, publishedTemplate.name);
        }
      });
    }
  }

  templateText(item: Optional<GondolaTemplateModel>): string {
    return (item?.name ? item?.name : item?._id) || '';
  }

  async bulkPublish() {
    if (!this.isPublishingAvailable) {
      return;
    }
    const template: Optional<GondolaTemplateModel> = this.gondolaTemplatesStore.getById(this.templateId ?? '');
    if (!template) {
      return;
    }
    const bulkPublishingJSON: BulkPublishingJSON = {
      templateId: this.templateId,
      templateHash: template.hash ?? '',
      sections: [],
    };
    this.publishProcessState = PublishProcessState.IN_PROGRESS;
    await this.selectedSections.forEach((selectedSection: SectionForPublishing) => {
      const store: Optional<Store> = this.storesStore.getStoreById(selectedSection.storeId);
      if (!store) {
        return undefined;
      }
      bulkPublishingJSON.sections.push({
        sectionId: selectedSection.gondolaId,
        storeId: selectedSection.storeId,
        storeHash: store.hash,
      });
    });
    const validatedPublishingJSON: Optional<ValidatedPublishingJSON> = await this.publishingsStore.validate(
      bulkPublishingJSON
    );
    if (!validatedPublishingJSON) {
      return;
    }
    const readyToPublish: boolean = validatedPublishingJSON.validationState === 'READY_TO_PUBLISH';
    if (!readyToPublish) {
      this.validationErrorMessage = this.getErrorMessage(validatedPublishingJSON);
      this.publishProcessState = PublishProcessState.VALIDATION_FAILED;
      return;
    }
    this.publishProcessState = PublishProcessState.IN_PROGRESS;
    const readyForRedirect: boolean = await this.publishingsStore.publish(bulkPublishingJSON);
    if (readyForRedirect) {
      this.publishProcessState = PublishProcessState.DONE;
      this.$refs.timer.startTimer();
    }
  }

  getErrorMessage(validatedPublishingJSON: ValidatedPublishingJSON): TranslateResult {
    return extractPublishValidationError(validatedPublishingJSON, this.storesStore, this, true);
  }

  handleSelectedItems(selected: Array<SectionForPublishing>) {
    this.selectedSections = selected;
  }

  redirectToTemplates(): void {
    this.$router.push('/templates/');
  }

  /* GETTERS */
  getStore(storeId: string): Optional<Store> {
    return this.storesStore.getStoreById(storeId);
  }

  getStoreName(storeId: string): string {
    const storeModel: Optional<Store> = this.getStore(storeId);
    return storeModel?.name ?? storeId;
  }

  get templateId(): string {
    return this.$route.params.templateid;
  }

  /**
   * Returns true if the template contains an empty label
   */
  get gondolaTemplateContainsEmptyLabels(): boolean {
    return !!this.selectedGondolaTemplate?.railGrid
      .flat(1)
      .find((deviceTemplate: DeviceTemplate) =>
        deviceTemplate.foregroundContent.find(
          (foregroundContent: ForegroundContentModel) => foregroundContent.baseLayer.name === EMPTY_LABEL_PLACEHOLDER
        )
      );
  }

  /**
   * Returns true if the selected template has a valid background
   */
  get isSelectedGondolaTemplateWithValidBackground(): boolean {
    const flattenedDevices: DeviceTemplate[] | undefined = this.selectedGondolaTemplate?.railGrid.flat(1);
    if (!flattenedDevices) {
      return false;
    }
    return !flattenedDevices.some(
      (deviceTemplate: DeviceTemplate) =>
        deviceTemplate.backgroundContent?.length === 0 ||
        (deviceTemplate.backgroundContent[0].baseLayer?.type === ContentType.Playlist &&
          deviceTemplate.backgroundContent[0].baseLayer.playlist.length === 0)
    );
  }

  get isPublishingAvailable(): boolean {
    return !(
      this.selectedSections.length === 0 ||
      !this.isSelectedGondolaTemplateWithValidBackground ||
      this.gondolaTemplateContainsEmptyLabels
    );
  }

  get validationError(): boolean {
    return !!this.validationErrorMessage.length;
  }

  get isPublishingInProgress(): boolean {
    return this.publishProcessState === PublishProcessState.IN_PROGRESS;
  }

  get isPublishingDone(): boolean {
    return (
      this.publishProcessState === PublishProcessState.DONE ||
      this.publishProcessState === PublishProcessState.VALIDATION_FAILED
    );
  }

  get buttonText(): TranslateResult {
    if (this.publishProcessState === PublishProcessState.NOT_STARTED) {
      return this.$t(this.$i18nTranslationKeys.rePublishView.bulkPublish.$path);
    } else if (this.publishProcessState === PublishProcessState.VALIDATION_FAILED) {
      return this.$t(this.$i18nTranslationKeys.rePublishView.validationFailed.$path);
    }
    return this.$t(this.$i18nTranslationKeys.rePublishView.publishingSuccessful.$path);
  }

  get buttonCyProperty(): string {
    return this.pageType === PublishSectionsPageType.MATCHING_LAYOUTS ? 'publish-button-cy' : 'republish-button-cy';
  }

  get isActionDisabled(): boolean {
    return !this.isPublishingAvailable || this.isPublishingInProgress || this.isPublishingDone;
  }

  get buttonIcon(): string {
    return this.pageType === PublishSectionsPageType.MATCHING_LAYOUTS ? 'mdi-publish' : 'mdi-sync';
  }
}
