
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentType } from '@common/enums';
import { contentItemTypes } from '@client/components/ContentItems/utils';

@Component({
  methods: { contentItemTypes },
})
export default class TypeFilter extends Vue {
  /* DECLARATIONS */
  @Prop()
  onChange!: (filters: Array<ContentType>) => void;
  @Prop({ default: () => Object.values(ContentType) })
  private selectableContentType!: Array<ContentType>;
  @Prop({ default: () => [] })
  private defaultSelectedValues!: Array<ContentType>;

  private isMenuOpen: boolean = false;
  private selectedTypes: Array<ContentType> = [];
  private typesKey: number = 0;

  /* LIFECYCLE EVENTS */
  created(): void {
    if (this.defaultSelectedValues.length) {
      this.selectedTypes = this.defaultSelectedValues;
    }
  }
  /* METHODS */
  onValueClicked(value: ContentType) {
    const indexOfValue: number = this.selectedTypes.indexOf(value);
    if (indexOfValue >= 0) {
      this.selectedTypes.splice(indexOfValue, 1);
    } else {
      this.selectedTypes.push(value);
    }
    this.onChange(this.selectedTypes);
  }

  clearSelected(): void {
    this.selectedTypes = [];
    this.onChange(this.selectedTypes);
    // Force re-rendering the checkboxes to clear selection
    this.typesKey += 1;
  }

  onFilterOptionClick(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  /* GETTERS */
}
