import { OnlineStatus } from '@common/enums/OnlineStatus';
import { BackgroundContentModelJSON, ForegroundContentModelJSON } from '@common/schedule/types';
import { GondolaPublishingJSON, DeviceGroupJSON } from '@common/publishing/types';
import { ValidationErrorSeverity } from '@common/error/types';
import { DeviceData } from '../apim/definitions';

export interface LastKnownActiveNetwork {
  hardwareAddress?: string;
  address?: string;
  mask?: string;
  ssid?: string;
  bssid?: string;
  linkQuality?: string;
  signalLevel?: string;
  channel?: string;
  frequency?: string;
  bitRate?: string;
  connectivity?: string;
  security?: string;
  flags?: string;
}

export interface WifiConfig {
  expectedSsid?: string;
  status?: string;
  lastKnownActiveNetwork: LastKnownActiveNetwork;
  lastActivityDateTime?: string;
}

export interface Firmware {
  version: string;
  flashDate: string;
  systemVersion: string;
  /**
   * Array of the not implemented features handled by the device
   * If it's undefined, then it handles all features
   */
  notImplementedFlags?: Array<APIMFeature>;
}

export interface DeviceJSON {
  _id: string;
  longId?: string;
  shortId?: string;
  hash?: string;
  onlineStatus?: OnlineStatus;
  onlineStatusLastChanged?: Date;
  gondolaId?: string;
  customerId?: string;
  storeId?: string;
  hardwareModel?: string;
  rail?: string;
  wifiConfig?: WifiConfig;
  dimmingLevel?: number;
  firmware?: Firmware;
  notImplementedFeatures?: Array<APIMFeature>;
}

export enum HardwareModelIdentifier {
  VR06001 = 'VR06001',
  VR09001 = 'VR09001',
  VR12001 = 'VR12001',
  VP1080P = 'VP1080P',
}

export interface HardwareModelJSON {
  identifier: HardwareModelIdentifier;
}

export interface DeviceTemplateJSON {
  hardwareModel: string;
  backgroundContent: Array<BackgroundContentModelJSON>;
  foregroundContent: Array<ForegroundContentModelJSON>;
  railGroup: DeviceGroupJSON;
  previewUrl: string;
}

export interface DeviceTemplateWithIdJSON extends DeviceTemplateJSON {
  _id: string;
}

export interface DeviceValidationResultJSON {
  hasError: boolean;
  message?: string;
  device?: DeviceJSON;
  duplicateDeviceInOtherGondola?: DeviceJSON;
  duplicateDevicesInCurrentGondola?: GridPosition[]; // logically a Set, but that's not JSON.stringify-able
  severity: ValidationErrorSeverity;
}

export interface GridPosition {
  row: number;
  column: number;
}

export interface PublishingGridPosition extends GridPosition {
  isPublishable: boolean;
  deviceData?: DeviceData;
}

export interface DeviceSearchResultJSON {
  device: DeviceJSON;
  publishing?: GondolaPublishingJSON;
}

export enum APIMFeature {
  REGISTER_LABELS = 'registerlabels',
  UNREGISTER_LABELS = 'unregisteredLabels',
  MULTIPLE_LABELS = 'multipleLabels',
  SCHEDULED_CONTENT = 'scheduledContent',
  PLAYLISTS = 'playlistContent',
  VIDEOS_IN_LABELS = 'videoInLabels',
  IMAGE_IN_BACKGROUND = 'imageInBackground',
  DELETE_BACKGROUND = 'deleteBackground',
  FIRMWARE_RELEASE_MANAGEMENT = 'firmwareReleaseManagement',
  POWERSAVE = 'powerSave',
  WIFI_CONFIG = 'wifiConfigurations',
}
