import { AisleJSON } from '@common/stores/types';

export default class Aisle {
  name: string;
  railsSucceeded: number = 0;
  railsPending: number = 0;
  railsFailed: number = 0;

  constructor(name: string) {
    this.name = name;
  }

  static clone(source: Aisle): Aisle {
    return new Aisle(source.name);
  }

  static fromJSON(source: AisleJSON): Aisle {
    return new Aisle(source.name);
  }

  static toJSON(aisle: Aisle): AisleJSON {
    return {
      name: aisle.name,
    };
  }
}
