
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { FilterOption } from '@client/definitions/dateselector';
import { SortOrder } from '@common/enums/Search';

export type SortPickerModel = { sortOrder: SortOrder; orderByField: string };

/**
 * Renders a picker to select from a list of order by fields and their sort order (asc or desc)
 */
@Component({})
export default class SortPicker extends Vue {
  @Prop({ type: Boolean })
  private isLoading: boolean = false;
  @Prop()
  private sortOptions!: Array<FilterOption>;
  @Prop({ default: '' })
  /**
   * Cypress selector id
   */
  private dataCy?: string;
  @Model()
  private sortConfig!: { sortOrder: SortOrder; orderByField: string };
  /* LIFECYCLE EVENTS */
  /* METHODS */
  onSelectionChange(value: { label: string; value: string }): void {
    if (this.sortConfig.orderByField === value.value && this.sortConfig.sortOrder === SortOrder.desc) {
      this.sortConfig.sortOrder = SortOrder.asc;
    } else if (this.sortConfig.orderByField === value.value && this.sortConfig.sortOrder === SortOrder.asc) {
      this.sortConfig.sortOrder = SortOrder.desc;
    } else {
      this.sortConfig.sortOrder = SortOrder.desc;
      this.sortConfig.orderByField = value.value;
    }
    this.$emit('change', { sortOrder: this.sortConfig.sortOrder, orderByField: this.sortConfig.orderByField });
  }

  /* GETTERS */

  get isSortOrderDesc(): boolean {
    return this.sortConfig.sortOrder === SortOrder.desc;
  }
}
