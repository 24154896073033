import { GondolaTemplate } from '@client/models';
import { ContentReferencesJSON, PopulatedPlaylistJSON } from '@common/content-item/types';
import { GondolaTemplateWithIdJSON } from '@common/gondola-template/types';
import VideoModel from '@client/models/ContentModels/Video.model';
import ImageModel from '@client/models/ContentModels/Image.model';
import PlaylistModel from '@client/models/ContentModels/Playlist.model';
import FolderModel from '@client/models/ContentModels/Folder.model';
import { ContentType } from '@common/enums';

export class ContentReferences {
  playlistsContainingItem: Array<PlaylistModel>;
  templatesContainingItem: Array<GondolaTemplate>;

  constructor(playlistContainingItem: Array<PlaylistModel>, templatesContainingItem: Array<GondolaTemplate>) {
    this.playlistsContainingItem = playlistContainingItem;
    this.templatesContainingItem = templatesContainingItem;
  }

  static fromJSON(contentReferencesJSON: ContentReferencesJSON): ContentReferences {
    return new ContentReferences(
      contentReferencesJSON.playlistsContainingItem?.map((item: PopulatedPlaylistJSON) => PlaylistModel.fromJSON(item)),
      contentReferencesJSON.templatesContainingItem?.map((item: GondolaTemplateWithIdJSON) =>
        GondolaTemplate.fromJSON(item)
      )
    );
  }
}

/**
 * Type for holding currently applied filter values
 */
export type ContentItemFilters = {
  originalName?: string;
  type?: ContentType[];
  createdAtAsTime: [string?, string?];
  datePrefillOption?: string;
  dimensions?: string;
  duration: [number?, number?];
  fps?: number | null;
  parentFolder?: string;
};

export type ContentItemFiltersKeys = keyof ContentItemFilters;

/**
 * Types of properties inside ContentItemFilters
 */
export type ContentItemFiltersValues = ContentItemFilters[ContentItemFiltersKeys];

/**
 * Used for clearing filter value via chip, either clears one specific filter or clears all filters
 */
export type ContentItemFiltersClearOptions = ContentItemFiltersKeys | 'all';

export const EMPTY_LABEL_PLACEHOLDER: string = 'placeholder';

/**
 * Indicates that a {@link ScheduleLayer} layer exists only to hide the base object.<br/>
 * This value will be set in the URL field of the {@link ScheduleLayer}.<br/>
 * <i>Note that this is not the same as a {@link ScheduleLayer} that already had content set, since this is used only in {@link ScheduleLayer} where the visibility field is the only relevant value</i>
 */
export const HIDDEN_SCHEDULE_URL_VALUE: string = '#HIDDENLABEL#';

export type MediaFile = VideoModel | ImageModel;
export type Media = MediaFile | PlaylistModel;
export type ContentItem = Media | FolderModel;
