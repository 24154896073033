import { defineStore, StoreDefinition, Store } from 'pinia';
import { TranslateResult } from 'vue-i18n';

const MESSAGE_QUEUE_TIMEOUT: number = 5000;

export interface MessagesActions {
  showMessage(message: string | TranslateResult): void;
  applyRemoveMessageFromQueue(): void;
  removeMessageFromQueue(): void;
}

export interface MessagesState {
  displayedMessages: Array<string>;
  timeout?: ReturnType<typeof setTimeout>;
}

export type MessagesStoreDefinition = StoreDefinition<
  'messages',
  MessagesState,
  Record<string, never>,
  MessagesActions
>;

export type MessagesStore = Store<'messages', MessagesState, Record<string, never>, MessagesActions>;

export const useMessagesStore: MessagesStoreDefinition = defineStore('messages', {
  state: (): MessagesState => ({
    displayedMessages: new Array<string>(),
    timeout: undefined,
  }),
  actions: {
    applyRemoveMessageFromQueue() {
      this.displayedMessages.splice(0, 1);
    },
    showMessage(message: string) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
      this.timeout = setTimeout(() => {
        this.removeMessageFromQueue();
      }, MESSAGE_QUEUE_TIMEOUT);
      this.displayedMessages.push(message);
    },
    removeMessageFromQueue() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
      if (this.displayedMessages.length > 1) {
        this.timeout = setTimeout(() => {
          this.removeMessageFromQueue();
        }, MESSAGE_QUEUE_TIMEOUT);
      }
      this.applyRemoveMessageFromQueue();
    },
  },
});
