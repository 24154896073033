import { en as vuetify } from 'vuetify/src/locale/';
import { TranslationType } from '@client/plugins/i18n/locales/translationTypes';
import VueI18n from 'vue-i18n';
import LocaleMessageObject = VueI18n.LocaleMessageObject;

const englishTranslationObject: TranslationType = {
  $vuetify: vuetify, // import vuetify translations
  language: 'Language',
  contentManagement: {
    title: 'Content management',
    openInNewTab: 'Open content management page in a new tab',
    folder: 'Folder',
    image: 'Image',
    video: 'Video',
    playlist: 'Playlist',
    file: 'File',
    location: 'Location',
    searchResults: 'No results found | 1 Result found | {count} Results found',
    maximumResults: 'Showing top {count} results',
    filesToUpload: 'Uploading files to',
    isReadyToUpload: 'are ready to be uploaded.',
    selectedFiles: `<strong>{numberOfFiles}</strong> selected files <strong>({size})</strong>:`,
    filteredFiles:
      'Out of the {originalNumberOfFiles} files selected, only {allowedNumberOfFiles} can be uploaded, accepted extensions are ".gif",".png", ".apng" and ".mp4"',
    noMatchingFiles: 'Selected files are not compatible, accepted extensions are ".gif",".png", ".apng" and ".mp4"',
    andOtherFiles: 'And <strong>{numberOfFiles}</strong> other files...',
    uploadQueue: 'Upload queue',
    uploadHistory: 'Uploads',
    cancelOngoingUploads: 'Cancel uploads',
    cancelUpload: 'Cancel upload',
    uploadCancelled: 'Cancelled',
    error: 'Error',
    uploadedSuccessfully: 'Uploaded successfully',
    recomputeMetadata: 'Recompute file metadata',
    dimensions: 'Dimensions',
    assign: 'Assign',
    addFolderTitle: 'Add folder to',
    root: 'Root',
    noDataInFolder: 'No items in this folder.',
    folderNotFound: "The folder you're trying to navigate to doesn't exist! It might have been deleted.",
    duplicateItemInFolder:
      'Another item with the same name exists in this folder, playlists and folders must have unique names.',
    folderCannotMoveToChild: 'Cannot move folder to one of its children.',
    folderCannotMoveToSelf: 'Cannot move folder to itself.',
    itemAlreadyInFolder:
      ' - | This item is already in the selected folder. | One or multiple of these items are already in the selected folder.',
    movingItemTo:
      'Moving <strong class="primary--text">{itemToMove}</strong> to <strong class="{targetClass}">{target}</strong>',
    movingItems: '{count} items',
    assignContent: 'Assign content',
    previewFailed: 'Post processing has failed, please retry.',
    recomputePreview: 'Reprocess <span class="text-lowercase">{type}</span>',
    folderDeletedFromAnotherInstance:
      "The selected folder was deleted by another user, you've been redirected to the root folder",
    keepFolderStructure: 'Keep folder structure',
    foldersCreated: '{numberOfFolders} folders are created/found.',
  },
  storeOverview: {
    breadcrumb: 'Store overview',
    heading: 'Stores',
    title: 'Store overview',
  },
  devices: {
    shortId: 'Short ID',
    longId: 'Long ID',
    hardwareModel: 'Hardware model',
    store: 'Store',
    aisle: 'Aisle',
    section: 'Section',
    onlineStatus: 'Status',
    onlineStatusChanged: 'Online since {date}',
    offlineStatusChanged: 'Offline since {date}',
    publishState: 'Publish State',
    publishTemplateDate: 'Published on {date}',
    publishTemplateName: 'Last Published Template',
    deletedTemplate: 'The template has been deleted, since this was published',
    registrationDate: 'Registration date',
    hardwareDimensions: 'Hardware dimensions',
    dimmingLevel: 'Dimming level',
    firmware: 'Firmware',
    wifiConfig: {
      title: 'Wifi configuration',
      status: 'Status',
      address: 'Address',
      bitRate: 'Bit rate',
      bssid: 'BSSID',
      channel: 'Channel',
      connectivity: 'Connectivity',
      frequency: 'Frequency',
      hardwareAddress: 'Hardware address',
      mask: 'Mask',
      linkQuality: 'Link quality',
      ssid: 'SSID',
      signalLevel: 'Signal level',
      lastActivityDateTime: 'Last activity timestamp',
      expectedSSID: 'Expected SSID',
    },
    overviewBreadcrumb: 'Device overview',
    overviewPageTitle: 'Device overview page',
    dimensionOfDevice: 'Device dimension: {width}x{height}',
    exportToCSV: 'Export selected items to CSV',
    navigationTitle: 'Devices',
  },
  storeDetail: {
    breadcrumb: 'Store detail of {store}',
    heading: 'Store detail',
    title: 'Store detail view',
    vrConnect: 'VUSIONRail: Connect<br> QR Code',
    storeName: 'Store name',
    storeAzureId: 'Store Id Azure',
    timezone: 'Store timezone',
    publishingInfo: 'Preview might be outdated, because the Template was different at the moment of publishing',
    editStoreAzureIdWarning:
      'Changing the Azure Store Id will reset your active hours for the store. Thus all devices will be active all the time. Do you want to continue?',
    missingAzureId: 'Cannot perform action, the store Azure Id is empty',
    apimActionsDisabled: 'Cannot perform action, no devices set in the section',
    openTemplate: 'Open last published template - "{template}"',
    publishedTemplateDeleted: 'The published template has been deleted.',
    sectionNotPublished: 'This section is not yet published.',
    createTemplateFromSection: 'Create a template from section "{sectionName}" layout',
    activeHoursJobFailed: 'Active hours job failed, please re-save your configuration',
    syncDevices: 'Synchronize store',
    notFound: 'Store not found',
    storeNotFoundMessage: "The store could not be found. It might have been deleted or doesn't exist",
    returnToPage: 'Go to stores list',
  },
  gondolaView: {
    title: 'Create/Edit section',
    breadcrumbAdd: 'Add section for {store}',
    breadcrumbEdit: 'Section {position} in aisle {aisle}',
    step1: 'Position and layout',
    step2: 'Edit layout',
    step3: 'Set device IDs',
    position: 'Section',
    validation: {
      aisleReq: 'Aisle is required',
      templateReq: 'Template is required',
      positionReq: 'Section is required',
      positionInUse: 'Section already used',
    },
    next: 'Next',
    back: 'Back',
    deviceId: 'Device ID',
    confirmAndSave: 'Confirm matching and save',
    save: 'Save',
    manual: 'manually',
    fromTemplate: 'from template',
    validationError: {
      title: 'Validation error',
      text: 'There were issues saving the section configuration!',
      cancel: 'Abort saving',
      confirm: 'Ignore error and save',
    },
    unsavedChanges: {
      title: 'Unsaved changes',
      text: 'Changes you made are not yet saved!',
      cancel: 'Ignore changes',
      confirm: 'Save changes',
    },
    forceMove: {
      title: 'Devices already assigned!',
      cancel: 'Abort saving',
      confirm: 'Force move and save',
      message:
        'Devices can only be added to a single section. You can forcefully move devices from their current section to this section. Following devices are affected by this: ',
    },
    finishEditing: 'Finish editing to save',
    gondolaNotFoundMessage: "The section could not be found. It might have been deleted or doesn't exist.",
    returnToPage: 'Return to the store',
    notFound: 'Section not found',
  },
  publishDetailView: {
    breadcrumb: 'Publish',
    heading: 'Create new publish',
    time: 'Active time',
    redirectMsg: 'You will be redirected to the Store page in {time} seconds',
    successMsg: 'Successfully started publishing new content to all devices!',
    templatesFound:
      'No templates found matching the section layout | One template matches section layout | {n} templates match section layout',
    matchingLayoutsFilter: 'Show only templates with matching layouts',
    deviceError: {
      dimensionMismatch: 'Template does not match section dimensions',
      missingDeviceId: 'Section has devices with missing ID',
      publishingFailed: 'Publish failed for: {devices}',
      publishingFailedDevice: 'Device with ID {deviceId} with {status} {error}',
      backgroundBaseLayerMissing:
        'No background value seems to be set for one of the devices in the template. Any scheduled content that is assigned to this background cannot be deployed if the base layer has no content set!',
      emptyPublishing: 'The result of this publishing will be empty with the currently selected template.',
      gondolaHasEmptyLabels: 'Template cannot be published if it contains empty labels!',
      gondolaHasEmptyBackground: 'Template cannot be published if it contains an empty background!',
      devicesNotCompatible: 'Template cannot be published, some devices do not support this template',
      default: 'Unknown Error',
    },
    deviceState: {
      backgroundMissing: 'Background content is missing',
      backgroundSet: 'New background content is set',
    },
    validationError: {
      title: 'Validation error',
      text: 'The store and/or the selected template have been modified. You can still publish your current configuration or reload to update your local data',
      cancel: 'Abort saving',
      confirm: 'Ignore error and save',
    },
    timezoneError: {
      text: 'This store needs to have a timezone set to be able to publish',
    },
  },
  gondolaTemplateListView: {
    breadcrumb: 'Templates',
    title: 'Templates',
    actionPublish: 'Publish to sections',
    sameLayout: 'Sections with same layout',
    alreadyPublished: 'Already published sections',
  },
  filtersAndPagination: {
    sortOrder: {
      name: 'Name',
      createdAt: 'Creation date',
      updatedAt: 'Last updated',
    },
    resultsFound: 'No results found. | 1 result found. | {n} results found.',
    noResults: 'No results found with current filters.',
    noEntries: 'No entries created yet.',
    dateFilterPrefill: {
      oneHour: 'Last hour',
      oneDay: 'Today',
      oneWeek: 'Last week',
      oneMonth: 'Last month',
      custom: 'Custom',
    },
    publishStateFilter: {
      published: 'Published',
      pending: 'Pending',
      accepted: 'Accepted',
      failed: 'Failed',
      notSet: 'Nothing published',
    },
    clearFilter: 'Clear filter',
    enterSearchTerm: 'Enter the search term',
    selectPublishingStatusType: 'Select a status of publishing',
    selectOnlineStatus: 'Select a status of device',
    selectHardwareModel: 'Select hardware models',
    selectFileType: 'Select file type',
    enterMinValue: 'Enter minimal value',
    enterMaxValue: 'Enter maximal value',
    longerSeconds: '> {n} second | > {n} seconds',
    shorterSeconds: '< {n} second | < {n} seconds',
    betweenSeconds: '{0} - {1} seconds',
    clearAllFilters: 'Clear all filters',
  },
  gondolaTemplateDetailView: {
    breadcrumb: '{template}',
    templateName: 'Template name',
    title: 'Edit template',
  },
  rePublishView: {
    breadcrumb: 'Republish for template: {template}',
    title: 'Republish template',
    heading: 'Republish template',
    gondolas: 'Sections to be published',
    azurStoreId: 'Azure store Id',
    lastPublishDate: 'Last published on',
    linkHint: 'Open aisle in new tab',
    redirectMsg: 'You will be redirected to the template overview page in {time} seconds',
    noSections: {
      title: 'No sections found!',
      text: 'This template is not related to any sections.',
      btn: 'Go back',
    },
    publishStatus: 'Publish status',
    notUpToDate: 'Not up-to-date',
    upToDateText: 'Up-to-date',
    templateContainsEmptyLabels: 'Current template contains empty labels',
    bulkPublish: 'Publish template to selected sections',
    bulkRepublish: 'Republish template to selected sections',
    lastPublishedTemplate: 'Last published template',
    publishBreadcrumb: 'Publish for template {template}',
    notUpToDateMessage: 'This publishing is not up to date.',
    sectionNotSelectable: 'This section is not selectable because it contains a device without an ID.',
    validationFailed: 'Validation before publishing failed.',
    invalidHashTemplate: 'Template was updated in the meantime.',
    missingTemplate: 'Template was deleted in the meantime.',
    backgroundMissing: 'Template does not have a background.',
    backgroundBaseLayerMissing: 'Template does not have base content set.',
    sectionError: 'Some sections have an error. ',
    invalidHashStore: 'Section {section} from {store} was updated in the meantime.',
    dimensionMismatch: 'Section {section} from {store} has different layout than the selected template.',
    missingDeviceId: 'Section {section} from {store} has device without id',
    missingSection: 'Section with id: ${id} is missing.',
    missingStore: 'Store with id: ${id} is missing.',
    missingTimezone: 'Store {store} does not have timezone set. ',
    publishingSuccessful: 'Publishing successful.',
  },
  deviceView: {
    title: 'Edit device',
    breadcrumb: 'Edit row {row} column {col}',
    templateName: 'Template name',
    rowIdx: 'Row index in template',
    colIdx: 'Column index in template',
    deleteFG: 'Delete all labels',
    deleteBG: 'Delete background',
    uploading: 'Adding {file}:',
    uploadRate: 'Uploading {progress}% - [{loaded} of {total} KB]',
    processing: 'Processing @ {progress}%',
    availableFiles: 'No available items | 1 Available item | {count} Available items',
    contentItem: 'Content item',
    allLabels: 'all labels',
    uploadTarget: 'Set content for label {label}',
    setContentBG: 'Set content for background',
    fileType: 'Type',
    previewImage: 'Preview image',
    backgroundFileName: 'File name',
    createDate: 'Created date',
    width: 'Width',
    height: 'Height',
    duration: 'Duration',
    fps: 'FPS',
    download: 'Download',
    checkAll: 'Check all',
    uncheckAll: 'Uncheck all',
    baseBackground: 'base background',
    baseBackgroundDeleteMessage:
      'Remove the base background content? This will also delete all the background content for the existing schedules.',
    baseBackgroundMissingMessage: 'Set content for the base to enable scheduling content for the background.',
    baseForegroundMissingMessage: 'Set content for the base to enable scheduling content for the label.',
    createPlaylist: 'Create a new playlist',
    name: 'Name',
    preview: 'Preview',
    playlist: 'Playlist',
    video: 'Video',
    image: 'Image',
    none: 'None',
    playlistItems: 'Playlist items',
    hideLabels: 'Hide labels',
    videosOnly: 'Playlists can only contain videos, this list is only showing available videos.',
    chooseContent: 'Choose content',
    currentContentType: 'Current content type {type}',
    deletePlaylistItemWarning:
      'This content item is present in the following playlist(s), this will also remove this item from said playlist(s):',
    deleteContentItemTemplateReferenceWarning:
      'This content item is present in the following template(s), this will also remove this item from said template(s):',
    noItemsInPlaylist: 'No items in the playlist yet.',
    editContent: 'Edit content',
    background: 'Background',
    labels: 'Labels',
    label: 'Label {index}',
    contentBackgroundColor: 'Set preview background color',
    addLabel: 'Add label',
    removeLabel: 'Remove label',
    baseBackgroundAssignMessage: 'No content set - Click on the edit button to assign some content.',
    vueTour: {
      stepHover: 'Hover over the device to see the edit options!',
      stepEditContent: "Click on the edit button to edit the device's content.",
      stepEditModel: 'Click on the dropdown to edit the device model.',
    },
  },
  device: {
    selectModel: 'Device model',
    noContent: 'No content',
    status: {
      online: 'Online since {date}',
      offline: 'Offline since {date}',
    },
    statusNames: {
      online: 'Devices online',
      offline: 'Devices offline',
      unassigned: 'Devices unassigned',
    },
    copyLongId: 'Long device Id {shortId} copied to clipboard.',
    executing: 'Preparing content on your device...',
  },
  gondolaTemplate: {
    deleteDeviceModal: {
      title: 'Delete device from the template',
      text: 'Device already has a content set! This action cannot be reverted!',
    },
    label: {
      visible: 'Visible',
      hidden: 'Hidden',
    },
    notFound: 'Template not found',
    templateNotFoundMessage: "The template could not be found. It might have been deleted or doesn't exist",
    returnToPage: 'Go to templates list',
  },
  preview: {
    notAvailable: 'Preview not yet available.',
  },
  aisle: 'Aisle | Aisles',
  gondola: 'Section | Sections',
  store: 'Store | Stores',
  template: 'Template | Templates',
  action: {
    actions: 'Actions',
    publish: 'Publish',
    clone: 'Clone',
    edit: 'Edit',
    delete: 'Delete',
    upload: 'Upload',
    reload: 'Reload page',
    ignore: 'OK',
    close: 'Close',
    deleteEntity: 'Delete {entity}',
    createEntity: 'Create new {entity}',
    editEntity: 'Edit {entity}',
    remove: 'Remove',
    confirm: 'Confirm',
    addNewItem: 'Add new item',
    move: 'Move',
  },
  confirm: {
    deleteEntity: {
      title: 'Delete {entity}',
      text: 'Remove {entity} from the system? This action cannot be reverted!',
      delete: 'Delete',
      cancel: 'Cancel',
    },
  },
  search: 'Search',
  logout: 'Logout',
  editProfile: 'Profile',
  error: {
    headline: 'Oh snap!',
    validationHeadline: 'Validation error!',
    ok: 'Ok',
    searchFailed:
      'Error occurred during search, no results were found. | Error occurred during search, one result was found. | Error occurred during search, {n} results were found.',
    duplicateAzureId:
      'This Azure Store Id has already been assigned to another store: {0}. The value you entered cannot be saved',
    upload:
      'Error occurred during upload, most likely a not supported codec. Try again with x264 mp4 encoded video file or PNG encoded image.',
    maxLabels: "You can't add more Labels! The current maximum amount of Labels is {max}.",
    concurrentModification:
      'It seems like somebody else modified the data structure you are working on right now. Please reload this page to make a write operation. You might not be able to save until you reload the page.',
    StoreCRUDError: {
      AZURE_ID_EXISTING_DEVICES:
        'Cannot re-assign azure id for a store with devices. All device ids need to be removed first.',
      WEBHOOK_NOT_CREATED: 'A webhook for this store could not be created/updated, please try again later!',
      UPDATED_STORE_DID_NOT_EXIST: 'Could not update store: no previously existing store was found for this id.',
      INPUT_ERROR:
        "The values sent to the server couldn't be validated and/or were erroneous! Please reload the page and check your inputs!",
    },
    APIM: {
      MISSING_VRAIL_PERMISSIONS:
        'Could not create the store with current azure id "{azureId}", the store has been found but access is denied. Please contact your administrator.',
      MISSING_VCLOUD_SUBSCRIPTION:
        'Access denied due to invalid subscription key, call related to store with azure id "{azureId}". Please contact your administrator.',
      MISSING_VRAIL_KEY:
        'Could not create the store with the current azure id "{azureId}", your account is missing the videorail subscription config. Please contact your administrator.',
      INVALID_STORE:
        'No valid store was found with the current azure id "{azureId}", check your input and try again. If this error persists please contact your administrator.',
    },
    maxLength: 'Invalid input, maximum characters allowed {maxLength}.',
    requiredField: 'This field is required.',
    backendAuthentication:
      'Unable to authenticate to the backend. Maybe the server is currently being updated, please try again later',
    genericNotFound: 'The resource you tried to update could not be found! Please reload the page and try again!',
    genericDuplicate: 'A resource with the same name already exists!',
    genericUnexpected: 'Some unexpected error occurred - please try again',
    pageNotFoundTitle: 'Page not found',
    pageNotFoundDescription: 'The page you are looking for does not exist.',
    pageNotFoundFallback: 'Go to home page',
    authenticationErrorPageTitle: 'OOPS',
    authenticationErrorPageDescription:
      'It seems something went wrong during authentication. Try again and if the problem persists contact support',
    nonASCIIInput:
      'Invalid input, you may only use the characters A to Z, 0 to 9, and simple punctuation for the store Azure ID.',
    noSymbolsAllowed: 'Invalid input, special symbols are not allowed.',
  },
  publishState: {
    failed: 'Failed',
    published: 'Published',
    pending: 'Pending',
    accepted: 'Accepted',
  },
  deviceState: {
    failed: 'Publishing failed',
    pending: 'Publishing pending',
    accepted: 'Publishing accepted',
    noContent: 'Nothing published',
    missingDeviceId: 'Device ID not set',
    unknown: 'Publishing unknown',
  },
  deviceStateInfo: {
    unknown: 'Device Id might have changed',
  },
  copyOf: 'Copy of ',
  webSocketMessages: {
    connectionError:
      "It seems like your connection has an issue. We'll try to reconnect in 10 seconds. If this problem persists, please reload the page",
    connectionClose:
      "It seems like your connection has been closed by the server. We'll try to reconnect in 10 seconds. If this problem persists, please reload the page",
    connectionFail: "Connection to the server could not be created. We'll try to reconnect in 10 seconds.",
  },
  schedules: {
    schedule: 'schedule',
    color: 'Color',
    dateRange: 'Date range',
    timeRange: 'Time range',
    name: 'Name',
    recurrenceDays: 'Recurrence days',
    from: 'From',
    to: 'To',
    startTime: 'Start time',
    endTime: 'End time',
    requiredField: 'Indicates required field',
    errors: {
      dateRange: 'Please select a valid date range',
      name: 'Name must not be empty',
      recurrenceDays: 'Please choose at least one day',
      startTime: 'Start time must be valid (HH:mm)',
      endTime: 'End time must be valid (HH:mm) and bigger than start time',
      color: 'Please choose a color',
    },
    confirm: 'Confirm active status',
    confirmButton: 'Confirm active status and save',
    conflictWarning: 'If you confirm the following overlapping schedule(s) will be set as inactive : \n',
    active: 'Active',
    inactive: 'Inactive',
    scheduleActive: 'Schedule is active',
    scheduleInactive: 'Schedule is inactive',
    activeStatus: 'Active status',
    deleteOverride: 'Remove scheduled content',
    labelOptions: 'Label {index} actions:',
    label: 'Label : ',
    manageSchedules: 'Manage schedules',
    publishedAlert:
      'This schedule has already been deployed, do you really want to delete it? Note that the template needs to be re-published in order for the deletion to take effect.',
    deleteTitle: 'Delete schedule {schedule}?',
    editTitle: 'Edit schedule {schedule}',
    deleteAlert: 'Are you sure you want to delete this schedule?',
    scheduleOptions: 'Schedule actions:',
    base: 'Base',
    currentWeekFilter: 'Show current week only',
    activeOnlyFilter: 'Show active only',
    filter: 'Filter',
    selectedWeek: 'Selected week:',
    currentWeek: 'Current week',
    editScheduleNote: 'Note that the template needs to be re-published in order for the edit to take effect.',
  },
  notifications: {
    title: 'Notifications',
    types: {
      publishing: 'Publishing',
      online: 'Online status',
      registration: 'Registration status',
      activeHours: 'Active hours',
      label: 'Event types',
      storeSync: 'Store synchronization',
    },
    publishingRetry: {
      startingIn: 'Retrying in {minutes} minutes and {seconds} seconds',
      startingSoon: 'Starting soon...',
    },
    publishingAttempt: 'Retry attempt {attempt}',
    aisle: 'Aisle',
    section: 'Section',
    eventState: {
      inProgress: 'In progress',
      error: 'Error',
      accepted: 'Accepted',
      completed: 'Completed',
      cancelled: 'Cancelled',
      sendingToAPIM: 'Sending',
      sentToAPIM: 'Executing',
    },
    eventType: {
      updatingBackground: 'Updating background',
      deletingLabel: 'Deleting label',
      syncingLayer: 'Downloading content',
      initializingDevice: 'Initializing device',
      deviceSkipped: 'Device already published in a previous attempt',
      downloadingBackgroundContent: 'Downloading background content',
      downloadingLabelContent: 'Downloading content for label {index}',
      downloadingScheduledContentForLabel: 'Downloading scheduled content for label {index}',
      downloadingScheduledContentForBackground: 'Downloading scheduled content for background',
      unableToPreviewDownloadedContent: 'Unable to preview downloaded content',
      updatingLabel: 'Updating label',
      online: 'Online',
      offline: 'Offline',
      registered: 'Registered to store',
      unregistered: 'Unregistered from store',
      templatePublished: 'Template has been published',
    },
    brightnessLevelMessage: 'Brightness level set to {brightnessLevel}%',
    screenStatusMessage: 'Device screen set to OFF | Device screen set to ON | Device screen set to ON',
    activeHoursAccepted: 'Waiting for device response',
    noNotificationsAvailable: 'No notifications available!',
    resetFilters: 'Reset filters',
    relativeTime: {
      secondsAgo: '{time} second(s) ago',
      minutesAgo: '{time} minute(s) ago',
      hoursAgo: '{time} hour(s) ago',
      daysAgo: 'approximately {time} day(s) ago',
      monthsAgo: 'approximately {time} month(s) ago',
      yearsAgo: 'approximately {time} year(s) ago',
    },
    publishedWithDeletedTemplate: 'The template has been deleted, since this was published',
    publishedWithTemplate: 'Published template',
    searchTerm: 'Search by section, aisle or device id',
    advancedSearch: 'Advanced search',
    showMoreDevices: 'Show more (One more device) | Show more ({n} more devices)',
    activeHoursConfig: {
      activeHoursConfigSet: 'Changed {target} active hours config',
      activeHoursConfigRemoved: 'Removed {target} active hours config',
      off: 'Off',
    },
    storeSync: {
      title: 'Store synchronized',
      manual: 'Synchronization was manually triggered',
      automatic: 'Synchronization was automatically triggered',
      webhookRecreated: 'Webhook has been recreated',
      webhookUpToDate: 'Webhook is up-to-date',
      devicesAdded: '1 Device added | {n} Devices added',
      devicesRemoved: '1 Device removed | {n} Devices removed',
      devicesUpdated: '1 Device updated | {n} Devices updated',
      deviceSynced: 'Device has been updated',
    },
  },
  activeHours: {
    activeHours: 'Active hours',
    editDeviceMessage: 'Edit device active hours',
    title: 'Active hours for store',
    status: 'status',
    day: 'day',
    from: 'from',
    to: 'to',
    activeAllDay: 'Devices are active for the whole day',
    turnedOffAllDay: 'Devices are turned off for the whole day',
    specificTimeActive: 'Devices are active only within a specified time-slot for this day',
    revert: 'Revert to devices being active for the whole day',
    turnOff: 'Turn off devices for this day',
    save: 'Save active hours',
    section: {
      revert: 'Reset',
      revertTooltip: 'Section active hours will be removed and store active hours will be in effect.',
      filterMessage: 'Has active hours overrides',
      hideEmptyAisles: 'Hide empty aisles',
      title: 'Active hours for section',
      noSectionsFound: 'No sections match the currently applied filters',
      noCompatibleDevicesTitle: 'No compatible devices',
      noCompatibleDevicesError: "The section doesn't contain any devices that handle active hours.",
    },
    weekdays: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
  },
  qrCode: {
    copiedQRCodeToClipboard: 'QR Code copied to clipboard.',
    copyQRCodeToClipboard: 'Copy QR Code to clipboard',
    downloadQRCodeAs: 'Download QR Code as {format}',
  },
  vueTour: {
    buttons: {
      buttonNext: 'Next',
      buttonPrevious: 'Previous',
      buttonSkip: 'Close',
      buttonStop: 'Ok',
    },
  },
  deviceDetail: {
    title: 'Device details',
    breadcrumb: 'Device {longId} details',
    notFound: 'Device not found!',
    loadMore: 'Load more',
    events: 'Events',
    publishedSchedules: 'Published schedules',
    notAssigned: 'Not assigned',
    noEvents: 'No events available for this device.',
    noPublishing: 'No template has been published yet.',
    deviceNotFoundMessage: "The device you're looking for couldn't be found",
    returnToPage: 'Go to devices page',
    synchronize: 'Synchronize',
  },
};

export default englishTranslationObject as unknown as LocaleMessageObject;
