import VueRouter from 'vue-router';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { B2CUser } from '@client/models';
import { templateRoutes } from '@client/router/TemplateRoutes';
import { storeRoutes } from '@client/router/StoreRoutes';
import { Route, RouteConfig } from 'vue-router/types/router';
import { NextFunction } from 'express';
import { MSALBasic } from '@client/plugins/vue-msal/src/types';
import { ErrorResponse, ErrorType } from '@common/error/types';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { ErrorStore, useErrorStore } from '@client/stores/error';
import { errorRoutes } from '@client/router/ErrorRoutes';
import { deviceRoutes } from '@client/router/DeviceRoutes';

const routes: RouteConfig[] = [...templateRoutes, ...storeRoutes, ...deviceRoutes, ...errorRoutes];

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Interceptor to handle any error
axios.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  async function (error: AxiosError<ErrorResponse>) {
    const errorStore: ErrorStore = useErrorStore();
    const errorType: ErrorType = error?.response?.data?.errorType || ErrorType.UNKNOWN_ERROR;
    // call specific handlers. If there is no specific handler, notify default handler
    if (errorType.name === ErrorType.INVALID_AUTHENTICATION.name) {
      errorStore.setErrorType(errorType.name);
      if (router.currentRoute.name === 'error') {
        return Promise.reject(error);
      }
      await router.replace('/error');
      return Promise.reject(error);
    }
    errorStore.notify({ errorType: errorType, error: error });
    return Promise.reject(error);
  }
);

router.beforeEach((to: Route, _from: Route, next: NextFunction) => {
  const authService: MSALBasic = router.app.$msal;
  const appGlobal: AppGlobalStore = useAppGlobalStore();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated()) {
      const b2cUser: B2CUser = authService.data.user as B2CUser;
      const customer: string = appGlobal.getCustomer;
      if (customer != b2cUser.idToken.extension_companyId) {
        appGlobal.setUser(b2cUser.accountIdentifier);
        appGlobal.setCustomer(b2cUser.idToken.extension_companyId);
      }
      return next();
    }

    // Otherwise, log in
    authService.signIn(to.fullPath);
  };

  return fn();
});

export default router;
