
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WifiConfig } from '@common/device/types';
import DoubleIcon from '@client/components/DoubleIcon/DoubleIcon.vue';

@Component({
  components: { DoubleIcon },
})
export default class WifiConfigDialog extends Vue {
  /* DECLARATIONS */
  @Prop()
  private wifiConfig!: WifiConfig;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
