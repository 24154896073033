import { ContentType, LayerVisibility, ScheduleDay } from '@common/enums';
export interface LayerPlaylistItemJSON {
  _id?: string;
  name: string;
  url: string;
  previewUrl?: string;
  type: ContentType;
  height: number;
  width: number;
  duration?: number;
  fps?: number;
  offsetX: number;
  offsetY: number;
  zIndex: number;
  checksum?: string;
  hideLabels: boolean;
  layerId: string;
}

export interface BaseLayerModelJSON {
  _id?: string;
  name: string;
  url: string;
  previewUrl?: string;
  type: ContentType;
  height: number;
  width: number;
  duration?: number;
  fps?: number;
  offsetX: number;
  offsetY: number;
  zIndex: number;
  checksum?: string;
  layerId?: string;
  playlist?: Array<LayerPlaylistItemJSON>;
}

export interface ScheduleLayerModelJSON {
  name: string;
  url: string;
  previewUrl?: string;
  type: ContentType;
  height: number;
  width: number;
  duration?: number;
  fps?: number;
  offsetX: number;
  offsetY: number;
  zIndex: number;
  checksum?: string;
  layerId?: string;
  visibility: LayerVisibility;
  playlist?: Array<LayerPlaylistItemJSON>;
}

export interface ScheduledContentModelJSON {
  scheduleId: string;
  layer: ScheduleLayerModelJSON;
}

export interface PublishingScheduledContentModelJSON {
  schedule?: ScheduleModelJSON;
  layer: ScheduleLayerModelJSON;
}

export interface PublishingBackgroundContentModelJSON {
  baseLayer?: BaseLayerModelJSON;
  scheduledContent?: Array<PublishingScheduledContentModelJSON>;
}

export interface PublishingForegroundContentModelJSON {
  baseLayer: BaseLayerModelJSON;
  scheduledContent?: Array<PublishingScheduledContentModelJSON>;
}

export interface BackgroundContentModelJSON {
  baseLayer?: BaseLayerModelJSON;
  scheduledContent?: Array<ScheduledContentModelJSON>;
}

export interface ForegroundContentModelJSON {
  baseLayer: BaseLayerModelJSON;
  scheduledContent?: Array<ScheduledContentModelJSON>;
}

export interface ScheduleSpanModelJSON {
  validityFrom: Date;
  validityTo: Date;
  recurrenceStart: string;
  recurrenceEnd: string;
  recurrenceDays: ScheduleDay[];
}

export interface ScheduleModelJSON {
  _id?: string;
  name: string;
  color: string;
  customerId: string;
  gondolaTemplates: string[];
  visibility: ScheduleVisibility;
  spans: ScheduleSpanModelJSON[];
  active: boolean;
  hash?: string;
}

export enum ScheduleVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
}
