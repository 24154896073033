
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ModalDialog } from '@client/components';
import { isEndTimeBeforeStartTime, timeValidationRegex } from '@client/utils/DateTimeUtils';
import { Optional } from '@common/types';
import { ActiveHourDay, ActiveHourItem, ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { TranslateResult } from 'vue-i18n';
import TimePicker from '@client/components/TimePicker/TimePicker.vue';
import { isActiveAllDay, equals } from '@client/utils/ActiveHoursUtils';
import { Gondola, Store } from '@client/models';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';

/**
 * Object for representing existence of an error in start and end time input
 */
export interface TimeValidationError {
  startTime: boolean;
  endTime: boolean;
  endTimeBigger: boolean;
}

@Component({
  components: {
    TimePicker,
    ModalDialog,
  },
  methods: {
    isActiveAllDay,
  },
})
export default class ActiveHours extends Vue {
  @Prop({ default: false })
  private isIcon!: boolean;
  @Prop()
  private isDisabled!: boolean;
  @Prop()
  private isMissingAzureId!: boolean;
  @Prop()
  private storeId!: string;
  @Prop()
  private activeHoursSchedule!: ActiveHoursSchedule;
  @Prop()
  private activeHoursJobFailed!: boolean;
  /**
   * Prop which holds store active hours in case this component is used inside {@link SectionActiveHours}
   * Undefined in case this component is used in {@link StoreActiveHours}
   */
  @Prop()
  private initialStoreActiveHours?: ActiveHoursSchedule;
  /**
   * Prop that holds the gondola id of the section in case this is a section active hours
   * will be undefined for store level active hours
   * @private
   */
  @Prop()
  private gondolaId?: string;
  private localActiveHoursSchedule: Optional<ActiveHoursSchedule> = [];
  private static readonly NUMBER_OF_DAYS_IN_WEEK: number = 7;
  errors: TimeValidationError[] = [];
  showDialog: boolean = false;
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();

  @Watch('activeHoursSchedule')
  initValues(): void {
    if (this.activeHoursSchedule) {
      // deep copy of prop to local field, so we can discard changes in case of cancel
      this.localActiveHoursSchedule = this.activeHoursSchedule.map((element: ActiveHourItem, index: number) => {
        // Also reinitialize the timepicker components
        (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
        (this.$refs[`startTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
        return element.clone();
      });
      this.errors = Array.from({ length: ActiveHours.NUMBER_OF_DAYS_IN_WEEK }, () => ({
        startTime: false,
        endTime: false,
        endTimeBigger: false,
      }));
    }
  }

  /**
   * Check whether devices are active whole day or only for specific time
   * Based on this check, determine which icon should be used
   * @param activeHours for that specific day
   */
  determineTimeIcon(activeHours: ActiveHourDay[]): string {
    return isActiveAllDay(activeHours) ? 'mdi-hours-24' : 'mdi-clock-time-four-outline';
  }

  /**
   * After clicking cancel, discard any changes
   */
  cancel(): void {
    this.showDialog = false;
    this.initValues();
  }

  private isEndTimeBeforeStartTime(index: number, startTime: string, endTime: string): boolean {
    const hasError: boolean = isEndTimeBeforeStartTime(startTime, endTime);
    this.errors[index].endTimeBigger = hasError;
    return hasError;
  }

  /**
   * Check if there are no errors and send data to server
   */
  async saveActiveHours(): Promise<void> {
    const hasError: boolean = this.errors.some(
      (error: TimeValidationError) => error.startTime || error.endTime || error.endTimeBigger
    );
    if (hasError) {
      return;
    }
    this.$emit('onSave', this.localActiveHoursSchedule);
    this.showDialog = false;
  }

  validateStartTime(value: string, activeHourItem: ActiveHourItem, index: number): Array<string | TranslateResult> {
    const isValid: boolean = timeValidationRegex.test(value);
    this.errors[index].startTime = !isValid;
    if (isValid) {
      activeHourItem.schedule[0].start = value;
      // We need to re-trigger the end time validation AFTER we set the start time,
      // Since we're using the state start time to compare to the actual end time, else it would compare the old value
      (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.onChangeOrInput(
        activeHourItem.schedule[0].end
      );
      return [];
    }
    (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.onChangeOrInput(
      activeHourItem.schedule[0].end
    );
    return [this.$t(this.$i18nTranslationKeys.schedules.errors.startTime.$path)];
  }

  validateEndTime(value: string, activeHourItem: ActiveHourItem, index: number): Array<string | TranslateResult> {
    const isValid: boolean = timeValidationRegex.test(value);
    const isEndOfDay: boolean = value === '24:00';
    const hasEndTimeError: boolean = this.isEndTimeBeforeStartTime(index, activeHourItem.schedule[0].start, value);
    this.errors[index].endTime = !isValid && (hasEndTimeError || !isEndOfDay);
    if (isValid || isEndOfDay) {
      activeHourItem.schedule[0].end = value;
    }
    if ((isValid && !hasEndTimeError) || isEndOfDay) {
      return [];
    }
    return [this.$t(this.$i18nTranslationKeys.schedules.errors.endTime.$path)];
  }

  /**
   * Check if devices are turned off for this day
   * Represented by empty array
   * @param activeHours schedule for specific day
   */
  isTurnedOff(activeHours: ActiveHourDay[]): boolean {
    return !activeHours.length;
  }

  created(): void {
    this.initValues();
  }

  /**
   * Make devices active all day for selected day
   * @param activeHourItem selected day
   * @param index of day, used for error
   */
  resetActiveHours(activeHourItem: ActiveHourItem, index: number): void {
    if (!this.localActiveHoursSchedule) {
      return;
    }
    const activeAllDayItem: ActiveHourItem = new ActiveHourItem(activeHourItem.day, [
      new ActiveHourDay('00:00', '24:00', 100),
    ]);

    const arrayIndex: number = this.localActiveHoursSchedule.indexOf(activeHourItem);
    if (arrayIndex !== -1) {
      Vue.set(this.localActiveHoursSchedule, arrayIndex, activeAllDayItem);
      this.resetValidationErrors(index);
      (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
      (this.$refs[`startTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
    }
  }

  private resetValidationErrors(index: number) {
    const errorFreeObject: TimeValidationError = { startTime: false, endTime: false, endTimeBigger: false };
    Vue.set(this.errors, index, errorFreeObject);
  }

  /**
   * Turn off devices for selected day
   * @param activeHourItem selected day
   * @param index of day, used for error
   */
  turnOff(activeHourItem: ActiveHourItem, index: number): void {
    if (!this.localActiveHoursSchedule) {
      return;
    }
    const turnedOffItem: ActiveHourItem = new ActiveHourItem(activeHourItem.day, []);
    const arrayIndex: number = this.localActiveHoursSchedule.indexOf(activeHourItem);
    (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
    (this.$refs[`startTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
    if (arrayIndex !== -1) {
      Vue.set(this.localActiveHoursSchedule, arrayIndex, turnedOffItem);
      this.resetValidationErrors(index);
    }
  }

  /**
   * Checks if every device is active whole day
   */
  isActiveHoursStateDefault(): boolean {
    if (!this.localActiveHoursSchedule) {
      return true;
    }
    //in case of section active hours
    if (this.initialStoreActiveHours) {
      return equals(this.localActiveHoursSchedule, this.initialStoreActiveHours);
    }
    //in case of store active hours
    return !this.localActiveHoursSchedule.filter((item: ActiveHourItem) => !isActiveAllDay(item.schedule)).length;
  }

  get isLoading(): boolean {
    return (
      this.activeHoursStore.loadingIndicator.update ||
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update
    );
  }

  getStartTimeFromActiveHourDay(activeHourSchedule: Array<ActiveHourDay>): string {
    return activeHourSchedule[0]?.start || '00:00';
  }

  getEndTimeFromActiveHourDay(activeHourSchedule: Array<ActiveHourDay>): string {
    return activeHourSchedule[0]?.end || '24:00';
  }

  /**
   * Reset section specific active hours to store level active hours
   */
  resetToStoreActiveHours(): void {
    if (!this.localActiveHoursSchedule) {
      return;
    }
    for (let i: number = 0; i < ActiveHours.NUMBER_OF_DAYS_IN_WEEK; i++) {
      if (!this.initialStoreActiveHours) {
        return;
      }
      Vue.set(this.activeHoursSchedule, i, this.initialStoreActiveHours[i]);
    }
    this.$emit('onReset');
    this.showDialog = false;
  }

  get isSectionScope(): boolean {
    return !!this.gondolaId;
  }

  get gondolaName(): Optional<string> {
    if (!this.gondolaId) {
      return '';
    }
    const currentStore: Optional<Store> = this.storesStore.getStoreById(this.storeId);
    const currentGondola: Optional<Gondola> = currentStore?.gondolas.find(
      (gondola: Gondola) => gondola._id === this.gondolaId
    );
    if (!currentStore || !currentGondola) {
      return this.gondolaId;
    }
    return `${currentGondola.aisle}-${currentGondola.positionInAisle}`;
  }

  get buttonTooltipMessage(): TranslateResult {
    if (this.isMissingAzureId) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.missingAzureId.$path);
    }
    if (this.isDisabled) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.apimActionsDisabled.$path);
    }
    if (this.activeHoursJobFailed) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.activeHoursJobFailed.$path);
    }
    return this.$t(this.$i18nTranslationKeys.activeHours.editDeviceMessage.$path);
  }

  get isButtonDisabled(): boolean {
    return this.isDisabled || this.isMissingAzureId;
  }

  /**
   * Determines which icon will be used based on AH configuration
   */
  get icon(): string {
    if (this.activeHoursJobFailed) {
      return 'mdi-clock-alert';
    }
    return this.isActiveHoursStateDefault() ? 'mdi-clock' : 'mdi-clock-check';
  }
}
