import { defineStore, StoreDefinition, Store } from 'pinia';
import { Breadcrumb } from './types';

export interface BreadcrumbsActions {
  push(data: Breadcrumb): void;
  replace(data: Breadcrumb): void;
}

export interface BreadcrumbsState {
  breadcrumbs: Array<Breadcrumb>;
}

export type BreadcrumbsStoreDefinition = StoreDefinition<
  'breadcrumbs',
  BreadcrumbsState,
  Record<string, never>,
  BreadcrumbsActions
>;

export type BreadcrumbsStore = Store<'breadcrumbs', BreadcrumbsState, Record<string, never>, BreadcrumbsActions>;

export const useBreadcrumbsStore: BreadcrumbsStoreDefinition = defineStore('breadcrumbs', {
  state: (): BreadcrumbsState => ({
    breadcrumbs: Array<Breadcrumb>(),
  }),
  getters: {},
  actions: {
    push(breadcrumb: Breadcrumb) {
      const index: number = this.breadcrumbs.findIndex((storeBreadcrumb: Breadcrumb) => {
        return storeBreadcrumb.path === breadcrumb.path;
      });
      if (index >= 0) {
        this.breadcrumbs.splice(index + 1);
      } else {
        this.breadcrumbs.push(breadcrumb);
      }
    },
    replace(breadcrumb: Breadcrumb) {
      this.breadcrumbs = new Array<Breadcrumb>(breadcrumb);
    },
  },
});
