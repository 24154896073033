
import { Component, Vue } from 'vue-property-decorator';
import router from '@client/router';
import { Store } from '@client/models';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { ErrorStore, useErrorStore } from '@client/stores/error';
import { StoresStore, useStoresStore, StoreGondolaWrapper } from '@client/stores/stores';
import { Optional } from '@common/types';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import StoreListToolbar from '@client/components/StoreListToolbar/StoreListToolbar.vue';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import spacetime from 'spacetime';
import { ViewActions } from '@client/enums';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { getStoreDetailPath } from '@client/router/utils';

@Component({
  components: { StoreListToolbar },
})
export default class StoreListView extends Vue {
  private static readonly ERROR_OBSERVER_KEY: string = 'StoreListView';

  private showSearchFailedErrorModal: boolean = false;
  private searchFailedErrorMessage: string = '';
  private search: string = '';

  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private errorStore: ErrorStore = useErrorStore();
  private storesStore: StoresStore = useStoresStore();
  private devicesStore: DevicesStore = useDevicesStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();

  async created(): Promise<void> {
    this.breadcrumbsStore.replace({
      path: `${this.$route.fullPath}`,
      title: { key: this.$i18nTranslationKeys.storeOverview.breadcrumb.$path },
    });
    await this.storesStore.fetch();
  }

  async addStore(): Promise<void> {
    const storeId: string = await this.storesStore.add(
      new Store('', '', this.appGlobalStore.customer, spacetime().timezone().name, [])
    );
    await router.push({
      path: `/stores/${storeId}`,
      query: { action: ViewActions.Add },
    });
  }

  beforeDestroy(): void {
    this.errorStore.deregister(StoreListView.ERROR_OBSERVER_KEY);
  }

  hideSearchFailedErrorModal(): void {
    this.showSearchFailedErrorModal = false;
  }

  rowClick(selectedStore: Store): void {
    router.push(`/stores/${selectedStore._id}`);
  }

  navigateToDeviceById(): void {
    const storeGondolas: Optional<StoreGondolaWrapper> = this.storesStore.getGondolaByDeviceId(this.search);
    if (!storeGondolas) {
      this.searchFailedErrorMessage = this.$tc(this.$i18nTranslationKeys.error.searchFailed.$path, 0);
      this.showSearchFailedErrorModal = true;
    } else {
      this.$router.push(
        getStoreDetailPath(
          storeGondolas.storeModel._id,
          storeGondolas.gondolaModel.aisle,
          storeGondolas.gondolaModel.positionInAisle
        )
      );
    }
  }

  get headers(): CustomDataTableHeader[] {
    return [
      {
        text: this.$tc(this.$i18nTranslationKeys.store.$path),
        align: 'left',
        sortable: true,
        value: 'name',
      },
      { text: this.$tc(this.$i18nTranslationKeys.aisle.$path, 2), value: 'numberOfAisles' },
      { text: this.$tc(this.$i18nTranslationKeys.gondola.$path, 2), value: 'numberOfGondolas' },
      {
        text: this.$tc(this.$i18nTranslationKeys.device.statusNames.online.$path, 3),
        value: 'numberOfOnlineDevices',
      },
      {
        text: this.$tc(this.$i18nTranslationKeys.device.statusNames.offline.$path, 4),
        value: 'numberOfOfflineDevices',
      },
      {
        text: this.$tc(this.$i18nTranslationKeys.device.statusNames.unassigned.$path, 5),
        value: 'numberOfUnassignedDevices',
      },
    ];
  }

  get loading(): boolean {
    return !this.storesStore.fetched || this.storesStore.isFetching || this.devicesStore.isFetching;
  }

  get stores(): Array<Store> {
    return this.storesStore.stores;
  }
}
