
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import { getCreatedAtFormatted, getRelativeTimeFromNow } from '@client/utils/DateTimeUtils';
import NotificationTimeDisplay from '../NotificationTimeDisplay.vue';
import NotificationTitle from '../NotificationTitle.vue';
import { scrollToNotification } from '@client/components/NotificationPanel/utils';
import { Optional } from '@common/types';
import { EventLogsStore, useEventLogsStore } from '@client/stores/eventLogs';
import { StoreActiveHoursEventLog } from '@client/models/EventLogModels/StoreActiveHoursEventLog/StoreActiveHoursEventLog.model';
import { StoreActiveHoursDeviceEvent } from '@client/models/EventLogModels/StoreActiveHoursEventLog/StoreActiveHoursDeviceEvent.model';

@Component({
  methods: { getCreatedAtFormatted, getRelativeTimeFromNow },
  components: { NotificationTimeDisplay, NotificationTitle },
})
export default class StoreActiveHoursNotification extends Vue {
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: StoreActiveHoursEventLog;
  private devicesExpanded: boolean = false;
  private initialNumberOfDevicesShown: number = 10;
  private maximumNumberOfDevicesToShow: number = this.initialNumberOfDevicesShown;

  private eventLogsStore: EventLogsStore = useEventLogsStore();

  getMessageFromDevice(deviceEvent: StoreActiveHoursDeviceEvent): string {
    return ` ${this.$tc(this.$i18nTranslationKeys.notifications.screenStatusMessage.$path, deviceEvent.dimmingLevel)}`;
  }

  onNotificationClick(): void {
    if (!this.devicesExpanded) {
      this.devicesExpanded = true;
    }
  }

  onExpandToggleClick(): void {
    this.devicesExpanded = !this.devicesExpanded;
    if (!this.devicesExpanded) {
      scrollToNotification(this.eventLog._id);
    }
  }

  navigateToStore(): void {
    if (this.activeHoursStore) {
      this.$router.push(`/stores/${this.activeHoursStore._id}`);
    }
  }

  incrementMaximumNumberOfDevices(): void {
    this.maximumNumberOfDevicesToShow += this.initialNumberOfDevicesShown;
  }

  get deviceEvents(): Array<StoreActiveHoursDeviceEvent> {
    if (this.eventLogsStore.filters.searchTerm) {
      return this.eventLog.devices.sort((a: StoreActiveHoursDeviceEvent, b: StoreActiveHoursDeviceEvent) => {
        const aIncludesId: boolean =
          a.longId.includes(this.eventLogsStore.filters.searchTerm) ||
          a.shortId.includes(this.eventLogsStore.filters.searchTerm);
        const bIncludesId: boolean =
          b.longId.includes(this.eventLogsStore.filters.searchTerm) ||
          b.shortId.includes(this.eventLogsStore.filters.searchTerm);

        if (aIncludesId && bIncludesId) {
          // Both elements include the searchByDeviceId, maintain their relative order
          return 0;
        } else if (aIncludesId && !bIncludesId) {
          // Only a includes the searchByDeviceId, move a to a lower index
          return -1;
        } else if (!aIncludesId && bIncludesId) {
          // Only b includes the searchByDeviceId, move b to a lower index
          return 1;
        } else {
          // Neither element includes the searchByDeviceId, maintain their relative order
          return 0;
        }
      });
    }
    return this.eventLog.devices;
  }

  get parsedDeviceEvents(): Array<StoreActiveHoursDeviceEvent> {
    return this.deviceEvents.slice(0, this.maximumNumberOfDevicesToShow);
  }

  get showMoreDeviceVisible(): boolean {
    return this.deviceEvents.length > this.maximumNumberOfDevicesToShow;
  }

  get numberOfDevicesHidden(): number {
    return this.deviceEvents.length - this.maximumNumberOfDevicesToShow;
  }

  get numberOfDevices(): number {
    return this.eventLog.devices.length;
  }

  get activeHoursStore(): Optional<Store> {
    if (!this.eventLog.store) {
      return undefined;
    }
    return this.eventLog.store;
  }

  get activeHoursStoreName(): string {
    return this.activeHoursStore?.name || this.eventLog.azureStoreId;
  }

  get notificationTitle(): string {
    const firstDeviceEvent: StoreActiveHoursDeviceEvent = this.eventLog.devices[0];
    const store: Optional<Store> = this.activeHoursStore;
    if (!store) {
      return `${this.eventLog.azureStoreId} - ${this.getMessageFromDevice(firstDeviceEvent)}`;
    }
    return ` - ${this.getMessageFromDevice(firstDeviceEvent)}`;
  }

  get dateToDisplay(): string | Date {
    return this.eventLog.createdAt;
  }

  get notificationBadgesVisible(): boolean {
    return this.devicesExpanded;
  }

  get linkToStore(): string {
    if (!this.activeHoursStore) {
      return '';
    }
    return `/stores/${this.activeHoursStore._id}`;
  }
}
