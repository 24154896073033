import { defineStore, StoreDefinition, Store } from 'pinia';
import axios, { AxiosResponse } from 'axios';
import Config from '@client/utils/config';
import { Optional } from '@common/types';
import Vue from 'vue';
import {
  BackgroundContent,
  BaseLayer,
  ForegroundContent,
  GondolaTemplate as GondolaTemplateModel,
  GondolaTemplate,
  HardwareModel,
  DeviceTemplate,
  ScheduledContent,
  ScheduleLayer,
} from '@client/models';
import { GondolaTemplateZoomCache, DeviceContentSelection } from './types';
import { GondolaTemplateWithIdJSON, NewGondolaTemplateJSON } from '@common/gondola-template/types';
import i18n from '@client/plugins/i18n/i18n';
import { HardwareModelJSON } from '@common/device/types';
import DeviceGroup from '@client/models/DeviceModels/DeviceGroup.model';
import { BackgroundContentModelJSON, ForegroundContentModelJSON, ScheduleModelJSON } from '@common/schedule/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { LayerVisibility } from '@common/enums';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import { EMPTY_LABEL_PLACEHOLDER } from '@client/models/ContentModels/types';
import { TranslationKeys } from '@client/plugins/i18n/locales';

export interface GondolaTemplatesGetters {
  getById: (state: GondolaTemplatesState) => (id: string) => Optional<GondolaTemplate>;
  getZoomLevelById: (state: GondolaTemplatesState) => (id: string) => number;
  findLikeName: (state: GondolaTemplatesState) => (nameQuery: string) => Optional<GondolaTemplate>;
}

export interface GondolaTemplatesActions {
  fetch(): Promise<void>;
  updateAll(gondolaTemplates: Array<GondolaTemplateModel>): void;
  create(gondolaTemplate: NewGondolaTemplateJSON): Promise<string | undefined>;
  clone(gondolaTemplateId: string): Promise<string | undefined>;
  updateName(gondolaTemplateId: string, name: string): Promise<void>;
  addDeviceRow(deviceTemplate: DeviceTemplate, gondolaTemplateId: string): Promise<void>;
  addDeviceCol(deviceTemplate: DeviceTemplate, gondolaTemplateId: string, row: number): Promise<void>;
  updateHardwareModel(
    deviceTemplate: DeviceTemplate,
    hardwareModel: HardwareModel,
    gondolaTemplateId: string,
    row: number,
    col: number
  ): Promise<void>;
  updateDeviceGroup(
    deviceTemplate: DeviceTemplate,
    deviceGroup: DeviceGroup,
    gondolaTemplateId: string,
    row: number,
    col: number
  ): Promise<void>;
  updateDeviceBackground(
    deviceTemplate: DeviceTemplate,
    backgroundContent: Array<BackgroundContent>,
    gondolaTemplateId: string,
    row: number,
    col: number
  ): Promise<void>;
  deleteDeviceForegroundSlot(
    gondolaTemplateId: string,
    row: number,
    col: number,
    deviceTemplate: DeviceTemplate,
    foregroundContentIndex: number
  ): Promise<void>;
  deleteAllDeviceForegroundSlots(
    gondolaTemplateId: string,
    row: number,
    col: number,
    deviceTemplate: DeviceTemplate
  ): Promise<void>;
  updateDeviceContentSelection(
    data: DeviceContentSelection & {
      selectedSchedule?: string;
    }
  ): void;
  updateDeviceForeground(
    gondolaTemplateId: string,
    row: number,
    col: number,
    deviceTemplate: DeviceTemplate,
    newForegroundContent: Array<ForegroundContent>
  ): Promise<void>;
  updateDeviceForegroundSlotPosition(
    gondolaTemplateId: string,
    row: number,
    col: number,
    deviceTemplate: DeviceTemplate,
    foregroundContentIndex: number,
    offsetX: number,
    offsetY: number
  ): Promise<void>;
  updateDeviceForegroundSlot(
    gondolaTemplateId: string,
    row: number,
    col: number,
    deviceTemplate: DeviceTemplate,
    newForegroundContentItem: ForegroundContent,
    foregroundContentIndex: number
  ): Promise<void>;
  addDeviceForegroundSlot(
    gondolaTemplateId: string,
    row: number,
    col: number,
    deviceTemplate: DeviceTemplate,
    newForegroundContentItem: ForegroundContent
  ): Promise<void>;
  deleteDevice(gondolaTemplateId: string, row: number, col: number): Promise<void>;
  delete(gondolaTemplateId: string): Promise<void>;
  search(): Promise<void>;
  clearSearchResults(): void;
  setSearchFilters(
    sortOrder?: string,
    orderBy?: string,
    searchTerm?: string,
    startDate?: string,
    endDate?: string
  ): void;
  clearSearchFilters(): void;
  clear(): void;
  update(data: GondolaTemplateWithIdJSON): void;
  updateGondolaZoomLevelById(gondolaTemplateId: string, zoomLevel: number): void;
  loadGondolaZoomFromLocalStorage(): void;
  setIsCanvasLoading(status: boolean): void;
  setIsUpdating(status: boolean): void;
}

export interface GondolaTemplatesState {
  gondolaTemplates: Array<GondolaTemplate>;
  /*
  Fetching status used to prevent concurrent calls
  If this is set to true and a fetch is called, it will be ignored
   */
  isFetching: boolean;
  fetched: boolean;
  deviceContentSelection: DeviceContentSelection;
  gondolaTemplatesZoom: GondolaTemplateZoomCache;
  searchResults: {
    results: Array<GondolaTemplate>;
    totalCount: number;
  };
  isSearching: boolean;
  currentPage: number;
  searchFilters: {
    sortOrder?: string;
    orderBy?: string;
    searchTerm?: string;
    startDate?: string;
    endDate?: string;
  };
  loadingIndicator: {
    update: boolean;
    isCanvasLoading: boolean;
  };
}

export type GondolaTemplatesStoreDefinition = StoreDefinition<
  'gondolaTemplates',
  GondolaTemplatesState,
  GondolaTemplatesGetters,
  GondolaTemplatesActions
>;

export type GondolaTemplatesStore = Store<
  'gondolaTemplates',
  GondolaTemplatesState,
  GondolaTemplatesGetters,
  GondolaTemplatesActions
>;

export const useGondolaTemplatesStore: GondolaTemplatesStoreDefinition = defineStore('gondolaTemplates', {
  state: (): GondolaTemplatesState => ({
    gondolaTemplates: new Array<GondolaTemplate>(),
    isFetching: false,
    fetched: false,
    deviceContentSelection: {
      isForeground: false,
      index: -1,
      offsetX: 0,
      offsetY: 0,
    },
    gondolaTemplatesZoom: {},
    searchResults: {
      results: new Array<GondolaTemplate>(),
      totalCount: 0,
    },
    isSearching: false,
    currentPage: 0,
    searchFilters: {},
    loadingIndicator: {
      isCanvasLoading: false,
      update: false,
    },
  }),
  getters: {
    getById: (state: GondolaTemplatesState) => (id: string) => {
      return state.gondolaTemplates.find((gondolaTemplate: GondolaTemplateModel) => gondolaTemplate._id == id);
    },
    findLikeName: (state: GondolaTemplatesState) => (nameQuery: string) => {
      return state.gondolaTemplates.filter(
        (gondolaTemplate: GondolaTemplateModel) => gondolaTemplate.name && gondolaTemplate.name.includes(nameQuery)
      );
    },
    getZoomLevelById: (state: GondolaTemplatesState) => (id: string) => {
      return state.gondolaTemplatesZoom[id] || 100;
    },
  },
  actions: {
    async fetch(): Promise<void> {
      if (!this.isFetching) {
        this.isFetching = true;
        const response: AxiosResponse<Array<GondolaTemplateWithIdJSON>> = await axios.get(
          `${Config.getApiUrl()}/gondola-templates`
        );
        if (response.status === 200) {
          const gondolaTemplates: Array<GondolaTemplateModel> = response.data.map(
            (gondolaTemplateObject: GondolaTemplateWithIdJSON) => GondolaTemplateModel.fromJSON(gondolaTemplateObject)
          );
          this.updateAll(gondolaTemplates);
          this.isFetching = false;
          this.fetched = true;
        } else {
          console.error(response);
        }
      }
    },
    updateAll(gondolaTemplates: Array<GondolaTemplateModel>) {
      Vue.set(this, 'gondolaTemplates', gondolaTemplates);
      // When updating all the gondola templates, filter the obsolete gondola templates zoom from the store and local storage
      const newGondolaTemplatesZoom: GondolaTemplateZoomCache = {};
      for (const gondolaTemplatesKey of gondolaTemplates) {
        if (gondolaTemplatesKey._id && this.gondolaTemplatesZoom[gondolaTemplatesKey._id]) {
          newGondolaTemplatesZoom[gondolaTemplatesKey._id] = this.gondolaTemplatesZoom[gondolaTemplatesKey._id];
        }
      }
      Vue.set(this, 'gondolaTemplatesZoom', newGondolaTemplatesZoom);
      localStorage.setItem('gondolaTemplatesZoom', JSON.stringify(newGondolaTemplatesZoom));
    },
    async create(gondolaTemplate: NewGondolaTemplateJSON) {
      this.loadingIndicator.update = true;
      const response: AxiosResponse<GondolaTemplateWithIdJSON> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates`,
        gondolaTemplate
      );
      if (response.status === 200) {
        const createdGondolaTemplate: GondolaTemplateModel = GondolaTemplateModel.fromJSON(response.data);
        this.gondolaTemplates.push(createdGondolaTemplate);
        this.loadingIndicator.update = false;
        return createdGondolaTemplate._id;
      } else {
        console.error(response);
        this.loadingIndicator.update = false;
      }
    },
    async clone(gondolaTemplateId: string) {
      const response: AxiosResponse<GondolaTemplateWithIdJSON> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/clone/${gondolaTemplateId}`,
        {
          cloneNamePrefix: i18n.t(TranslationKeys.copyOf.$path),
        }
      );
      if (response.status === 200) {
        const createdGondolaTemplate: GondolaTemplateModel = GondolaTemplateModel.fromJSON(response.data);
        this.gondolaTemplates.push(createdGondolaTemplate);
        return createdGondolaTemplate._id;
      } else {
        console.error(response);
      }
    },
    async updateName(gondolaTemplateId: string, name: string) {
      const gondolaTemplate: Optional<GondolaTemplateModel> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const response: AxiosResponse<GondolaTemplateWithIdJSON> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}`,
        { ...gondolaTemplate, name: name },
        {
          params: {
            hash: this.getById(gondolaTemplateId)?.hash,
          },
        }
      );
      if (response.status === 200) {
        const gondolaTemplateToUpdate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
        if (gondolaTemplateToUpdate) {
          gondolaTemplateToUpdate.name = name;
          gondolaTemplateToUpdate.hash = response.data.hash;
        }
      } else {
        console.error(response);
      }
    },
    async addDeviceRow(deviceTemplate: DeviceTemplate, gondolaTemplateId: string) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const response: AxiosResponse<{ hash: string }> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}/device/${gondolaTemplate.railGrid.length}/0`,
        deviceTemplate.toJSON(),
        {
          params: {
            hash: gondolaTemplate.hash,
          },
        }
      );
      if (response.status === 200) {
        gondolaTemplate.railGrid.push(new Array<DeviceTemplate>(deviceTemplate));
        gondolaTemplate.hash = response.data.hash;
      } else {
        console.error(response);
      }
    },
    async addDeviceCol(deviceTemplate: DeviceTemplate, gondolaTemplateId: string, row: number) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const response: AxiosResponse<GondolaTemplateWithIdJSON> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}/device/${row}/${
          gondolaTemplate.railGrid[row].length
        }`,
        deviceTemplate.toJSON(),
        {
          params: {
            hash: gondolaTemplate.hash,
          },
        }
      );
      if (response.status === 200) {
        gondolaTemplate.railGrid[row].push(deviceTemplate);
        gondolaTemplate.hash = response.data.hash;
      } else {
        console.error(response);
      }
    },
    async updateHardwareModel(
      deviceTemplate: DeviceTemplate,
      hardwareModel: HardwareModel,
      gondolaTemplateId: string,
      row: number,
      col: number
    ) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const request: HardwareModelJSON = {
        identifier: hardwareModel.identifier,
      };
      const response: AxiosResponse<{ hash: string }> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}/device/${row}/${col}/hardwaremodel`,
        request,
        {
          params: {
            hash: gondolaTemplate.hash,
          },
        }
      );
      if (response.status === 200) {
        gondolaTemplate.hash = response.data.hash;
        deviceTemplate.hardwareModel = hardwareModel;
      } else {
        console.error(response);
      }
    },
    async updateDeviceGroup(
      deviceTemplate: DeviceTemplate,
      deviceGroup: DeviceGroup,
      gondolaTemplateId: string,
      row: number,
      col: number
    ) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const response: AxiosResponse<{ hash: string }> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}/device/${row}/${col}/railgroup`,
        deviceGroup.toJSON(),
        {
          params: {
            hash: gondolaTemplate.hash,
          },
        }
      );
      if (response.status === 200) {
        gondolaTemplate.hash = response.data.hash;
        deviceTemplate.railGroup = deviceGroup;
      } else {
        console.error(response);
      }
    },
    async updateDeviceBackground(
      deviceTemplate: DeviceTemplate,
      backgroundContent: Array<BackgroundContent>,
      gondolaTemplateId: string,
      row: number,
      col: number
    ) {
      this.setIsUpdating(true);
      this.setIsCanvasLoading(true);
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const requestContent: Array<BackgroundContentModelJSON> = backgroundContent.map(
        (backgroundContentItem: BackgroundContent) => backgroundContentItem.toJSON()
      );
      const response: AxiosResponse<{ hash: string }> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}/device/${row}/${col}/background`,
        requestContent,
        {
          params: {
            hash: gondolaTemplate.hash,
          },
        }
      );
      if (response.status === 200) {
        gondolaTemplate.hash = response.data.hash;
        deviceTemplate.backgroundContent = backgroundContent;
      } else {
        console.error(response);
      }
      this.setIsUpdating(false);
      this.setIsCanvasLoading(false);
    },
    /**
     * Delete the foreground content at the given index. in case the index is not provided, ALL foreground items
     * will be removed
     */
    async deleteDeviceForegroundSlot(
      gondolaTemplateId: string,
      row: number,
      col: number,
      deviceTemplate: DeviceTemplate,
      foregroundContentIndex: number
    ) {
      this.setIsUpdating(true);
      this.setIsCanvasLoading(true);
      let updatedDeviceForeground: Array<ForegroundContent> = [];

      if (foregroundContentIndex >= 0) {
        updatedDeviceForeground = deviceTemplate.foregroundContent.map((foregroundContentItem: ForegroundContent) =>
          foregroundContentItem.clone()
        );
        updatedDeviceForeground.splice(foregroundContentIndex, 1);
      }

      await this.updateDeviceForeground(gondolaTemplateId, row, col, deviceTemplate, updatedDeviceForeground);
      this.updateDeviceContentSelection({ isForeground: false, index: -1 });
      this.setIsUpdating(false);
      this.setIsCanvasLoading(false);
    },
    async deleteAllDeviceForegroundSlots(
      gondolaTemplateId: string,
      row: number,
      col: number,
      deviceTemplate: DeviceTemplate
    ) {
      this.setIsUpdating(true);
      this.setIsCanvasLoading(true);
      await this.updateDeviceForeground(gondolaTemplateId, row, col, deviceTemplate, []);
      this.updateDeviceContentSelection({ isForeground: false, index: -1 });
      this.setIsUpdating(false);
      this.setIsCanvasLoading(false);
    },
    updateDeviceContentSelection(
      data: DeviceContentSelection & {
        selectedSchedule?: string;
      }
    ) {
      this.deviceContentSelection = data;
    },
    async updateDeviceForeground(
      gondolaTemplateId: string,
      row: number,
      col: number,
      deviceTemplate: DeviceTemplate,
      newForegroundContent: Array<ForegroundContent>
    ) {
      this.setIsUpdating(true);
      this.setIsCanvasLoading(true);
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const requestContent: Array<ForegroundContentModelJSON> = newForegroundContent.map(
        (foregroundContentItem: ForegroundContent) => foregroundContentItem.toJSON()
      );
      const response: AxiosResponse<{ hash: string }> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}/device/${row}/${col}/foreground`,
        requestContent,
        {
          params: {
            hash: gondolaTemplate.hash,
          },
        }
      );
      if (response.status === 200) {
        gondolaTemplate.hash = response.data.hash;
        Vue.set(deviceTemplate, 'foregroundContent', newForegroundContent);
      } else {
        console.error(response);
      }
      this.setIsUpdating(false);
      this.setIsCanvasLoading(false);
    },
    async updateDeviceForegroundSlotPosition(
      gondolaTemplateId: string,
      row: number,
      col: number,
      deviceTemplate: DeviceTemplate,
      foregroundContentIndex: number,
      offsetX: number,
      offsetY: number
    ) {
      this.setIsUpdating(true);
      this.setIsCanvasLoading(true);
      const schedulesStore: SchedulesStore = useSchedulesStore();
      const updatedForeground: ForegroundContent = deviceTemplate.foregroundContent[foregroundContentIndex].clone();
      if (schedulesStore.selectedSchedule) {
        const indexOfScheduleContent: number | undefined = updatedForeground.scheduledContent?.findIndex(
          (schedule: ScheduledContent) => schedule.scheduleId === schedulesStore.selectedSchedule
        );
        const scheduleLayerToAdd: ScheduleLayer = new ScheduleLayer(
          updatedForeground.baseLayer.name,
          updatedForeground.baseLayer.url,
          updatedForeground.baseLayer.previewUrl,
          updatedForeground.baseLayer.type,
          updatedForeground.baseLayer.height,
          updatedForeground.baseLayer.width,
          updatedForeground.baseLayer.duration,
          updatedForeground.baseLayer.fps,
          updatedForeground.baseLayer.offsetX,
          updatedForeground.baseLayer.offsetY,
          updatedForeground.baseLayer.zIndex,
          updatedForeground.baseLayer.checksum,
          updatedForeground.baseLayer.layerId,
          LayerVisibility.VISIBLE,
          updatedForeground.baseLayer.playlist
        );
        if (indexOfScheduleContent !== undefined && indexOfScheduleContent > 0) {
          updatedForeground.scheduledContent
            ?.filter(
              (item: ScheduledContent) =>
                item.layer.name === EMPTY_LABEL_PLACEHOLDER && item.scheduleId === schedulesStore.selectedSchedule
            )
            .forEach((scheduleContent: ScheduledContent) => (scheduleContent.layer = scheduleLayerToAdd));
        }
      } else {
        // Must be a base layer and not a label
        updatedForeground.baseLayer.offsetY = offsetY;
        updatedForeground.baseLayer.offsetX = offsetX;
        // update all the schedule matching label to match the base layer positions
        updatedForeground.scheduledContent?.forEach((content: ScheduledContent) => {
          content.layer.offsetY = offsetY;
          content.layer.offsetX = offsetX;
        });
      }
      await this.updateDeviceForegroundSlot(
        gondolaTemplateId,
        row,
        col,
        deviceTemplate,
        updatedForeground,
        foregroundContentIndex
      );
    },
    async updateDeviceForegroundSlot(
      gondolaTemplateId: string,
      row: number,
      col: number,
      deviceTemplate: DeviceTemplate,
      newForegroundContentItem: ForegroundContent,
      foregroundContentIndex: number
    ) {
      this.setIsUpdating(true);
      this.setIsCanvasLoading(true);
      const updatedDeviceForeground: Array<ForegroundContent> = deviceTemplate.foregroundContent.map(
        (foregroundContent: ForegroundContent) => foregroundContent.clone()
      );
      // retain position if not set in new item
      const newBaseLayer: BaseLayer = newForegroundContentItem.baseLayer;
      if (!newBaseLayer.offsetY && !newBaseLayer.offsetX) {
        const existingBaseLayer: BaseLayer = updatedDeviceForeground[foregroundContentIndex].baseLayer;
        newForegroundContentItem.baseLayer.offsetX = existingBaseLayer.offsetX;
        newForegroundContentItem.baseLayer.offsetY = existingBaseLayer.offsetY;
      }
      updatedDeviceForeground[foregroundContentIndex] = newForegroundContentItem;
      await this.updateDeviceForeground(gondolaTemplateId, row, col, deviceTemplate, updatedDeviceForeground);
    },
    async addDeviceForegroundSlot(
      gondolaTemplateId: string,
      row: number,
      col: number,
      deviceTemplate: DeviceTemplate,
      newForegroundContentItem: ForegroundContent
    ) {
      this.setIsUpdating(true);
      this.setIsCanvasLoading(true);
      const updatedDeviceForeground: Array<ForegroundContent> = deviceTemplate.foregroundContent.map(
        (foregroundItem: ForegroundContent) => foregroundItem.clone()
      );
      updatedDeviceForeground.push(newForegroundContentItem);
      await this.updateDeviceForeground(gondolaTemplateId, row, col, deviceTemplate, updatedDeviceForeground);
      this.updateDeviceContentSelection({
        isForeground: true,
        index: updatedDeviceForeground.length - 1,
      });
      this.setIsUpdating(false);
      this.setIsCanvasLoading(false);
    },
    async deleteDevice(gondolaTemplateId: string, row: number, col: number) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const response: AxiosResponse<{ hash: string }> = await axios.delete(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}/device/${row}/${col}`,
        {
          params: {
            hash: gondolaTemplate.hash,
          },
        }
      );
      if (response.status === 200) {
        gondolaTemplate.railGrid[row].splice(col, 1);
        gondolaTemplate.hash = response.data.hash;

        if (gondolaTemplate.railGrid[row].length === 0) {
          gondolaTemplate.railGrid.splice(row, 1);
        }
      } else {
        console.error(response);
      }
    },
    async delete(gondolaTemplateId: string) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const schedulesStore: SchedulesStore = useSchedulesStore();
      const response: AxiosResponse<{
        gondolaTemplate: GondolaTemplateWithIdJSON;
        deletedSchedules: Array<ScheduleModelJSON>;
      }> = await axios.delete(`${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}`, {
        params: {
          hash: gondolaTemplate.hash,
        },
      });
      if (response.status === 200) {
        schedulesStore.deleteAll(
          response.data.deletedSchedules.map((schedule: ScheduleModelJSON) => ScheduleModel.fromJSON(schedule))
        );
        const gondolaTemplateIndex: number = this.gondolaTemplates.findIndex(
          (gondolaTemplate: GondolaTemplateModel) => gondolaTemplate._id === gondolaTemplateId
        );
        if (gondolaTemplateIndex >= 0) {
          this.gondolaTemplates.splice(gondolaTemplateIndex, 1);
          this.searchResults.totalCount -= 1;
        }
        const searchItemIndex: number = this.searchResults.results.findIndex(
          (gondolaTemplate: GondolaTemplateModel) => gondolaTemplate._id === gondolaTemplateId
        );
        if (searchItemIndex >= 0) {
          this.searchResults.results.splice(searchItemIndex, 1);
        }
      } else {
        console.error(response);
      }
    },
    async search() {
      if (this.isSearching) {
        return;
      }
      this.isSearching = true;
      const response: AxiosResponse<{
        gondolaTemplates: Array<GondolaTemplateWithIdJSON>;
        count: number;
        page: number;
        pageSize: number;
      }> = await axios.get(`${Config.getApiUrl()}/gondola-templates/search`, {
        params: {
          startDate: this.searchFilters.startDate,
          endDate: this.searchFilters.endDate,
          searchTerm: this.searchFilters.searchTerm,
          sortOrder: this.searchFilters.sortOrder,
          orderBy: this.searchFilters.orderBy,
          page: this.currentPage,
          pageSize: 10,
        },
      });
      if (response.status === 200) {
        const gondolaTemplates: Array<GondolaTemplateModel> = response.data.gondolaTemplates.map(
          (gondolaTemplateObject: GondolaTemplateWithIdJSON) => GondolaTemplateModel.fromJSON(gondolaTemplateObject)
        );
        this.searchResults.results = this.searchResults.results.concat(gondolaTemplates);
        this.searchResults.totalCount = response.data.count;
        this.currentPage += 1;
      } else {
        console.error(response);
      }
      this.isSearching = false;
    },
    clearSearchResults() {
      this.currentPage = 0;
      this.searchResults = {
        results: new Array<GondolaTemplate>(),
        totalCount: 0,
      };
    },
    setSearchFilters(sortOrder?: string, orderBy?: string, searchTerm?: string, startDate?: string, endDate?: string) {
      this.searchFilters = {
        startDate,
        endDate,
        sortOrder,
        orderBy,
        searchTerm,
      };
    },
    clearSearchFilters() {
      this.searchFilters = {};
    },
    clear() {
      Vue.set(this, 'gondolaTemplates', new Array<GondolaTemplateModel>());
    },
    update(gondolaTemplateJSON: GondolaTemplateWithIdJSON) {
      const index: number = this.gondolaTemplates.findIndex(
        (gondola: GondolaTemplateModel) => gondola._id === gondolaTemplateJSON._id
      );
      if (index >= 0) {
        const updatedTemplate: GondolaTemplateModel = GondolaTemplateModel.fromJSON(gondolaTemplateJSON);
        // For some reason, assigning updated template directly to the template in the array, generates a bug where we can't update the content anymore
        // The vue watcher won't detect any changes in the object after that!!
        this.gondolaTemplates[index].railGrid = updatedTemplate.railGrid;
        this.gondolaTemplates[index].layoutHash = updatedTemplate.layoutHash;
        this.gondolaTemplates[index].hash = updatedTemplate.hash;
        this.gondolaTemplates[index].name = updatedTemplate.name;
      }
    },
    updateGondolaZoomLevelById(gondolaTemplateId: string, zoomLevel: number) {
      Vue.set(this.gondolaTemplatesZoom, gondolaTemplateId, zoomLevel);
      localStorage.setItem('gondolaTemplatesZoom', JSON.stringify(this.gondolaTemplatesZoom));
    },
    loadGondolaZoomFromLocalStorage() {
      const localStorageZoomData: string | null = localStorage.getItem('gondolaTemplatesZoom');
      const cachedGondolaZoom: GondolaTemplateZoomCache = localStorageZoomData ? JSON.parse(localStorageZoomData) : {};
      Vue.set(this, 'gondolaTemplatesZoom', cachedGondolaZoom);
    },
    setIsCanvasLoading(status: boolean) {
      this.loadingIndicator.isCanvasLoading = status;
    },
    setIsUpdating(status: boolean) {
      this.loadingIndicator.update = status;
    },
  },
});
