
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItemSelectionTarget } from '@client/enums';
import PlaylistDialog from '@client/components/PlaylistDialog/PlaylistDialog.vue';
import AddFolderDialog from '@client/components/ContentItems/AddFolderDialog.vue';

/**
 * Actions toolbar for the content item management.
 * Will render an "open in new tab" (opens the content management page) action when it's embedded in a model
 */
@Component({
  components: {
    AddFolderDialog,
    PlaylistDialog,
  },
})
export default class ContentItemsActionButtons extends Vue {
  /* DECLARATIONS */
  @Prop()
  setSelectedFolder!: (folderId: string) => void;
  @Prop()
  disableActions!: boolean;
  @Prop()
  selectedFolder!: string;
  @Prop({ default: ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND })
  private contentItemSelectionTarget?: ContentItemSelectionTarget;
  @Prop()
  private currentlyActiveContentItemName?: string;
  private showPlaylistDialog: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  private openFileUpload() {
    this.$root.$emit('openFileUploadPopup');
  }

  private openFolderUpload() {
    this.$root.$emit('openFolderUploadPopup');
  }

  private openPlaylistDialog() {
    this.showPlaylistDialog = true;
  }

  private closePlaylistDialog() {
    this.showPlaylistDialog = false;
  }
  /* GETTERS */
}
