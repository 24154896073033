
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTextFilter from '@client/components/Filters/HeaderFilters/HeaderTextFilter.vue';
import HeaderPublishStatusFilter from '@client/components/Filters/HeaderFilters/HeaderPublishStatusFilter.vue';
import HeaderHardwareModelFilter from '@client/components/Filters/HeaderFilters/HeaderHardwareModelFilter.vue';
import HeaderOnlineStatusFilter from '@client/components/Filters/HeaderFilters/HeaderOnlineStatusFilter.vue';
import { DeviceTableItem } from '@client/models';
import { DeviceTableFilter } from '@client/models/DeviceOverviewModels/DeviceTableItem.model';
import { PublishedStateFilter } from '@client/models/GondolaModels/SectionsForPublishing.model';
import { ClientPublishState } from '@client/enums';
import { OnlineStatus } from '@common/enums';
import { HardwareModelIdentifier } from '@common/device/types';
import { TranslateResult } from 'vue-i18n';
import Moment from 'moment';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import { getDeviceDetailPath, getStoreDetailPath } from '@client/router/utils';

@Component({
  computed: {
    Moment() {
      return Moment;
    },
  },
  components: { HeaderOnlineStatusFilter, HeaderHardwareModelFilter, HeaderPublishStatusFilter, HeaderTextFilter },
  methods: {
    getDeviceDetailPath,
    getStoreDetailPath,
  },
})
export default class DeviceDataTable extends Vue {
  @Prop()
  private items!: DeviceTableItem[];
  @Prop()
  private isSearchingProp!: boolean;
  private selectedItems: DeviceTableItem[] = [];

  private deviceTableFilter: DeviceTableFilter = this.initFilters();

  initFilters(): DeviceTableFilter {
    this.deviceTableFilter = {
      shortId: '',
      longId: '',
      hardwareModels: [],
      store: '',
      aisleAndSection: '',
      onlineState: null,
      publishStatus: null,
      lastPublishedTemplate: '',
    };
    return this.deviceTableFilter;
  }

  get headers(): CustomDataTableHeader[] {
    return [
      {
        text: this.$t(this.$i18nTranslationKeys.devices.shortId.$path),
        value: 'shortId',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.longId.$path),
        value: 'longId',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.hardwareModel.$path),
        value: 'hardwareModel',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.store.$path),
        value: 'storeName',
        filterable: true,
      },
      {
        text: `${this.$t(this.$i18nTranslationKeys.devices.aisle.$path)} - ${this.$t(
          this.$i18nTranslationKeys.devices.section.$path
        )}`,
        value: 'aisle',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.onlineStatus.$path),
        value: 'onlineStatus',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.publishState.$path),
        value: 'publishState',
        filterable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.devices.publishTemplateName.$path),
        value: 'lastPublishedTemplate',
        filterable: true,
      },
    ];
  }

  updateSelectedItems(selected: DeviceTableItem[]) {
    this.$emit('selected-items', selected);
  }

  private deselectAll(): void {
    this.selectedItems = [];
  }

  private changeLongIdFilter(longId: string): void {
    this.deviceTableFilter.longId = longId;
    this.deselectAll();
  }

  private changeShortIdFilter(shortId: string): void {
    this.deviceTableFilter.shortId = shortId;
    this.deselectAll();
  }

  private changeStoreFilter(store: string): void {
    this.deviceTableFilter.store = store;
    this.deselectAll();
  }

  private changeAisleFilter(searchTerm: string): void {
    this.deviceTableFilter.aisleAndSection = searchTerm;
    this.deselectAll();
  }

  private changePublishedTemplateFilter(templateName: string): void {
    this.deviceTableFilter.lastPublishedTemplate = templateName;
    this.deselectAll();
  }

  private changeLastPublishedStateFilter(publishStatus: PublishedStateFilter | null) {
    this.deviceTableFilter.publishStatus = publishStatus;
    this.deselectAll();
  }

  private changeOnlineStatusFilter(onlineStatus: OnlineStatus | null) {
    this.deviceTableFilter.onlineState = onlineStatus;
    this.deselectAll();
  }

  private changeHardwareModelFilter(hardwareModel: HardwareModelIdentifier[]) {
    this.deviceTableFilter.hardwareModels = hardwareModel;
    this.deselectAll();
  }

  private isPublishingPending(item: DeviceTableItem) {
    return (
      item.publishInformation?.state === ClientPublishState.PUBLISHING ||
      item.publishInformation?.state === ClientPublishState.INITIALIZING
    );
  }

  private isAccepted(item: DeviceTableItem) {
    return item.publishInformation?.state === ClientPublishState.ACCEPTED;
  }

  private isPublished(item: DeviceTableItem) {
    return item.publishInformation?.state === ClientPublishState.PUBLISHED;
  }

  private getItemOnlineStatusColor(item: DeviceTableItem): string {
    return item.onlineStatus == OnlineStatus.ONLINE ? 'success' : 'error';
  }
  private getItemOnlineStatusTooltip(item: DeviceTableItem): TranslateResult {
    return item.onlineStatus == OnlineStatus.ONLINE
      ? this.$t(this.$i18nTranslationKeys.devices.onlineStatusChanged.$path, {
          date: Moment(item.onlineStatusChanged).format('llll'),
        })
      : this.$t(this.$i18nTranslationKeys.devices.offlineStatusChanged.$path, {
          date: Moment(item.onlineStatusChanged).format('llll'),
        });
  }

  private getPublishStatusTooltip(item: DeviceTableItem): TranslateResult {
    if (!item.publishInformation) {
      return this.$t(this.$i18nTranslationKeys.deviceState.noContent.$path);
    }
    switch (item.publishInformation.state) {
      case ClientPublishState.PUBLISHING:
        return this.$t(this.$i18nTranslationKeys.deviceState.pending.$path);
      case ClientPublishState.PUBLISHED:
        return this.$t(this.$i18nTranslationKeys.publishState.published.$path);
      case ClientPublishState.ACCEPTED:
        return this.$t(this.$i18nTranslationKeys.publishState.accepted.$path);
      default:
        return this.$t(this.$i18nTranslationKeys.deviceState.failed.$path);
    }
  }

  get filteredItems(): Array<DeviceTableItem> {
    let filteredItems: DeviceTableItem[] = this.items;
    if (this.deviceTableFilter.longId) {
      filteredItems = filteredItems.filter((item: DeviceTableItem) =>
        item.longId.toLowerCase().includes(this.deviceTableFilter.longId.toLowerCase())
      );
    }
    if (this.deviceTableFilter.shortId) {
      filteredItems = filteredItems.filter((item: DeviceTableItem) =>
        item.shortId.toLowerCase().includes(this.deviceTableFilter.shortId.toLowerCase())
      );
    }
    if (this.deviceTableFilter.store) {
      filteredItems = filteredItems.filter((item: DeviceTableItem) =>
        item.storeName?.toLowerCase().includes(this.deviceTableFilter.store.toLowerCase())
      );
    }
    if (this.deviceTableFilter.aisleAndSection) {
      filteredItems = filteredItems.filter(
        (item: DeviceTableItem) =>
          item.aisle?.toLowerCase().includes(this.deviceTableFilter.aisleAndSection.toLowerCase()) ||
          item.section?.toLowerCase().includes(this.deviceTableFilter.aisleAndSection.toLowerCase())
      );
    }
    if (this.deviceTableFilter.lastPublishedTemplate) {
      filteredItems = filteredItems.filter((item: DeviceTableItem) =>
        item.publishTemplateName?.toLowerCase().includes(this.deviceTableFilter.lastPublishedTemplate.toLowerCase())
      );
    }
    if (this.deviceTableFilter.publishStatus) {
      filteredItems = filteredItems.filter((item: DeviceTableItem) =>
        this.filterItemState(item, this.deviceTableFilter.publishStatus)
      );
    }
    if (this.deviceTableFilter.onlineState) {
      filteredItems = filteredItems.filter(
        (item: DeviceTableItem) => item.onlineStatus == this.deviceTableFilter.onlineState
      );
    }
    if (this.deviceTableFilter.hardwareModels.length) {
      filteredItems = filteredItems.filter((item: DeviceTableItem) =>
        this.deviceTableFilter.hardwareModels?.includes(item.hardwareModel.identifier)
      );
    }
    return filteredItems;
  }

  private filterItemState(item: DeviceTableItem, filterState: PublishedStateFilter | null): boolean {
    if (!filterState) {
      return true;
    }
    if (
      filterState === PublishedStateFilter.PUBLISHED &&
      item.publishInformation?.state !== ClientPublishState.PUBLISHED
    ) {
      return false;
    }
    if (
      filterState === PublishedStateFilter.PENDING &&
      item.publishInformation?.state !== ClientPublishState.PUBLISHING &&
      item.publishInformation?.state !== ClientPublishState.INITIALIZING
    ) {
      return false;
    }
    if (
      filterState === PublishedStateFilter.ACCEPTED &&
      item.publishInformation?.state !== ClientPublishState.ACCEPTED
    ) {
      return false;
    }
    if (
      filterState === PublishedStateFilter.FAILED &&
      (!item.publishInformation?.state ||
        item.publishInformation?.state === ClientPublishState.PUBLISHED ||
        item.publishInformation?.state === ClientPublishState.PUBLISHING ||
        item.publishInformation?.state === ClientPublishState.INITIALIZING)
    ) {
      return false;
    }
    if (filterState === PublishedStateFilter.NOT_SET && item.publishInformation?.state) {
      return false;
    }
    return true;
  }

  get isSearching(): boolean {
    return this.isSearchingProp;
  }
}
