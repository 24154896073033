<template>
  <v-container>
    <v-skeleton-loader type="chip" class="mb-2" />
    <v-row>
      <v-col>
        <v-skeleton-loader type="text" height="60px" />
        <v-skeleton-loader type="text" height="60px" />
        <v-skeleton-loader type="text" height="60px" />
      </v-col>
      <v-col cols="1" class="mx-4">
        <v-skeleton-loader type="image" max-height="112" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class StoreDetailHeaderLoader extends Vue {}
</script>
