import { Ancestors, BaseContentItemJSON, BaseContentItemWithIdJSON } from '@common/content-item/types';
import { ContentType, LayerVisibility } from '@common/enums';
import ImageModel from '@client/models/ContentModels/Image.model';
import Moment from 'moment';
import VideoModel from '@client/models/ContentModels/Video.model';
import FolderModel from '@client/models/ContentModels/Folder.model';
import PlaylistModel from '@client/models/ContentModels/Playlist.model';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';

export default class BaseContentItemModel {
  _id: string;
  name: string;
  customerId: string;
  hash?: string;
  parentFolder?: string;
  type: ContentType;
  createdAt: Date;
  ancestors?: Ancestors;
  /**
   * Only used in the content item list data table
   */
  createdAtAsTime?: number;
  constructor(
    id: string,
    customerId: string,
    name: string,
    hash: string,
    type: ContentType,
    parentFolder?: string,
    ancestors?: Ancestors
  ) {
    this._id = id;
    this.customerId = customerId;
    this.name = name;
    this.hash = hash;
    this.type = type;
    this.parentFolder = parentFolder;
    const createdAtDate: Date = new Date(parseInt(id.substring(0, 8), 16) * 1000);
    this.createdAt = createdAtDate;
    this.createdAtAsTime = createdAtDate.getTime();
    this.ancestors = ancestors;
  }

  isImage(): this is ImageModel {
    return this.type === ContentType.Image;
  }

  isVideo(): this is VideoModel {
    return this.type === ContentType.Video;
  }

  isMediaFile(): this is VideoModel | ImageModel {
    return this.isVideo() || this.isImage();
  }

  isFolder(): this is FolderModel {
    return this.type === ContentType.Folder;
  }

  isPlaylist(): this is PlaylistModel {
    return this.type === ContentType.Playlist;
  }

  public setId(id: string): void {
    this._id = id;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public setCustomerId(customerId: string): void {
    this.customerId = customerId;
  }

  public setFileType(contentType: ContentType): void {
    this.type = contentType;
  }

  public setHash(hash?: string): void {
    this.hash = hash;
  }

  public setParentFolder(parentFolder?: string): void {
    if (parentFolder) {
      this.parentFolder = parentFolder;
    }
  }

  public setAncestors(ancestors?: Ancestors): void {
    this.ancestors = ancestors;
  }

  getCreatedAtFormatted(locale?: string): string {
    const moment: typeof Moment = Moment;
    if (locale) {
      moment.locale(locale);
    }
    return `${moment(this.createdAt).format('l')} ${moment(this.createdAt).format('LT')}`;
  }

  getCreatedAtFormattedRelative(locale?: string): string {
    const moment: typeof Moment = Moment;
    if (locale) {
      moment.locale(locale);
    }
    return moment(this.createdAt).fromNow();
  }

  setCreatedAt(): void {
    if (!this._id) {
      return;
    }
    const createdAtDate: Date = new Date(parseInt(this._id.substring(0, 8), 16) * 1000);
    this.createdAt = createdAtDate;
    this.createdAtAsTime = createdAtDate.getTime();
  }

  public static getDefaultEmptyContentItem(): BaseContentItemModel {
    return new BaseContentItemModel('', '', '', '', ContentType.Image, '');
  }

  public static fromJSON(contentItemJSON: BaseContentItemWithIdJSON): BaseContentItemModel {
    const contentItem: BaseContentItemModel = BaseContentItemModel.getDefaultEmptyContentItem();
    contentItem.setId(contentItemJSON._id);
    contentItem.setCustomerId(contentItemJSON.customerId);
    contentItem.setName(contentItemJSON.name);
    contentItem.setFileType(contentItemJSON.type);
    contentItem.setHash(contentItemJSON.hash);
    contentItem.setParentFolder(contentItemJSON.parentFolder);
    contentItem.setAncestors(contentItemJSON.ancestors);
    return contentItem;
  }

  public toNewJSON(): BaseContentItemJSON {
    const { _id, parentFolder, ...newContentItemJSON }: BaseContentItemWithIdJSON = this.toJSON();
    return parentFolder ? { parentFolder, ...newContentItemJSON } : newContentItemJSON;
  }

  public toJSON(): BaseContentItemWithIdJSON {
    return {
      _id: this._id,
      hash: this.hash,
      type: this.type,
      name: this.name,
      customerId: this.customerId,
      parentFolder: this.parentFolder,
    };
  }

  public toBaseLayer(): BaseLayerModel {
    return new BaseLayerModel(
      this._id ? this._id : undefined,
      this.name,
      '',
      '',
      this.type,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      '',
      undefined,
      []
    );
  }

  public toScheduledLayer(): ScheduleLayerModel {
    return new ScheduleLayerModel(
      this.name,
      '',
      '',
      this.type,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      '',
      undefined,
      LayerVisibility.VISIBLE,
      []
    );
  }

  public clone(): BaseContentItemModel {
    return new BaseContentItemModel(
      this._id,
      this.customerId,
      this.name,
      this.hash || '',
      this.type,
      this.parentFolder,
      this.ancestors
    );
  }
}
