
import { Component, Vue } from 'vue-property-decorator';
import { LocaleSwitch } from '@client/components';
import { NotificationPanel } from '@client/components/NotificationPanel';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { BreadcrumbsStore, useBreadcrumbsStore, Breadcrumb } from '@client/stores/breadcrumbs';
import ContentItemsUpload from '@client/components/ContentItems/ContentItemsUpload.vue';
import ContentItemsUploadConfirmation from '@client/components/ContentItems/ContentItemsUploadConfirmation.vue';
import UserMenu from '@client/components/User/UserMenu.vue';
import AppLogo from '@client/components/Layouts/AppLogo.vue';

@Component({
  components: {
    AppLogo,
    UserMenu,
    ContentItemsUpload,
    LocaleSwitch,
    NotificationPanel,
    ContentItemsUploadConfirmation,
  },
})
export default class NavigationWrapper extends Vue {
  private drawer: boolean = false;
  private notificationDrawer: boolean = false;

  private messagesStore: MessagesStore = useMessagesStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();

  get breadcrumbs(): Breadcrumb[] {
    return this.breadcrumbsStore.breadcrumbs;
  }

  get showErrorModal(): boolean {
    return this.appGlobalStore.showGenericErrorModal;
  }

  get showErrorModalReloadButton(): boolean {
    return this.appGlobalStore.showGenericErrorModalReloadButton;
  }

  get errorModalText(): string {
    return this.appGlobalStore.genericErrorModalText;
  }

  get isValidationError(): boolean {
    return this.appGlobalStore.isValidationError;
  }

  get forceShowIgnoreButton(): boolean {
    return this.appGlobalStore.forceShowIgnoreButton;
  }

  get showSnackbar(): boolean {
    return this.messagesStore.displayedMessages.length != 0;
  }

  get pushNotification(): string {
    return this.messagesStore.displayedMessages.length !== 0 ? this.messagesStore.displayedMessages[0] : '';
  }

  removeSnackBarMessage(): void {
    this.messagesStore.removeMessageFromQueue();
  }

  hideErrorModal(): void {
    this.appGlobalStore.updateGenericErrorModal({ showGenericErrorModal: false });
  }

  reloadPage(): void {
    window.location.reload();
  }

  toggleNotificationPanel(): void {
    this.notificationDrawer = !this.notificationDrawer;
  }
}
