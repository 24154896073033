import { ContentItemJSON } from '@common/content-item/types';
import { ContentItem, ContentItemFilters } from '@client/models/ContentModels/types';
import { isImageJSON, isPlaylistJSON, isVideoJSON } from '@common/content-item/utils';
import VideoModel from '@client/models/ContentModels/Video.model';
import ImageModel from '@client/models/ContentModels/Image.model';
import PlaylistModel from '@client/models/ContentModels/Playlist.model';
import FolderModel from '@client/models/ContentModels/Folder.model';
import Moment from 'moment';
import { getAncestorName, getContentItemParent } from '@client/components/ContentItems/ListItems/utils';

export const contentItemFromJSON = (contentItemJSON: ContentItemJSON): ContentItem => {
  if (isVideoJSON(contentItemJSON)) {
    return VideoModel.fromJSON(contentItemJSON);
  } else if (isImageJSON(contentItemJSON)) {
    return ImageModel.fromJSON(contentItemJSON);
  } else if (isPlaylistJSON(contentItemJSON)) {
    return PlaylistModel.fromJSON(contentItemJSON);
  } else {
    return FolderModel.fromJSON(contentItemJSON);
  }
};

/**
 * Check whether content item is matching filters set
 * @param contentItem an content item to be checked
 * @param contentItemFilters filter values
 */
export const isContentItemFiltered = (contentItem: ContentItem, contentItemFilters: ContentItemFilters): boolean => {
  if (
    contentItemFilters.originalName &&
    ((contentItem.isMediaFile() &&
      !contentItem.originalName.toLowerCase().includes(contentItemFilters.originalName.toLowerCase())) ||
      (!contentItem.isMediaFile() &&
        !contentItem.name.toLowerCase().includes(contentItemFilters.originalName.toLowerCase())))
  ) {
    return false;
  }
  if (
    contentItemFilters.type &&
    contentItemFilters.type.length &&
    !contentItemFilters.type.includes(contentItem.type)
  ) {
    return false;
  }
  if (contentItemFilters.createdAtAsTime[0] && contentItemFilters.createdAtAsTime[1]) {
    if (
      Moment(contentItem.createdAtAsTime).isBefore(Moment(contentItemFilters.createdAtAsTime[0])) ||
      Moment(contentItem.createdAtAsTime).isAfter(contentItemFilters.createdAtAsTime[1])
    ) {
      return false;
    }
  }
  if (
    contentItemFilters.dimensions &&
    (!contentItem.isMediaFile() ||
      !contentItem.dimensions.toLowerCase().includes(contentItemFilters.dimensions.toLowerCase()))
  ) {
    return false;
  }
  if (contentItemFilters.duration[0] || contentItemFilters.duration[1]) {
    if (contentItem.isVideo() || contentItem.isPlaylist()) {
      if (contentItemFilters.duration[0] && contentItemFilters.duration[0] > contentItem.duration) {
        return false;
      }
      if (contentItemFilters.duration[1] && contentItemFilters.duration[1] < contentItem.duration) {
        return false;
      }
    } else {
      return false;
    }
  }
  if (contentItemFilters.fps && (!contentItem.isVideo() || contentItem.fps !== contentItemFilters.fps)) {
    return false;
  }
  if (
    contentItemFilters.parentFolder &&
    !getAncestorName(getContentItemParent(contentItem))
      .toString()
      .toLowerCase()
      .includes(contentItemFilters.parentFolder.toLowerCase())
  ) {
    return false;
  }
  return true;
};
