export type Optional<T> = T | undefined | null;

interface IQueue<T> {
  enqueue(item: T): void;
  dequeue(): T | undefined;
  size(): number;
}

export class Queue<T> implements IQueue<T> {
  private queueItems: T[] = [];

  enqueue(item: T): void {
    this.queueItems.push(item);
  }
  dequeue(): T | undefined {
    return this.queueItems.shift();
  }
  size(): number {
    return this.queueItems.length;
  }
}
