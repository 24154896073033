
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ContentType } from '@common/enums';
import { TranslateResult } from 'vue-i18n';
import TypeFilter from '@client/components/ContentItems/Search/TypeFilter.vue';
import { Optional } from '@common/types';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { contentItemTypes } from '@client/components/ContentItems/utils';

export type SearchParams = {
  typeFilter: Array<ContentType>;
  searchTerm: string;
};

@Component({
  methods: {
    DEFAULT_COMMON_STRING_MAX_LENGTH() {
      return DEFAULT_COMMON_STRING_MAX_LENGTH;
    },
    validateTextFieldLength,
  },
  components: { TypeFilter },
})
export default class Search extends Vue {
  /* DECLARATIONS */
  @Prop({ default: () => Object.values(ContentType) })
  private selectableContentType?: Array<ContentType>;
  @Prop({ default: () => [] })
  private defaultSelectedValues!: Array<ContentType>;

  private selectedTypes: Array<ContentType> = [];
  private searchTerm: string = '';
  private isSearchDisabled: boolean = true;

  /* LIFECYCLE EVENTS */
  created(): void {
    if (this.defaultSelectedValues.length) {
      this.selectedTypes = this.defaultSelectedValues;
    }
  }
  /* METHODS */
  onSelectedFilterChange(filters: Array<ContentType>): void {
    this.selectedTypes = filters;
  }

  onClear(): void {
    (this.$refs.typeFilter as Optional<TypeFilter>)?.clearSelected();
  }

  onSearch(): void {
    if (this.isSearchDisabled) {
      return;
    }
    this.triggerSearch();
  }

  @Emit('onSearch')
  triggerSearch(): SearchParams {
    return {
      typeFilter: this.selectedTypes,
      searchTerm: this.searchTerm,
    };
  }

  @Watch('searchTerm')
  onSearchTermChanged(): void {
    if (this.searchTerm.length === 0 && this.searchTerm.length > DEFAULT_COMMON_STRING_MAX_LENGTH) {
      this.isSearchDisabled = true;
      return;
    } else {
      this.isSearchDisabled = false;
    }
  }
  /* GETTERS */

  get selectedTypesText(): string {
    const appliedFilters: Array<{ text: TranslateResult; value: ContentType; icon: string }> = contentItemTypes(
      this
    ).filter((filterOption: { text: TranslateResult; value: ContentType; icon: string }) =>
      this.selectedTypes.includes(filterOption.value)
    );
    return appliedFilters
      .map((option: { text: TranslateResult; value: ContentType; icon: string }) => option.text)
      .join(', ');
  }
}
