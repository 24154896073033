/**
 * The type definition of a response returned from the publisher REST API
 */
export interface ErrorResponse<DetailsType = void> {
  errorType: ErrorType;
  message: string;
  statusCode: number;
  timestamp: Date;
  details?: DetailsType;
}

/**
 * Definition of all common error types.
 * Each error is attached with a specific error types. This allows us to distinguish between different errors.
 * Can be used as base class for more specific types.
 */
export class ErrorType {
  public static readonly UNEXPECTED_ERROR: ErrorType = new ErrorType('unexpected');
  public static readonly APIM_ERROR: ErrorType = new ErrorType('apim_error');
  public static readonly APIM_INVALID_STORE: ErrorType = new ErrorType('apim_invalid_store');
  public static readonly APIM_MISSING_VRAIL_KEY: ErrorType = new ErrorType('apim_missing_vrail_key');
  public static readonly APIM_MISSING_VRAIL_PERMISSIONS: ErrorType = new ErrorType('apim_missing_vrail_permissions');
  public static readonly APIM_INVALID_VCLOUD_SUBSCRIPTION: ErrorType = new ErrorType(
    'apim_invalid_vcloud_subscription'
  );
  public static readonly NO_VALID_DEVICE: ErrorType = new ErrorType('no_valid_device');
  public static readonly VALIDATION: ErrorType = new ErrorType('validation');
  public static readonly HASH_CONFLICT: ErrorType = new ErrorType('hash_conflict');
  public static readonly DUPLICATE: ErrorType = new ErrorType('duplicate');
  public static readonly NOT_FOUND: ErrorType = new ErrorType('not_found');
  public static readonly BAD_REQUEST: ErrorType = new ErrorType('bad_request');
  public static readonly ILLEGAL_STATE: ErrorType = new ErrorType('illegal_state');
  public static readonly INVALID_AUTHENTICATION: ErrorType = new ErrorType('invalid_authentication');
  public static readonly UNKNOWN_ERROR: ErrorType = new ErrorType('unknown');

  public readonly name: string;

  constructor(name: string) {
    this.name = name;
  }

  public toString(): string {
    return this.name;
  }
}

/**
 * Base error definition. Can be used as base class for specific errors.
 */
export class BaseError<DetailsType = void> extends Error {
  public readonly status: number;
  public readonly type: ErrorType;
  public readonly details?: DetailsType;

  constructor(message: string, status: number, type: ErrorType, details?: DetailsType) {
    super(message);
    this.status = status;
    this.type = type;
    this.details = details;
  }

  public toJSON(): ErrorResponse<DetailsType> {
    return {
      errorType: this.type,
      message: this.message,
      statusCode: this.status,
      timestamp: new Date(),
      details: this.details,
    };
  }

  public static fromError<DetailsType>(
    error: Error,
    status: number,
    type: ErrorType,
    details?: DetailsType
  ): BaseError<DetailsType> {
    return new BaseError<DetailsType>(error.message, status, type, details);
  }
}

/**
 * Fallback generic error
 */
export class UnexpectedError extends BaseError {
  constructor(message: string) {
    super(message, 500, ErrorType.UNEXPECTED_ERROR);
  }
}

/**
 * Database specific error.
 */
export class DatabaseError extends BaseError {
  constructor(message: string = 'Unexpected error while querying the database') {
    super(message, 500, ErrorType.UNEXPECTED_ERROR);
  }
}

/**
 * We use hashes to detect concurrency conflicts.
 * Any update or delete operation contains the hash of the object that is manipulated.
 * If the provided hash does not match the hash in the database this error will be thrown.
 */
export class HashConflictError extends BaseError {
  constructor(message: string = 'Provided hash does not match with hash of current version') {
    super(message, 409, ErrorType.HASH_CONFLICT);
  }
}

/**
 * Thrown when trying to create a duplicate entry.
 */
export class DuplicateError extends BaseError {
  constructor(message: string = 'Resource already exists') {
    super(message, 409, ErrorType.DUPLICATE);
  }
}

/**
 * Thrown when the requested entity isn't found
 */
export class EntityNotFoundError extends BaseError {
  constructor(message: string) {
    super(message, 404, ErrorType.NOT_FOUND);
  }
}

/**
 * Error that is thrown when the data validation failed.
 */
export class InvalidRequestError extends BaseError {
  constructor(message: string = 'Invalid request') {
    super(message, 422, ErrorType.BAD_REQUEST);
  }
}

/**
 * Error that is thrown when the data validation failed.
 */
export class ValidationError extends BaseError {
  constructor(message: string = 'Validation failed') {
    super(message, 422, ErrorType.VALIDATION);
  }
}

/**
 * Error that is thrown when the authentication to the backend failed.
 */
export class InvalidAuthenticationError extends BaseError {
  constructor(message: string = 'Failed to authenticate to API') {
    super(message, 403, ErrorType.INVALID_AUTHENTICATION);
  }
}

/**
 * Error that is thrown when an operation can not be performed due to an invalid server state
 */
export class IllegalStateError extends BaseError {
  constructor(message: string) {
    super(message, 500, ErrorType.BAD_REQUEST);
  }
}

/**
 *  APIM communication error
 */
export class APIMError extends BaseError {
  constructor(message: string, statusCode: number = 500, errorType: ErrorType = ErrorType.APIM_ERROR) {
    super(message, statusCode, errorType);
  }
}

/**
 *  APIM wrong store error, occurs when sending a request to the wrong store.
 */
export class APIMInvalidStoreError extends APIMError {
  constructor(message: string) {
    super(message, 412, ErrorType.APIM_INVALID_STORE);
  }
}

/**
 *  APIM Error, occurs when the client is missing the vrail subscription key.
 */
export class APIMMissingVRAILSubscription extends APIMError {
  constructor(message: string) {
    super(message, 403, ErrorType.APIM_MISSING_VRAIL_KEY);
  }
}

/**
 *  APIM Error, occurs when the client is missing the right permissions from vrail subscription key.
 */
export class APIMMissingPermissionsVRAILSubscription extends APIMError {
  constructor(message: string) {
    super(message, 403, ErrorType.APIM_MISSING_VRAIL_PERMISSIONS);
  }
}

/**
 *  APIM Error, occurs when the vcloud instance is missing its config.
 */
export class APIMInvalidVCloudSubscription extends APIMError {
  constructor(message: string) {
    super(message, 401, ErrorType.APIM_INVALID_VCLOUD_SUBSCRIPTION);
  }
}

/**
 *  Error occurs when trying to do an action to a device that doesn't support a feature
 *  Example: Trying to set powersave on a device that has powersave in it's notImplementedFlags
 */
export class NoValidDevice extends APIMError {
  constructor(message: string) {
    super(message, 401, ErrorType.NO_VALID_DEVICE);
  }
}

/**
 * Severity of an error.
 * CRITICAL: Errors that can not be forced.
 * FORCEABLE: Errors that can be forced, but require manual confirmation).
 */
export enum ValidationErrorSeverity {
  CRITICAL = 'critical',
  FORCEABLE = 'forceable',
}
