
import { Component, Vue } from 'vue-property-decorator';
import { GondolaTemplate as GondolaTemplateModel } from '@client/models';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import GondolaTemplate from '@client/components/GondolaTemplate/GondolaTemplate.vue';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import GondolaTemplateListToolbar from '@client/components/GondolaTemplate/GondolaTemplateListToolbar.vue';

/**
 * Renders a list of gondola templates.
 * Also contains the toolbar that allows adding and filtering gondola templates.
 */
@Component({
  components: {
    GondolaTemplateListToolbar,
    GondolaTemplate,
  },
})
export default class GondolaTemplateListView extends Vue {
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  created(): void {
    this.schedulesStore.clearSelectedSchedule();
    this.breadcrumbsStore.replace({
      path: `${this.$route.fullPath}`,
      title: { key: this.$i18nTranslationKeys.gondolaTemplateListView.breadcrumb.$path },
    });
    this.gondolaTemplatesStore.clearSearchFilters();
    this.gondolaTemplatesStore.fetch();
  }

  get gondolaTemplates(): Array<GondolaTemplateModel> {
    return this.gondolaTemplatesStore.searchResults.results;
  }
}
