import { BaseEventLog } from '../EventLog.model';
import { ActiveHoursEventType, EventType, StoreActiveHoursEventLogJSON } from '@common/eventlog/types';
import { StoreActiveHoursDeviceEvent } from './StoreActiveHoursDeviceEvent.model';
import Store from '../../StoreModels/Store.model';
import { Optional } from '@common/types';

export class StoreActiveHoursEventLog extends BaseEventLog {
  transactionId: string;
  activeHoursStatus: ActiveHoursEventType;
  devices: Array<StoreActiveHoursDeviceEvent>;
  constructor(
    id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    store: Optional<Store>,
    customerId: string,
    createdAt: Date,
    updatedAt: Date,
    transactionId: string,
    activeHoursStatus: ActiveHoursEventType,
    devices: Array<StoreActiveHoursDeviceEvent>
  ) {
    super(id, schemaVersion, type, azureStoreId, store, customerId, createdAt, updatedAt);
    this.transactionId = transactionId;
    this.activeHoursStatus = activeHoursStatus;
    this.devices = devices;
  }

  public static fromJSON(storeActiveHoursEventLogJSON: StoreActiveHoursEventLogJSON): StoreActiveHoursEventLog {
    const {
      _id,
      azureStoreId,
      store,
      customerId,
      type,
      updatedAt,
      createdAt,
      schemaVersion,
      activeHoursStatus,
      devices,
      transactionId,
    }: StoreActiveHoursEventLogJSON = storeActiveHoursEventLogJSON;
    return new StoreActiveHoursEventLog(
      _id,
      schemaVersion,
      type,
      azureStoreId,
      store ? Store.fromJSON(store) : undefined,
      customerId,
      createdAt,
      updatedAt,
      transactionId,
      activeHoursStatus,
      devices.map(StoreActiveHoursDeviceEvent.fromJSON)
    );
  }
}
