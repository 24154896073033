
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from '@client/models';
import Moment from 'moment';
import { TranslateResult } from 'vue-i18n';
import { EventType } from '@common/eventlog/types';
import TimePicker from '../TimePicker/TimePicker.vue';
import DatePicker from '@client/components/Filters/DatePicker/DatePicker.vue';
import { DateFilterPrefill } from '@client/components/Filters/DatePicker/types';
import { EventLogFilters, EventLogsStore, useEventLogsStore } from '@client/stores/eventLogs';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { getDateRangePrefill, getDateTimePrefill, getIsoDate } from '@client/components/Filters/DatePicker/utils';
import { MILLISECONDS_PER_DAY } from '@common/constants';

@Component({
  components: {
    DatePicker,
    TimePicker,
  },
})
export default class NotificationFilters extends Vue {
  private defaultDateFilterPrefill: DateFilterPrefill = DateFilterPrefill.ONE_DAY;
  private selectedDatePrefill: DateFilterPrefill = this.defaultDateFilterPrefill;
  private datePrefillKey: number = 0;
  private selectedTypes: Array<EventType> = [EventType.PUBLISHING, EventType.REGISTERED_STATUS];
  private dateRangeValue: [string, string?] = this.initialPrefillDate;
  private selectedStores: Array<string> = [];

  private thirtyOneDaysAgo: Date = new Date(Date.now() - 31 * MILLISECONDS_PER_DAY);
  private minimumDateFilter: string = this.thirtyOneDaysAgo.toISOString();
  private areFiltersChanged: boolean = false;
  private advancedSearchExpanded: boolean = false;
  private areAdvancedSearchFiltersSet: boolean = false;

  private searchTerm: string = '';
  private eventLogsStore: EventLogsStore = useEventLogsStore();
  private storesStore: StoresStore = useStoresStore();

  /* LIFECYCLE EVENTS */

  async created(): Promise<void> {
    await this.initFilters();
    await this.triggerSearch();
  }

  /* METHODS */

  async initFilters(withDateInit?: boolean): Promise<void> {
    if (withDateInit) {
      this.dateRangeValue = this.initialPrefillDate;
    }
    this.selectedStores = [];
    this.selectedTypes = [EventType.PUBLISHING, EventType.REGISTERED_STATUS];
    this.searchTerm = '';
    this.datePrefillKey += 1;
    this.areAdvancedSearchFiltersSet = false;
    this.areFiltersChanged = false;
  }

  setFiltersInStore(): void {
    const eventLogFilters: EventLogFilters = {
      stores: this.selectedStores,
      searchTerm: this.searchTerm,
      types: this.selectedTypes,
      dateRange: this.formatSelectedQueryDate(),
    };
    this.eventLogsStore.setFilters(eventLogFilters);
  }

  formatSelectedQueryDate(): [number, number] {
    return [Moment(this.dateRangeValue[0]).toDate().valueOf(), Moment(this.dateRangeValue[1]).toDate().valueOf()];
  }

  setFiltersChanged(): void {
    this.areFiltersChanged = true;
  }

  async triggerSearch(): Promise<void> {
    this.areFiltersChanged = false;
    this.setFiltersInStore();
    await this.eventLogsStore.fetch();
  }

  @Watch('dateRangeValue', { deep: true })
  onDateChange(): void {
    this.setFiltersChanged();
  }

  onAdvancedFiltersChange(): void {
    this.areAdvancedSearchFiltersSet = this.searchTerm !== '' || this.selectedDatePrefill !== DateFilterPrefill.ONE_DAY;
  }

  onDatePrefillChange(datePrefill: DateFilterPrefill): void {
    this.selectedDatePrefill = datePrefill;
    this.onAdvancedFiltersChange();
  }

  /* GETTERS */

  get storesList(): Array<Store> {
    return this.storesStore.getStoresWithValidAzureId();
  }
  get isLoading(): boolean {
    return this.eventLogsStore.isSearching;
  }

  get totalCount(): number {
    return this.eventLogsStore.totalCount;
  }

  get initialPrefillDate(): [string, string] {
    const timeRangeValue: [string, string] = getDateTimePrefill(DateFilterPrefill.ONE_DAY);
    const dateRangeValue: [string, string] = getDateRangePrefill(DateFilterPrefill.ONE_DAY);
    return [getIsoDate(dateRangeValue[0], timeRangeValue[0]), getIsoDate(dateRangeValue[1], timeRangeValue[1])];
  }

  get notificationTypes(): Array<{ text: TranslateResult; value: EventType; icon: string }> {
    return [
      {
        text: this.$t(this.$i18nTranslationKeys.notifications.types.publishing.$path),
        value: EventType.PUBLISHING,
        icon: `mdi-publish`,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.notifications.types.activeHours.$path),
        value: EventType.STORE_ACTIVE_HOURS,
        icon: `mdi-lightbulb-on`,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.notifications.types.online.$path),
        value: EventType.ONLINE_STATUS,
        icon: `mdi-signal-variant`,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.notifications.types.registration.$path),
        value: EventType.REGISTERED_STATUS,
        icon: `mdi-note-edit`,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.notifications.types.storeSync.$path),
        value: EventType.STORE_SYNC,
        icon: `mdi-store`,
      },
    ];
  }
}
