
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola, GondolaPublishing, Device, DevicePublishing, Store } from '@client/models';
import { Optional } from '@common/types';
import Preview from '@client/components/DeviceDetail/Preview.vue';
import Details from '@client/components/DeviceDetail/Details.vue';
import DeviceEvents from '@client/components/DeviceDetail/Events.vue';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import DevicePublishingForegroundContentModel from '@client/models/DeviceModels/DevicePublishingForegroundContentModel';
import DevicePublishingScheduledContentModel from '@client/models/DeviceModels/DevicePublishingScheduledContentModel';
import Schedules from '@client/components/DeviceDetail/Schedules.vue';
import ActiveHours from '@client/components/DeviceDetail/ActiveHours.vue';
import StoreAndSectionActiveHours from '@client/models/ActiveHoursModels/StoreAndSectionActiveHours';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import DeviceNotFound from '@client/components/NotFound/DeviceNotFound.vue';

@Component({
  components: { ActiveHours, Schedules, DeviceEvents, Details, Preview, DeviceNotFound },
})
export default class DeviceDetail extends Vue {
  /* DECLARATIONS */
  @Prop()
  private device?: Optional<Device>;
  @Prop()
  private deviceStore?: Optional<Store>;
  @Prop()
  private deviceGondola?: Optional<Gondola>;
  @Prop()
  private lastPublishing?: Optional<GondolaPublishing>;
  @Prop()
  private activeHours?: Optional<StoreAndSectionActiveHours>;

  private devicesStore: DevicesStore = useDevicesStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */

  async syncDevice(): Promise<void> {
    if (this.device && this.deviceStore) {
      const updatedRail: Optional<Device> = await this.devicesStore.syncDevice(
        this.device.longId,
        this.deviceStore.idAzure
      );
      if (!updatedRail) {
        return;
      }
      this.$emit('onSync', updatedRail);
    }
  }

  /* GETTERS */
  get schedules(): Array<ScheduleModel> {
    const schedules: Set<ScheduleModel> = new Set<ScheduleModel>();
    if (!this.lastPublishing) {
      return [];
    }
    this.lastPublishing?.railGrid?.flat<DevicePublishing[][]>(1).forEach((devicePublishing: DevicePublishing) => {
      devicePublishing.foregroundContent.forEach((foregroundContent: DevicePublishingForegroundContentModel) =>
        foregroundContent.scheduledContent?.forEach((scheduledContent: DevicePublishingScheduledContentModel) => {
          if (scheduledContent.schedule) {
            schedules.add(scheduledContent.schedule);
          }
        })
      );
      devicePublishing.backgroundContent[0].scheduledContent?.forEach(
        (scheduledContent: DevicePublishingScheduledContentModel) => {
          if (scheduledContent.schedule) {
            schedules.add(scheduledContent.schedule);
          }
        }
      );
    });
    return Array.from(schedules);
  }

  get isLoading(): boolean {
    return this.devicesStore.isFetching;
  }
}
