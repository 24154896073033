
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActiveHoursConfigEventLog } from '@client/models/EventLogModels/ActiveHoursConfigEventLog/ActiveHoursConfigEventLog.model';
import { getShortDayName } from '@client/utils/DateTimeUtils';
import NotificationTimeDisplay from '../NotificationTimeDisplay.vue';
import NotificationTitle from '../NotificationTitle.vue';
import { scrollToNotification } from '@client/components/NotificationPanel/utils';
import { Optional } from '@common/types';
import { Gondola, Store } from '@client/models';
import { TranslateResult } from 'vue-i18n';

@Component({
  methods: { getShortDayName },
  components: { NotificationTimeDisplay, NotificationTitle },
})
export default class ActiveHoursConfigNotification extends Vue {
  /* DECLARATIONS */
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: ActiveHoursConfigEventLog;

  private isNotificationContentExpanded: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  onNotificationClick(): void {
    if (!this.isNotificationContentExpanded) {
      this.isNotificationContentExpanded = true;
    }
  }

  onExpandToggleClick(): void {
    this.isNotificationContentExpanded = !this.isNotificationContentExpanded;
    if (!this.isNotificationContentExpanded) {
      scrollToNotification(this.eventLog._id);
    }
  }

  /* GETTERS */
  get activeHoursStoreName(): string {
    return this.eventLog.store?.name || this.eventLog.azureStoreId;
  }

  get notificationTitle(): string {
    const store: Optional<Store> = this.eventLog.store;
    if (!store) {
      return `${this.eventLog.azureStoreId} - ${this.messageFromConfig}`;
    }
    return ` - ${this.messageFromConfig}`;
  }

  get dateToDisplay(): string | Date {
    return this.eventLog.createdAt;
  }

  get isSectionActiveHoursConfig(): boolean {
    return !!this.eventLog.gondolaId;
  }

  get gondola(): Optional<Gondola> {
    if (!this.eventLog.store || !this.eventLog.gondolaId) {
      return undefined;
    }
    return (this.eventLog.store as Store).gondolas.find((gondola: Gondola) => gondola._id === this.eventLog.gondolaId);
  }

  get aisleName(): string {
    return this.gondola?.aisle || '';
  }

  get positionInAisle(): string {
    return this.gondola?.positionInAisle || '';
  }

  get linkToStore(): string {
    if (!this.eventLog.store) {
      return '';
    }
    return `/stores/${this.eventLog.store._id}`;
  }

  get messageFromConfig(): TranslateResult {
    const isConfigSet: boolean = !!this.eventLog.activeHoursConfig;
    if (this.isSectionActiveHoursConfig) {
      return isConfigSet
        ? this.$i18n.t(this.$i18nTranslationKeys.notifications.activeHoursConfig.activeHoursConfigSet.$path, {
            target: `${this.aisleName} - ${this.positionInAisle}`,
          })
        : this.$i18n.t(this.$i18nTranslationKeys.notifications.activeHoursConfig.activeHoursConfigRemoved.$path, {
            target: `${this.aisleName} - ${this.positionInAisle}`,
          });
    } else {
      return isConfigSet
        ? this.$i18n.t(this.$i18nTranslationKeys.notifications.activeHoursConfig.activeHoursConfigSet.$path, {
            target: this.activeHoursStoreName,
          })
        : this.$i18n.t(this.$i18nTranslationKeys.notifications.activeHoursConfig.activeHoursConfigRemoved.$path, {
            target: this.activeHoursStoreName,
          });
    }
  }
}
