
import { Component, Vue } from 'vue-property-decorator';
import { Gondola as GondolaModel, GondolaTemplate, Device, DeviceTemplate, Store } from '@client/models';
import router from '@client/router';
import { Gondola, GondolaRailGrid, ModalDialog } from '@client/components';
import { Route } from 'vue-router/types/router';
import { NextType } from '@client/definitions/hooks';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { TranslateResult } from 'vue-i18n';
import { GondolaValidationErrorDetails, StoreErrorType } from '@common/stores/error';
import { ErrorResponse, ValidationErrorSeverity } from '@common/error/types';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { ErrorStore, useErrorStore, ErrorObserver } from '@client/stores/error';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { StoresStore, useStoresStore, DisplayedGondolaValidationResult } from '@client/stores/stores';
import { Optional } from '@common/types';
import StoreNotFound from '@client/components/NotFound/StoreNotFound.vue';
import StoreSectionLoader from '@client/components/StoreDetail/StoreSectionLoader.vue';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import GondolaNotFound from '@client/components/NotFound/GondolaNotFound.vue';
import { getStoreDetailPath } from '@client/router/utils';

@Component({
  components: {
    GondolaNotFound,
    Gondola,
    ModalDialog,
    GondolaRailGrid,
    StoreNotFound,
    StoreSectionLoader,
  },
  methods: {
    validateTextFieldLength,
  },
})
export default class StoreSectionView extends Vue {
  /* DECLARATIONS */
  private static readonly ERROR_OBSERVER_KEY: string = 'StoreSectionView.vue';

  selectedTemplateId: string = '';
  private sectionCreationMode: string = 'manually';
  private currentStep: number = 1;
  private gondola: GondolaModel = new GondolaModel('', '');
  private isEdit: boolean = false;
  private isDirty: boolean = false;
  private showAll: boolean = false;
  private isValidating: boolean = false;
  private displayedGondolaValidationResult: DisplayedGondolaValidationResult | null = null;
  private showDeviceValidationModal: boolean = false;
  private showConfirmUnloadModal: boolean = false;
  private showForceMoveModal: boolean = false;
  private duplicateDeviceInOtherGondola: Array<Device> = [];
  private isDataLoaded: boolean = false;
  private isGondolaNotFound: boolean = false;
  private isLoading: boolean = false;
  private onUnloadModalConfirmed: () => void = () => {
    // Will be overridden later
  };
  private onUnloadModalCanceled: () => void = () => {
    // Will be overridden later
  };
  private ongoingValidations: Promise<void>[] = [];

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private errorStore: ErrorStore = useErrorStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private storesStore: StoresStore = useStoresStore();
  private devicesStore: DevicesStore = useDevicesStore();

  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    if (this.gondolaId) {
      this.isEdit = true;
    }
    try {
      await this.storesStore.fetch();
      await this.devicesStore.fetch(this.storeId);
      if (this.gondolaId) {
        const gondola: Optional<GondolaModel> = this.storesStore.getGondolaById(this.storeId, this.gondolaId);
        if (!gondola) {
          this.isGondolaNotFound = true;
          this.isDataLoaded = true;
          return;
        }
        this.$set(this, 'gondola', GondolaModel.clone(gondola));
        this.showSummary();
        this.displayedGondolaValidationResult = null;
        this.$forceUpdate();
      } else {
        await this.gondolaTemplatesStore.fetch();
      }
    } catch (e: unknown) {
      this.isDataLoaded = true;
      console.error(e);
    }
    this.updateBreadCrumbs();
    this.displayedGondolaValidationResult = null;
    this.createErrorObserver();

    window.addEventListener('beforeunload', this.beforeWindowUnload);
    this.isDataLoaded = true;
  }

  beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
    this.errorStore.deregister(StoreSectionView.ERROR_OBSERVER_KEY);
  }

  // on route change
  public beforeRouteLeave(_to: Route, _from: Route, next: NextType): void {
    if (this.isDirty) {
      this.showConfirmUnloadModal = true;
      this.onUnloadModalConfirmed = () => {
        this.showConfirmUnloadModal = false;
        this.saveGondola(true).then((success: boolean) => {
          if (success) {
            next();
          }
        });
      };
      this.onUnloadModalCanceled = () => {
        this.isDirty = false;
        this.showConfirmUnloadModal = false;
        next();
      };
    } else {
      // Navigate to next view
      next();
    }
  }

  // if browser window / tab closes
  beforeWindowUnload(e: Event): void {
    if (this.isDirty) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = false;
    }
  }
  /* METHODS */
  /**
   * abort saving the gondola and close modal
   */
  onErrorAbort(): void {
    this.showDeviceValidationModal = false;
  }

  onForceMove(): void {
    this.showForceMoveModal = false;
    this.saveGondola(true);
  }

  onForceMoveAbort(): void {
    this.showForceMoveModal = false;
  }

  back(): void {
    this.currentStep--;
    this.showDeviceValidationModal = false;
    this.displayedGondolaValidationResult = null;
  }

  shouldShowStep(step: number): boolean {
    return this.currentStep === step || this.showAll;
  }

  showSummary(): void {
    this.currentStep = 4;
    this.showAll = true;
  }

  onFormChange(): void {
    this.isDirty = true;
  }

  validateAisleName(value: string): boolean | TranslateResult {
    return validateTextFieldLength(
      value,
      DEFAULT_COMMON_STRING_MAX_LENGTH,
      true,
      this.$t(this.$i18nTranslationKeys.gondolaView.validation.aisleReq.$path)
    );
  }

  validateAislePosition(value: string): boolean | TranslateResult {
    return validateTextFieldLength(
      value,
      DEFAULT_COMMON_STRING_MAX_LENGTH,
      true,
      this.$t(this.$i18nTranslationKeys.gondolaView.validation.positionReq.$path)
    );
  }

  updateBreadCrumbs(): void {
    this.breadcrumbsStore.replace({
      path: `/stores`,
      title: {
        key: this.$i18nTranslationKeys.storeOverview.breadcrumb.$path,
      },
    });
    this.breadcrumbsStore.push({
      path: `/stores/${this.storeId}`,
      title: {
        key: this.$i18nTranslationKeys.storeDetail.breadcrumb.$path,
        params: {
          store: this.storeName || this.storeId,
        },
      },
    });
    this.breadcrumbsStore.push({
      path: `${this.$route.fullPath}`,
      title: this.isEdit
        ? {
            key: this.$i18nTranslationKeys.gondolaView.breadcrumbEdit.$path,
            params: {
              position: this.gondola.positionInAisle,
              aisle: this.gondola.aisle,
            },
          }
        : {
            key: this.$i18nTranslationKeys.gondolaView.breadcrumbAdd.$path,
            params: {
              store: this.storeName || this.storeId,
            },
          },
    });
  }

  createErrorObserver(): void {
    ErrorObserver.create(StoreSectionView.ERROR_OBSERVER_KEY)
      .attachHandler(
        StoreErrorType.GONDOLA_VALIDATION,
        (gondolaUpsertError: ErrorResponse<GondolaValidationErrorDetails>) => {
          if (gondolaUpsertError && gondolaUpsertError.details?.severity) {
            this.$set(this, 'displayedGondolaValidationResult', gondolaUpsertError);
          } else {
            return false;
          }
          if (gondolaUpsertError.details?.severity === ValidationErrorSeverity.FORCEABLE) {
            this.showForceMoveModal = true;
            this.duplicateDeviceInOtherGondola =
              gondolaUpsertError.details?.duplicateDevices.map(Device.fromJSON) || [];
          } else {
            this.showDeviceValidationModal = true;
            return false;
          }
          return true;
        }
      )
      .attachHandler(StoreErrorType.DUPLICATE, () => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.gondolaView.validation.positionInUse.$path),
          isValidationError: true,
          forceShowIgnoreButton: true,
        });
      })
      .register();
  }

  getDisplayNameForTemplate(template: GondolaTemplate): string | undefined {
    if (template.name && template.name !== '') {
      return template.name;
    }
    return template._id;
  }

  /**
   * Check if a given aisle position already exists for the current store
   */
  isPositionInAisleValid(): TranslateResult | boolean {
    if (!this.gondola?.aisle) {
      return true;
    }

    if (!this.gondola?.positionInAisle) {
      return this.$t(this.$i18nTranslationKeys.gondolaView.validation.positionReq.$path);
    }

    // new aisle, the position will always be valid
    if (this.gondola?.aisle && !this.aisles.includes(this.gondola.aisle)) {
      return true;
    }

    return (
      this.store?.gondolas.filter(
        (value: GondolaModel) =>
          value.positionInAisle === this.gondola?.positionInAisle &&
          value.aisle === this.gondola?.aisle &&
          value._id != this.gondola?._id
      ).length === 0 || this.$t(this.$i18nTranslationKeys.gondolaView.validation.positionInUse.$path)
    );
  }

  templateSelected(selectedTemplate: string): void {
    this.selectedTemplateId = selectedTemplate;
  }

  /**
   * First step is layout selection. After this step the temporary gondola has been setup either empty or
   * based on some template
   */
  onStep1Continue(): void {
    if (!(this.$refs.formStep1 as Vue & { validate: () => boolean }).validate() || this.isAisleFieldsValid) {
      return;
    }

    if (this.gondolaId) {
      this.currentStep++;
      return;
    }

    if (this.sectionCreationMode === 'template') {
      const railGrid: Device[][] = new Array(new Array<Device>());
      this.gondolaTemplatesStore
        .getById(this.selectedTemplateId)
        ?.railGrid.forEach((row: DeviceTemplate[], rowIndex: number) => {
          railGrid[rowIndex] = new Array<Device>();
          row.forEach((col: DeviceTemplate, colIndex: number) => {
            railGrid[rowIndex][colIndex] = new Device(col.hardwareModel);
          });
        });
      this.gondola.railGrid = railGrid;
    }

    this.currentStep++;
  }

  /**
   * Event handler for last step of wizard
   */
  onConfirmMatching(): void {
    this.saveGondola(false);
  }

  /**
   * persist the gondola
   */
  async saveGondola(force: boolean = false): Promise<boolean> {
    this.isLoading = true;
    if (!(this.$refs.formStep1 as Vue & { validate: () => boolean }).validate() || this.isAisleFieldsValid) {
      this.isLoading = false;
      return false;
    }
    // wait for ongoing validations to finish
    await Promise.all(this.ongoingValidations);
    // we check for validation errors by making sure that no validation error messages are set
    const hasValidationErrors: boolean = this.displayedGondolaValidationResult?.validationErrorMessages
      ? this.displayedGondolaValidationResult.validationErrorMessages.some((rowMessages: string[]) => {
          return rowMessages.some((message: string) => {
            return !!message;
          });
        })
      : false;
    const isValidationErrorForcible: boolean = this.displayedGondolaValidationResult
      ? this.displayedGondolaValidationResult.severity == ValidationErrorSeverity.FORCEABLE
      : false;

    if (hasValidationErrors && !isValidationErrorForcible) {
      // if we could force save, we do not need to show the validation error modal here
      this.showDeviceValidationModal = true;
      this.isLoading = false;
      return false;
    }

    this.displayedGondolaValidationResult = null;

    return (
      this.isEdit
        ? this.storesStore.editGondola(this.gondola, this.storeId, force)
        : this.storesStore.addGondola(this.gondola, this.storeId, force)
    ).then(
      () => {
        this.isLoading = false;
        this.isDirty = false;
        router.push(getStoreDetailPath(this.storeId, this.gondola.aisle, this.gondola.positionInAisle));
        return true;
      },
      (gondolaUpsertError: DisplayedGondolaValidationResult) => {
        this.isLoading = false;
        if (gondolaUpsertError && gondolaUpsertError.severity) {
          this.$set(this, 'displayedGondolaValidationResult', gondolaUpsertError);
        } else {
          return false;
        }
        if (gondolaUpsertError.severity === ValidationErrorSeverity.FORCEABLE) {
          this.showForceMoveModal = true;
          this.duplicateDeviceInOtherGondola = gondolaUpsertError.duplicateDeviceInOtherGondola || [];
        } else {
          this.showDeviceValidationModal = true;
          return false;
        }
        return true;
      }
    );
  }

  updateAisleInput(newAisle: string): void {
    this.gondola.aisle = newAisle;
  }

  updateValidationResult(validationResult: DisplayedGondolaValidationResult): void {
    this.displayedGondolaValidationResult = validationResult;
  }

  setValidationState(isValidating: boolean): void {
    this.isValidating = isValidating;
  }
  /* GETTERS */
  get isAisleFieldsValid(): boolean {
    return (
      this.gondola.positionInAisle?.length > DEFAULT_COMMON_STRING_MAX_LENGTH ||
      this.gondola.aisle?.length > DEFAULT_COMMON_STRING_MAX_LENGTH
    );
  }

  get store(): Optional<Store> {
    return this.storesStore.getStoreById(this.storeId);
  }

  get storeName(): string {
    return this.store?.name || '';
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get gondolaId(): string {
    return this.$route.params.gondolaid;
  }

  get aisles(): Array<string> {
    return this.storesStore.getAisleNamesOfStore(this.storeId);
  }

  get templates(): GondolaTemplate[] {
    return this.gondolaTemplatesStore.gondolaTemplates;
  }
}
