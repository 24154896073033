import Aisle from './Aisle.model';
import Gondola from '../GondolaModels/Gondola.model';
import { AisleJSON, StoreJSON } from '@common/stores/types';
import { GondolaJSON } from '@common/gondola-template/types';
import { Device } from '@client/models';
import { OnlineStatus } from '@common/enums';

export interface StoreDeviceStatistics {
  numberOfOnlineDevices: number;
  numberOfOfflineDevices: number;
  numberOfUnassignedDevices: number;
}

export default class Store {
  _id: string;
  name: string;
  hash: string;
  customerId: string;
  aisles: Aisle[];
  gondolas: Gondola[];
  idAzure: string;
  timezone: string;
  numberOfOnlineDevices: number = 0;
  numberOfOfflineDevices: number = 0;
  numberOfUnassignedDevices: number = 0;
  activeHoursJobFailed?: boolean;
  activeHoursTransactionId?: string;
  isStoreSyncing: boolean = false;

  constructor(
    _id: string,
    name: string,
    customerId: string,
    timezone: string = '',
    aisles: Aisle[] = [],
    gondolas: Gondola[] = [],
    idAzure: string = '',
    hash: string = '',
    activeHoursJobFailed: boolean = false,
    activeHoursTransactionId: string = ''
  ) {
    this.name = name;
    this._id = _id;
    this.customerId = customerId;
    this.timezone = timezone;
    this.aisles = aisles;
    this.gondolas = gondolas;
    this.idAzure = idAzure;
    this.hash = hash;
    const devices: Array<Device> = gondolas.map((gondola: Gondola) => gondola.railGrid.flat(1)).flat(1);
    this.numberOfUnassignedDevices = devices.filter((device: Device) => !device.gondolaId).length;
    this.numberOfOnlineDevices = devices.filter(
      (device: Device) => device.gondolaId && device.onlineStatus === OnlineStatus.ONLINE && device.shortId
    ).length;
    this.numberOfOfflineDevices = devices.filter(
      (device: Device) => device.gondolaId && device.onlineStatus === OnlineStatus.OFFLINE && device.shortId
    ).length;
    this.activeHoursJobFailed = activeHoursJobFailed;
    this.activeHoursTransactionId = activeHoursTransactionId;
  }

  setStoreSyncing(isStoreSyncing: boolean): void {
    this.isStoreSyncing = isStoreSyncing;
  }

  setDevicesStatistics({
    numberOfUnassignedDevices,
    numberOfOfflineDevices,
    numberOfOnlineDevices,
  }: StoreDeviceStatistics): void {
    this.numberOfOnlineDevices = numberOfOnlineDevices;
    this.numberOfOfflineDevices = numberOfOfflineDevices;
    this.numberOfUnassignedDevices = numberOfUnassignedDevices;
  }

  get numberOfAisles(): number {
    return this.aisles.length;
  }

  get numberOfGondolas(): number {
    return this.gondolas.length;
  }

  /**
   * Creates a deep copy of the store
   * @param source
   */
  static clone(source: Store): Store {
    const clonedAisles: Aisle[] = source.aisles.map((value: Aisle) => {
      return Aisle.clone(value);
    });

    const clonedGondolas: Gondola[] = source.gondolas.map((value: Gondola) => {
      return Gondola.clone(value);
    });
    return new Store(
      source._id,
      source.name,
      source.customerId,
      source.timezone,
      clonedAisles,
      clonedGondolas,
      source.idAzure,
      source.hash,
      source.activeHoursJobFailed,
      source.activeHoursTransactionId
    );
  }

  static fromJSON(source: StoreJSON): Store {
    const newAisles: Aisle[] = source.aisles.map((value: AisleJSON) => {
      return Aisle.fromJSON(value);
    });

    const newGondolas: Gondola[] = source.gondolas.map((value: GondolaJSON) => {
      return Gondola.fromJSON(value);
    });
    return new Store(
      source._id,
      source.name,
      source.customerId,
      source.timezone,
      newAisles,
      newGondolas,
      source.idAzure,
      source.hash,
      source.activeHoursJobFailed,
      source.activeHoursTransactionId
    );
  }

  static toJSON(store: Store): StoreJSON {
    return {
      _id: store._id,
      hash: store.hash,
      customerId: store.customerId,
      name: store.name,
      timezone: store.timezone,
      idAzure: store.idAzure,
      gondolas: store.gondolas.map((gondola: Gondola) => gondola.toJSON()),
      aisles: store.aisles.map((aisle: Aisle) => Aisle.toJSON(aisle)),
      activeHoursTransactionId: store.activeHoursTransactionId,
    };
  }
}
