// Note: This file contains utility functions for the router

/**
 * Get the path for the store detail page
 * @param storeId id of the store
 * @param aisle the aisle to navigate to
 * @param position the position in aisle to navigate to
 */
export const getStoreDetailPath = (storeId: string, aisle?: string, position?: string) => {
  let storeLink: string = `/stores/${storeId}`;
  if (!aisle) {
    return storeLink;
  }
  storeLink += `?aisle=${aisle}&position=${position}`;
  return storeLink;
};

/**
 * Get the path for the device detail page
 * @param deviceId id of the device
 */
export const getDeviceDetailPath = (deviceId: string) => {
  return `/devices/${deviceId}`;
};
