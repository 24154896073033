
import { Component, Prop, Vue } from 'vue-property-decorator';
import QRCodeVue from 'qrcode.vue';
import {
  copyQRCodeToClipboard,
  downloadQRCodeAsPNG,
  downloadQRCodeAsSVG,
  isClipboardAPICompatibleWithBrowser,
} from '@client/components/QRCode/utils';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    QRCodeVue,
  },
  methods: {
    copyQRCodeToClipboard,
    downloadQRCodeAsSVG,
    downloadQRCodeAsPNG,
    isClipboardAPICompatibleWithBrowser,
  },
})
export default class QRCode extends Vue {
  @Prop()
  private qrCodeData!: string | undefined;
  @Prop()
  private qrCodeFileName!: string;
  @Prop()
  private qrCodeTitle!: string | TranslateResult;

  get isDataAvailable(): boolean {
    return !!this.qrCodeData;
  }
}
