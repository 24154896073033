import { ActiveHourDay, ActiveHourItem, ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { Optional } from '@common/types';

/**
 * Check if devices are active for the whole day
 * @param activeHours schedule for specific day
 */
const isActiveAllDay = (activeHours: ActiveHourDay[]): boolean => {
  if (activeHours.length !== 1) {
    return false;
  }
  return activeHours[0].start === '00:00' && activeHours[0].end === '24:00';
};

const isStoreActiveHoursInDefaultState = (activeHoursSchedule: ActiveHoursSchedule): boolean => {
  return activeHoursSchedule.some((item: ActiveHourItem) => !isActiveAllDay(item.schedule));
};

/**
 * Deep equals check of two {@link ActiveHoursSchedule} objects
 * @param first
 * @param second
 */
const equals = (first: ActiveHoursSchedule, second: ActiveHoursSchedule): boolean => {
  for (const itemFromFirst of first) {
    const itemFromSecond: Optional<ActiveHourItem> = second.find(
      (item: ActiveHourItem) => item.day === itemFromFirst.day
    );
    if (!itemFromSecond) {
      return false;
    }
    if (itemFromSecond.schedule.length !== itemFromFirst.schedule.length) {
      return false;
    }
    for (let i: number = 0; i < itemFromFirst.schedule.length; i++) {
      if (
        itemFromFirst.schedule[i].start !== itemFromSecond.schedule[i].start ||
        itemFromFirst.schedule[i].end !== itemFromSecond.schedule[i].end ||
        itemFromFirst.schedule[i].dimmingLevel !== itemFromSecond.schedule[i].dimmingLevel
      ) {
        return false;
      }
    }
  }
  return true;
};
export { isActiveAllDay, isStoreActiveHoursInDefaultState, equals };
