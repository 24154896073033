
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import router from '@client/router';
import { B2CUser, Gondola, Device, Store } from '@client/models';
import { InlineEditTextField, ModalDialog } from '@client/components';
import { ViewActions } from '@client/enums';
import spacetime from 'spacetime';
import { formatTimezone } from '@client/utils/timezone';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { ErrorResponse, ErrorType } from '@common/error/types';
import { StoreActiveHours } from '@client/components/ActiveHours';
import StoreAndSectionActiveHours from '@client/models/ActiveHoursModels/StoreAndSectionActiveHours';
import { isStoreActiveHoursInDefaultState } from '@client/utils/ActiveHoursUtils';
import { StoreJSON } from '@common/stores/types';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { ErrorStore, useErrorStore, ErrorObserver } from '@client/stores/error';
import { QRCode } from '@client/components/QRCode';
import { TranslateResult } from 'vue-i18n';
import i18n from '@client/plugins/i18n/i18n';
import { isInputOnlyAscii } from '@common/utils/validation';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Optional } from '@common/types';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
/**
 * Entry of the timezone autocomplete component
 */
export interface TimezoneAutoCompleteEntry {
  displayText: string;
  iana: string;
}

@Component({
  components: {
    ModalDialog,
    InlineEditTextField,
    StoreActiveHours,
    QRCode,
  },
  methods: {
    validateTextFieldLength,
  },
})
export default class StoreDetailHeader extends Vue {
  private static readonly ERROR_OBSERVER_KEY: string = 'StoreDetailHeader';
  @Prop()
  private store!: Store;

  private storeName: string = '';
  private storeIdAzure: string = '';
  private showDeleteStoreDialog: boolean = false;
  private showConfirmAzureIdDialog: boolean = false;
  private timezone: string = '';
  private cancelHandler: () => void = () => {
    // Will be overridden later
  };
  private confirmHandler: () => void = () => {
    // Will be overridden later
  };
  /**
   * This function will return the confirmation dialog result to the component
   * If the user confirms he wants to edit the azure id it should return true
   */
  private confirmAzureIdUpdate: () => Promise<boolean> = () => {
    return new Promise<boolean>(() => true);
  };
  private showDuplicateAzureIdErrorModal: boolean = false;
  private duplicateIdAzureStoreId: string = '';
  private duplicateIdAzureStoreName: string = '';
  private storeAndSectionActiveHours: StoreAndSectionActiveHours = { storeActiveHours: [], sectionActiveHours: [] };

  private actionQueue: Promise<void> = Promise.resolve();

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private errorStore: ErrorStore = useErrorStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();
  private devicesStore: DevicesStore = useDevicesStore();

  async created(): Promise<void> {
    if (this.storeId) {
      this.storeAndSectionActiveHours =
        this.activeHoursStore.getActiveHoursByStoreId(this.storeId) ?? this.storeAndSectionActiveHours;
      this.storeName = this.store.name;
      this.storeIdAzure = this.store.idAzure;
      this.timezone = this.store.timezone;
    }
    this.breadcrumbsStore.replace({
      path: `/stores/`,
      title: { key: this.$i18nTranslationKeys.storeOverview.breadcrumb.$path },
    });
    this.breadcrumbsStore.push({
      path: `${this.$route.fullPath}`,
      title: {
        key: this.$i18nTranslationKeys.storeDetail.breadcrumb.$path,
        params: {
          store: this.storeName || this.storeId,
        },
      },
    });
    this.timezone = this.timezone || (this.isNew && spacetime().timezone().name) || '';
    this.registerObserver();
    this.confirmAzureIdUpdate = async () => {
      this.storeAndSectionActiveHours =
        this.activeHoursStore.getActiveHoursByStoreId(this.storeId) ?? this.storeAndSectionActiveHours;
      const isActiveHoursSet: boolean = isStoreActiveHoursInDefaultState(
        this.storeAndSectionActiveHours.storeActiveHours
      );
      if (!isActiveHoursSet) {
        return true;
      }
      return new Promise<boolean>((resolve: (value: boolean | PromiseLike<boolean>) => void) => {
        this.showConfirmAzureIdDialog = true;
        this.cancelHandler = () => {
          resolve(false);
          this.showConfirmAzureIdDialog = false;
        };
        this.confirmHandler = () => {
          resolve(true);
          this.showConfirmAzureIdDialog = false;
        };
      });
    };
  }

  beforeDestroy(): void {
    this.errorStore.deregister(StoreDetailHeader.ERROR_OBSERVER_KEY);
  }

  @Watch('isLoading')
  setStoreAndSectionActiveHours(): void {
    if (this.storeId && !this.isLoading) {
      this.storeAndSectionActiveHours =
        this.activeHoursStore.getActiveHoursByStoreId(this.storeId) ?? this.storeAndSectionActiveHours;
    }
  }

  registerObserver(): void {
    ErrorObserver.create(StoreDetailHeader.ERROR_OBSERVER_KEY)
      .attachHandler(ErrorType.DUPLICATE, (errorResponse: ErrorResponse<StoreJSON>): void => {
        this.duplicateIdAzureStoreId = errorResponse.details?._id || '';
        this.duplicateIdAzureStoreName = errorResponse.details?.name || '';
        this.showDuplicateAzureIdErrorModal = true;
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.ILLEGAL_STATE, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(
            this.$i18nTranslationKeys.error.StoreCRUDError.AZURE_ID_EXISTING_DEVICES.$path
          ),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_ERROR, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.StoreCRUDError.WEBHOOK_NOT_CREATED.$path),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_INVALID_STORE, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.APIM.INVALID_STORE.$path, { azureId }),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_MISSING_VRAIL_KEY, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.APIM.MISSING_VRAIL_KEY.$path, { azureId }),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_MISSING_VRAIL_PERMISSIONS, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.APIM.MISSING_VRAIL_PERMISSIONS.$path, {
            azureId,
          }),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_INVALID_VCLOUD_SUBSCRIPTION, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.APIM.MISSING_VCLOUD_SUBSCRIPTION.$path, {
            azureId,
          }),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.UNEXPECTED_ERROR, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.genericUnexpected.$path),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .register();
  }

  validateStoreAzureId(value: string): boolean | TranslateResult {
    if (!isInputOnlyAscii(value)) {
      return i18n.t(this.$i18nTranslationKeys.error.nonASCIIInput.$path);
    }
    const maxInternalLength: number = 128;
    const customerIdLength: number = this.appGlobalStore.customer.length;
    // azure id internally on APIM is saved as "{customerId}-{AzureId}" and limited to 128 char
    const maxAzureIdLength: number = maxInternalLength - customerIdLength - 1;
    return validateTextFieldLength(value, maxAzureIdLength);
  }

  deleteStore(): void {
    this.showDeleteStoreDialog = true;
    this.confirmHandler = () => {
      this.actionQueue = this.actionQueue.then(() => {
        return this.storesStore.deleteStore(this.storeId).then(() => {
          router.push(`/stores`);
        });
      });

      this.showDeleteStoreDialog = false;
    };
    this.cancelHandler = () => {
      this.showDeleteStoreDialog = false;
    };
  }

  updateStoreName(): void {
    if (this.storeName.length <= DEFAULT_COMMON_STRING_MAX_LENGTH) {
      this.actionQueue = this.actionQueue
        .then(() => {
          return this.storesStore.updateStoreName(this.storeName, this.storeId);
        })
        .catch(() => {
          this.storeName = this.storesStore.getStoreById(this.storeId)?.name || '';
        });
    }
  }

  updateStoreTimezone(): void {
    if (!this.timezone) {
      return;
    }
    this.actionQueue = this.actionQueue
      .then(() => {
        return this.storesStore.updateStoreTimezone(this.timezone, this.storeId);
      })
      .catch(() => {
        this.timezone = this.storesStore.getStoreById(this.storeId)?.timezone || '';
      });
  }

  updateStoreIdAzure(): void {
    if (this.storesStore.getStoreById(this.storeId)?.idAzure === this.storeIdAzure) {
      return;
    }

    if (this.storeIdAzure.length <= DEFAULT_COMMON_STRING_MAX_LENGTH && isInputOnlyAscii(this.storeIdAzure)) {
      this.actionQueue = this.actionQueue
        .then(async () => {
          await this.storesStore.updateStoreIdAzure(this.storeIdAzure, this.storeId);
          await this.activeHoursStore.fetchActiveHours(this.storeId);
          this.storeAndSectionActiveHours =
            this.activeHoursStore.getActiveHoursByStoreId(this.storeId) ?? this.storeAndSectionActiveHours;
        })
        .catch(() => {
          this.storeIdAzure = this.storesStore.getStoreById(this.storeId)?.idAzure || '';
        });
    }
  }

  hideIdAzureErrorModal(): void {
    this.showDuplicateAzureIdErrorModal = false;
  }

  async syncDevices(): Promise<void> {
    await this.storesStore.syncDevices(this.storeId);
  }

  get DEFAULT_COMMON_STRING_MAX_LENGTH(): number {
    return DEFAULT_COMMON_STRING_MAX_LENGTH;
  }

  /**
   * Checks if a store has any devices with an assigned short or long id.
   * If yes, the azure id cannot be edited
   */
  get isAzureIdEditable(): boolean {
    try {
      const currentStore: Optional<Store> = this.storesStore.getStoreById(this.storeId);
      if (!currentStore) {
        return true;
      }
      return currentStore.gondolas.every((gondola: Gondola) => {
        return (
          gondola.railGrid &&
          gondola.railGrid.every((row: Device[]) => {
            return row.every((device: Device) => {
              return (
                device &&
                (device.longId == undefined ||
                  device.longId.length == 0 ||
                  device.shortId == undefined ||
                  device.shortId.length == 0)
              );
            });
          })
        );
      });
    } catch (error) {
      return true;
    }
  }

  get isLoading(): boolean {
    return (
      this.activeHoursStore.loadingIndicator.update ||
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update
    );
  }

  /*
  Returns the qrcode's file name to be downloaded
  If both the store name and ID Azure are available it will be as the following: "<storeIdAzure>-<storeName>"
  Else it will be either one of the following, respecting this order:
  <ol>
  <li>storeIdAzure</li>
  <li>storeName</li>
  <li>storeId</li>
  </ol>
   */
  get qrCodeFileName(): string {
    // If both the store name and azure id are available, return the following format
    if (this.storeName && this.storeIdAzure) {
      return `${this.storeIdAzure}-${this.storeName}`;
    }
    // Else return any of these fallback values
    return this.storeIdAzure || this.storeName || this.storeId;
  }

  get storeQRCodeData(): string | undefined {
    const b2cUser: B2CUser = this.$msal.data.user as B2CUser;
    const apimUrl: string = b2cUser.idToken.extension_gatewayUrl;
    const apimKey: string = b2cUser.idToken.extension_primaryKey;
    if (!this.storeIdAzure || !apimUrl || !apimKey) {
      return undefined;
    }

    return `{"storeId":"${this.storeIdAzure}","apiUrl":"${apimUrl}","env":"${process.env.VUE_APP_B2C_ENV}","apimKey":"${apimKey}","isPro":true}`;
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get isNew(): boolean {
    return this.$route.query.action === ViewActions.Add;
  }

  get timezones(): TimezoneAutoCompleteEntry[] {
    return Object.keys(spacetime.timezones()).map((timezone: string) => {
      // get GMT offset and add offset as prefix to display text (note that the GMT offset is DST-aware and uses the timezone based on the current datetime)
      const offset: number = spacetime().goto(timezone).timezone().current.offset;
      const offsetPrefix: string = (offset >= 0 && `(GMT +${offset})`) || `(GMT ${offset})`;

      const timezoneFormatted: string = formatTimezone(timezone);
      return {
        displayText: `${offsetPrefix} ${timezoneFormatted}`,
        iana: timezoneFormatted,
      };
    });
  }

  get areDevicesSyncing(): boolean {
    return this.devicesStore.isFetching || this.store.isStoreSyncing;
  }

  get storeActiveHourJobFailed(): boolean {
    const currentStore: Optional<Store> = this.storesStore.getStoreById(this.storeId);
    if (!currentStore) {
      return false;
    }
    return currentStore.activeHoursJobFailed || false;
  }
}
