
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Device, EventLog } from '@client/models';
import { EventLogsStore, useEventLogsStore } from '@client/stores/eventLogs';
import { DeviceStatusEventType, EventType } from '@common/eventlog/types';
import Moment from 'moment/moment';
import { TranslateResult } from 'vue-i18n';
import { SearchResponse } from '@client/stores/eventLogs/types';
import InfiniteScroll from '@client/components/InfiniteScroll/InfiniteScroll.vue';
import { SectionActiveHoursDeviceEvent } from '@client/models/EventLogModels/SectionActiveHoursEventLog/SectionActiveHoursDeviceEvent.model';
import { StoreActiveHoursDeviceEvent } from '@client/models/EventLogModels/StoreActiveHoursEventLog/StoreActiveHoursDeviceEvent.model';
import { SectionActiveHoursEventLog } from '@client/models/EventLogModels/SectionActiveHoursEventLog/SectionActiveHoursEventLog.model';
import { StoreActiveHoursEventLog } from '@client/models/EventLogModels/StoreActiveHoursEventLog/StoreActiveHoursEventLog.model';
import { Optional } from '@common/types';

@Component({
  components: { InfiniteScroll },
})
export default class DeviceEvents extends Vue {
  /* DECLARATIONS */
  @Prop()
  private device!: Device;
  private eventLogs: Array<EventLog> = [];
  private totalCount: number = 0;
  private page: number = 0;
  private isLoading: boolean = true;
  private readonly defaultSearchTypes: Array<EventType> = [
    EventType.STORE_ACTIVE_HOURS,
    EventType.SECTION_ACTIVE_HOURS,
    EventType.ONLINE_STATUS,
    EventType.PUBLISHING,
    EventType.REGISTERED_STATUS,
  ];

  private eventLogStore: EventLogsStore = useEventLogsStore();
  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    await this.initializeComponent();
  }

  @Watch('device')
  async initializeComponent(): Promise<void> {
    const searchResult: SearchResponse = await this.eventLogStore.search(
      {
        searchTerm: this.device.longId,
        types: this.defaultSearchTypes,
      },
      this.page
    );
    this.page += 1;
    this.eventLogs = searchResult.eventLogs;
    this.totalCount = searchResult.totalCount;
    this.isLoading = false;
  }
  /* METHODS */
  getEventLogIcon(eventLog: EventLog): string {
    switch (eventLog.type) {
      case EventType.STORE_ACTIVE_HOURS:
        return 'mdi-lightbulb-on';
      case EventType.SECTION_ACTIVE_HOURS:
        return 'mdi-lightbulb-on';
      case EventType.ONLINE_STATUS:
        return 'mdi-signal-variant';
      case EventType.PUBLISHING:
        return 'mdi-publish';
      case EventType.STORE_SYNC:
        return 'mdi-sync';
      case EventType.REGISTERED_STATUS:
        return 'mdi-note-edit';
      default:
        return 'mdi-publish';
    }
  }

  getEventLogDate(eventLog: EventLog): string {
    const createdAt: Date = new Date(eventLog.createdAt);
    const moment: typeof Moment = Moment;
    moment.locale?.(this.$i18n.locale);
    return `${moment(createdAt).format('LL')} ${moment(createdAt).format('LTS')}`;
  }

  getEventLogTitle(eventLog: EventLog): TranslateResult {
    if (eventLog.isPublishingEventLog()) {
      return this.$t(this.$i18nTranslationKeys.notifications.eventType.templatePublished.$path);
    } else if (eventLog.isRegistrationStatusEventLog()) {
      return eventLog.deviceStatus === DeviceStatusEventType.REGISTERED
        ? this.$t(this.$i18nTranslationKeys.notifications.eventType.registered.$path)
        : this.$t(this.$i18nTranslationKeys.notifications.eventType.unregistered.$path);
    } else if (eventLog.isOnlineStatusEventLog()) {
      return eventLog.deviceStatus === DeviceStatusEventType.ONLINE
        ? this.$t(this.$i18nTranslationKeys.notifications.eventType.online.$path)
        : this.$t(this.$i18nTranslationKeys.notifications.eventType.offline.$path);
    } else if (eventLog.isStoreActiveHoursEventLog()) {
      return this.getMessageFromDevice(eventLog);
    } else if (eventLog.isSectionActiveHoursEventLog()) {
      return this.getMessageFromDevice(eventLog);
    } else if (eventLog.isStoreSyncEventLog()) {
      return this.$t(this.$i18nTranslationKeys.notifications.storeSync.deviceSynced.$path);
    }

    return '';
  }

  getMessageFromDevice(eventLog: SectionActiveHoursEventLog | StoreActiveHoursEventLog): string {
    let deviceEvent: Optional<SectionActiveHoursDeviceEvent> | Optional<StoreActiveHoursDeviceEvent>;
    if (eventLog.isSectionActiveHoursEventLog()) {
      deviceEvent = eventLog.devices.find(
        (device: SectionActiveHoursDeviceEvent) => device.longId === this.device.longId
      );
    } else {
      deviceEvent = eventLog.devices.find(
        (device: StoreActiveHoursDeviceEvent) => device.longId === this.device.longId
      );
    }
    if (!deviceEvent) {
      return '';
    }
    return ` ${this.$tc(this.$i18nTranslationKeys.notifications.screenStatusMessage.$path, deviceEvent.dimmingLevel)}`;
  }

  async loadMore(): Promise<void> {
    if ((this.eventLogs.length < this.totalCount || this.page === 0) && !this.eventLogStore.isSearchingDevice) {
      const searchResult: SearchResponse = await this.eventLogStore.search(
        {
          searchTerm: this.device.longId,
          types: this.defaultSearchTypes,
        },
        this.page
      );
      this.page += 1;
      this.eventLogs = this.eventLogs.concat(searchResult.eventLogs);
    }
  }
  /* GETTERS */
  get isSearching(): boolean {
    return this.eventLogStore.isSearchingDevice;
  }

  get canLoadMore(): boolean {
    return this.eventLogs.length < this.totalCount;
  }
}
