import { BaseError, ErrorType, ValidationErrorSeverity } from '../error/types';
import { Optional } from '../types';
import { DeviceJSON } from '@common/device/types';
import { StoreJSON } from './types';

/**
 * Definition of store specific error types
 */
export class StoreErrorType extends ErrorType {
  public static readonly GONDOLA_VALIDATION: ErrorType = new ErrorType('gondola_validation');
}

export interface GondolaValidationErrorDetails {
  severity?: ValidationErrorSeverity;
  fields: Optional<string>[][];
  duplicateDevices: DeviceJSON[];
}

export class DuplicateAzureIdError extends BaseError<StoreJSON> {
  constructor(store: StoreJSON, message: string = 'Found a Store with the same azure id') {
    super(message, 409, ErrorType.DUPLICATE, store);
  }
}

export class GondolaValidationError extends BaseError<GondolaValidationErrorDetails> {
  constructor(validationResult: GondolaValidationErrorDetails, message: string = 'Gondola validation failed') {
    super(message, 422, StoreErrorType.GONDOLA_VALIDATION, validationResult);
  }
}
