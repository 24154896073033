
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Optional } from '@common/types';
import { ContentItem } from '@client/models/ContentModels/types';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import {
  Ancestor,
  Ancestors,
  DEFAULT_ANCESTORS,
  MAXIMUM_NUMBER_OF_SEARCH_RESULTS,
  ROOT_NODE,
} from '@common/content-item/types';
import { TranslateResult } from 'vue-i18n';
import { SearchParams } from '@client/components/ContentItems/Search/Search.vue';
import { ContentType } from '@common/enums';
import { getAncestorName } from '@client/components/ContentItems/ListItems/utils';

@Component({
  methods: {
    getAncestorName,
  },
})
export default class ContentItemNavigation extends Vue {
  /* DECLARATIONS */
  @Prop()
  setSelectedFolder!: (folderId: string) => void;
  @Prop()
  selectedFolder!: string;
  @Prop()
  isSearchMode!: boolean;
  @Prop()
  numberOfItems!: number;
  @Prop()
  searchParams!: SearchParams;

  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  isDisabled(ancestor: Ancestor): boolean {
    return ancestor.id === this.selectedFolder || (ancestor.id === ROOT_NODE && !this.selectedFolder);
  }
  onAncestorClick(folderId: string): void {
    if (folderId === ROOT_NODE) {
      this.setSelectedFolder('');
    } else {
      this.setSelectedFolder(folderId);
    }
  }
  /* GETTERS */
  get ancestorsOfSelectedFolder(): Ancestors {
    return this.selectedFolderContentItem?.ancestors ?? [];
  }

  get selectedFolderContentItem(): Optional<ContentItem> {
    if (!this.selectedFolder) {
      return undefined;
    }
    return this.contentItemsStore.getContentItemById(this.selectedFolder);
  }

  get displayableAncestors(): Ancestors {
    if (this.ancestorsOfSelectedFolder.length > 2) {
      return this.ancestorsOfSelectedFolder.slice(-2);
    } else {
      return this.ancestorsOfSelectedFolder.slice(1);
    }
  }

  get rootFolder(): Ancestor {
    return DEFAULT_ANCESTORS[0];
  }
  get tooltipMessage(): string {
    if (!this.ancestorsOfSelectedFolder) {
      return '';
    }
    const foldersHidden: number = this.ancestorsOfSelectedFolder.length - this.displayableAncestors.length;
    return `/ ${this.ancestorsOfSelectedFolder
      .slice(1, foldersHidden)
      .map((ancestor: Ancestor) => `${getAncestorName(ancestor)}`)
      .join(' / ')} /`;
  }

  get searchResultsMessage(): TranslateResult {
    if (this.numberOfItems === MAXIMUM_NUMBER_OF_SEARCH_RESULTS) {
      return this.$tc(
        this.$i18nTranslationKeys.contentManagement.maximumResults.$path,
        MAXIMUM_NUMBER_OF_SEARCH_RESULTS
      );
    }
    return this.$tc(this.$i18nTranslationKeys.contentManagement.searchResults.$path, this.numberOfItems);
  }

  get searchQueryMessage(): string {
    const typeFilters: string = this.searchParams.typeFilter
      .map((filter: ContentType) => {
        return this.$t(this.$i18nTranslationKeys.contentManagement[filter].$path);
      })
      .join(',')
      .concat(':');

    return this.searchParams.typeFilter.length > 0
      ? `${typeFilters} "${this.searchParams.searchTerm}"`
      : `"${this.searchParams.searchTerm}"`;
  }
}
