
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import router from '@client/router';
import { Aisle, Gondola as GondolaModel, GondolaTemplate, Device, Store } from '@client/models';
import { Gondola, ModalDialog, OpenTemplateStoreButton } from '@client/components';
import { validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { ErrorType } from '@common/error/types';
import { Optional } from '@common/types';
import SectionActiveHours from '@client/components/ActiveHours/SectionActiveHours.vue';
import StoreAndSectionActiveHours from '@client/models/ActiveHoursModels/StoreAndSectionActiveHours';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { equals, isStoreActiveHoursInDefaultState } from '@client/utils/ActiveHoursUtils';
import SectionActiveHoursSchedule from '@client/models/ActiveHoursModels/SectionActiveHoursSchedule';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { ErrorStore, useErrorStore, ErrorObserver } from '@client/stores/error';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import SearchTextField from '@client/components/Filters/SearchTextField/SearchTextField.vue';
import DoubleIcon from '@client/components/DoubleIcon/DoubleIcon.vue';
import PublishButton from './Buttons/PublishButton.vue';
import EditGondolaButton from '@client/components/StoreDetail/Buttons/EditGondolaButton.vue';
import DeleteGondolaButton from '@client/components/StoreDetail/Buttons/DeleteGondolaButton.vue';
import CreateTemplateFromSectionButton from '@client/components/StoreDetail/Buttons/CreateTemplateFromSectionButton.vue';
import AislePublishingBadge from '@client/components/StoreDetail/AislePublishingBadge.vue';

@Component({
  components: {
    AislePublishingBadge,
    CreateTemplateFromSectionButton,
    DeleteGondolaButton,
    EditGondolaButton,
    PublishButton,
    SearchTextField,
    SectionActiveHours,
    Gondola,
    ModalDialog,
    OpenTemplateStoreButton,
    DoubleIcon,
  },
  methods: {
    validateTextFieldLength,
  },
})
export default class StoreDetailGondolas extends Vue {
  private static readonly ERROR_OBSERVER_KEY: string = 'StoreDetailGondolas';
  @Prop()
  private store!: Store;
  private aisleSearchQuery: string = '';
  private sectionSearchQuery: string = '';
  private panelIndex: number = -1;
  private showDeleteGondolaDialog: boolean = false;
  private showConfirmAzureIdDialog: boolean = false;
  private isActiveHoursSetFilter: boolean = false;
  private hideEmptyAisles: boolean = false;
  private VUE_ACCORDION_ANIMATION_DELAY: number = 700;
  private cancelHandler: () => void = () => {
    // Will be overridden later
  };
  private confirmHandler: () => void = () => {
    // Will be overridden later
  };
  /**
   * This function will return the confirmation dialog result to the component
   * If the user confirms he wants to edit the azure id it should return true
   */
  private confirmAzureIdUpdate: () => Promise<boolean> = () => {
    return new Promise<boolean>(() => true);
  };
  private storeAndSectionActiveHours: StoreAndSectionActiveHours = { storeActiveHours: [], sectionActiveHours: [] };

  private actionQueue: Promise<void> = Promise.resolve();

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private errorStore: ErrorStore = useErrorStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();

  /* LIFECYCLE EVENTS */

  async created(): Promise<void> {
    if (this.storeId) {
      this.storeAndSectionActiveHours =
        this.activeHoursStore.getActiveHoursByStoreId(this.storeId) ?? this.storeAndSectionActiveHours;
    }
    this.registerObserver();
    this.confirmAzureIdUpdate = async () => {
      this.storeAndSectionActiveHours =
        this.activeHoursStore.getActiveHoursByStoreId(this.storeId) ?? this.storeAndSectionActiveHours;
      const isActiveHoursSet: boolean = isStoreActiveHoursInDefaultState(
        this.storeAndSectionActiveHours.storeActiveHours
      );
      if (!isActiveHoursSet) {
        return true;
      }
      return new Promise<boolean>((resolve: (value: boolean | PromiseLike<boolean>) => void) => {
        this.showConfirmAzureIdDialog = true;
        this.cancelHandler = () => {
          resolve(false);
          this.showConfirmAzureIdDialog = false;
        };
        this.confirmHandler = () => {
          resolve(true);
          this.showConfirmAzureIdDialog = false;
        };
      });
    };
  }

  mounted(): void {
    this.openPreselectedAisleAndScroll();
  }

  beforeDestroy(): void {
    this.errorStore.deregister(StoreDetailGondolas.ERROR_OBSERVER_KEY);
  }

  registerObserver(): void {
    // register error handler when azureId is already assigned to another store
    ErrorObserver.create(StoreDetailGondolas.ERROR_OBSERVER_KEY)
      .attachHandler(ErrorType.UNEXPECTED_ERROR, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.genericUnexpected.$path),
        });
        this.storesStore.setUpdatingStoreLoadingIndicator(false);
        this.storesStore.setDeletingStoreLoadingIndicator(false);
      })
      .register();
  }

  openPreselectedAisleAndScroll(): void {
    if (!this.activeAisleName) {
      // No aisle is set, no need to scroll
      return;
    }

    const aisles: Array<string> = Array.from(this.aisleGroups.keys());
    // Find the index of the active aisle
    this.panelIndex = aisles.findIndex((aisle: string) => aisle === this.activeAisleName);
    if (this.panelIndex === -1) {
      // If the aisle is not found, do not scroll
      return;
    }
    let anchorSelector: string = `aisle-${this.activeAisleName}-anchor`;
    if (this.activePositionInAisleName) {
      // If a position in aisle is set, scroll to it
      anchorSelector = `section-${aisles[this.panelIndex]}-${this.activePositionInAisleName}-anchor`;
    }
    setTimeout(() => {
      const aisleHeader: HTMLElement | null = document.getElementById(anchorSelector);
      aisleHeader?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }, this.VUE_ACCORDION_ANIMATION_DELAY);
  }

  /* METHODS */

  @Watch('isLoading')
  setStoreAndSectionActiveHours(): void {
    if (this.storeId && !this.isLoading) {
      this.storeAndSectionActiveHours =
        this.activeHoursStore.getActiveHoursByStoreId(this.storeId) ?? this.storeAndSectionActiveHours;
    }
  }

  filterAisles(gondolas?: GondolaModel[]): GondolaModel[] {
    if (!gondolas) {
      return [];
    }
    let filteredGondolas: GondolaModel[] = gondolas;
    // No filters are set
    if (!this.isActiveHoursSetFilter && !this.sectionSearchQuery) {
      return filteredGondolas;
    }
    // Apply active hours filter
    if (this.isActiveHoursSetFilter) {
      filteredGondolas = filteredGondolas.filter((gondola: GondolaModel) => {
        const gondolaActiveHours: Optional<SectionActiveHoursSchedule> =
          this.storeAndSectionActiveHours.sectionActiveHours.find(
            (item: SectionActiveHoursSchedule) => item.gondolaId === gondola._id
          );
        return !!(
          gondolaActiveHours &&
          !equals(this.storeAndSectionActiveHours.storeActiveHours, gondolaActiveHours.activeHours)
        );
      });
    }
    if (!this.sectionSearchQuery) {
      return filteredGondolas;
    }
    // Apply search query filter on gondolas
    return filteredGondolas.filter((gondola: GondolaModel) =>
      gondola.positionInAisle.toLowerCase().includes(this.sectionSearchQuery.toLowerCase())
    );
  }

  onNewGondola(): void {
    router.push(`/stores/${this.storeId}/gondola`);
  }

  /**
   * Checks if the gondola already has a published template
   * @param gondola gondola to check
   */
  isGondolaAlreadyPublishedWithTemplate(gondola: GondolaModel): boolean {
    return !!gondola.lastPublishing?.gondolaTemplateId;
  }

  /**
   * Checks if the published template still exists
   * @param gondola gondola to check
   */
  isTemplatePresent(gondola: GondolaModel): boolean {
    const templateId: Optional<string> = gondola.lastPublishing?.gondolaTemplateId;
    if (!templateId) {
      return false;
    }
    const template: Optional<GondolaTemplate> = this.gondolaTemplatesStore.getById(templateId);
    return !!template;
  }

  deleteGondola(gondolaId: string): void {
    this.showDeleteGondolaDialog = true;
    this.confirmHandler = () => {
      this.actionQueue = this.actionQueue.then(() => {
        return this.storesStore.deleteGondola(gondolaId, this.storeId);
      });
      this.showDeleteGondolaDialog = false;
    };
    this.cancelHandler = () => {
      this.showDeleteGondolaDialog = false;
    };
  }

  getSectionActiveHours(gondolaId: string): ActiveHoursSchedule {
    const sectionActiveHours: Optional<SectionActiveHoursSchedule> =
      this.storeAndSectionActiveHours.sectionActiveHours.find(
        (item: SectionActiveHoursSchedule) => item.gondolaId === gondolaId
      );
    if (!sectionActiveHours) {
      return this.storeAndSectionActiveHours.storeActiveHours;
    }
    return sectionActiveHours.activeHours;
  }

  getLastPublishingTemplateId(gondola: GondolaModel): Optional<string> {
    return gondola.lastPublishing?.gondolaTemplateId || undefined;
  }

  /**
   * Checks if the section has at least one valid device id set, otherwise no action can be performed
   * @param railGrid rail grid of the section to check
   */
  isSectionOperable(railGrid: Array<Array<Device>>): boolean {
    return !railGrid.flat(1).some((device: Device) => device.shortId);
  }

  /* GETTERS */

  get isStoreAzureIdSet(): boolean {
    return !this.store.idAzure;
  }

  get isLoading(): boolean {
    return (
      this.activeHoursStore.loadingIndicator.update ||
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update ||
      this.gondolaTemplatesStore.loadingIndicator.update
    );
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get activeAisleName(): string {
    return this.$route.query.aisle as string;
  }

  get activePositionInAisleName(): string {
    return this.$route.query.position as string;
  }

  /**
   * Get all gondolas for the currently selected store, grouped by aisle
   */
  get aisleGroups(): Map<string, Array<GondolaModel>> {
    const groupedGondolas: Map<string, Array<GondolaModel>> = new Map<string, Array<GondolaModel>>();
    if (!this.store) {
      return groupedGondolas;
    }

    const storeAisles: Set<string> = new Set<string>();
    // Only add aisles that are included in the aisles array (Sanity check)
    this.store.aisles.forEach((aisle: Aisle) => {
      if (aisle.name.toLowerCase().includes(this.aisleSearchQuery.toLowerCase())) {
        storeAisles.add(aisle.name);
      }
    });
    // Loop through the gondolas and regroup them by aisle
    for (const gondola of this.store.gondolas) {
      const aisle: string = gondola.aisle;
      if (!storeAisles.has(aisle)) {
        continue;
      }
      // Initialize the grouped gondolas by aisle
      if (!groupedGondolas.get(aisle)) {
        groupedGondolas.set(aisle, []);
      }
      // Add the gondola to the group
      groupedGondolas.get(aisle)?.push(gondola);
    }
    const result: Map<string, Array<GondolaModel>> = new Map<string, Array<GondolaModel>>();

    for (const aisle of groupedGondolas.keys()) {
      // Apply the set filters
      const gondolasInAisle: Array<GondolaModel> = this.filterAisles(groupedGondolas.get(aisle));
      // If this is empty and hide empty is active, or search is active, ignore this from the results
      if ((this.hideEmptyAisles || this.aisleSearchQuery) && gondolasInAisle.length === 0) {
        continue;
      }
      // Apply sorting
      gondolasInAisle.sort((a: GondolaModel, b: GondolaModel) =>
        a.positionInAisle.toLowerCase() < b.positionInAisle.toLowerCase() ? -1 : 1
      );
      // Add the final result to the set
      result.set(aisle, gondolasInAisle);
    }
    return result;
  }
}
