
import { Component, Vue } from 'vue-property-decorator';
import StoreDetailHeader from '@client/components/StoreDetail/StoreDetailHeader.vue';
import StoreDetailGondolas from '@client/components/StoreDetail/StoreDetailGondolas.vue';
import StoreDetailHeaderLoader from '@client/components/StoreDetail/StoreDetailHeaderLoader.vue';
import StoreDetailGondolasLoader from '@client/components/StoreDetail/StoreDetailGondolasLoader.vue';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Optional } from '@common/types';
import { Store } from '@client/models';
import StoreNotFound from '@client/components/NotFound/StoreNotFound.vue';

@Component({
  components: {
    StoreDetailGondolasLoader,
    StoreDetailHeaderLoader,
    StoreDetailGondolas,
    StoreDetailHeader,
    StoreNotFound,
  },
})
export default class StoreDetailView extends Vue {
  private isDataLoaded: boolean = false;
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();

  async created(): Promise<void> {
    try {
      if (this.storeId) {
        await Promise.all([this.schedulesStore.fetch(), this.gondolaTemplatesStore.fetch()]);
        await this.storesStore.fetchStore(this.storeId);
        await this.storesStore.populateDevices(this.storeId);
        await Promise.all([
          this.activeHoursStore.fetchActiveHours(this.storeId),
          this.storesStore.fetchPublishings(this.storeId),
        ]);
      }
    } catch (e) {
      console.error(e);
    }

    this.isDataLoaded = true;
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get store(): Optional<Store> {
    return this.storesStore.getStoreById(this.storeId);
  }
}
