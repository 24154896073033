import BaseContentItemModel from '@client/models/ContentModels/BaseContentItem.model';
import { ContentType, LayerVisibility } from '@common/enums';
import {
  Ancestors,
  NonPopulatedPlaylistItemJSON,
  NonPopulatedPlaylistJSON,
  NonPopulatedPlaylistWithIdJSON,
  PopulatedPlaylistItemJSON,
  PopulatedPlaylistJSON,
} from '@common/content-item/types';
import VideoModel from '@client/models/ContentModels/Video.model';
import { LayerPlaylistItem } from '@client/models';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';

export interface PlaylistItem {
  contentItem: VideoModel;
  hideLabels: boolean;
}

export default class PlaylistModel extends BaseContentItemModel {
  duration: number = 0;
  playlist: Array<PlaylistItem> = [];
  constructor(
    id: string,
    customerId: string,
    name: string,
    hash: string,
    type: ContentType,
    playlist: Array<PlaylistItem> = [],
    duration: number = 0,
    parentFolder?: string,
    ancestors?: Ancestors
  ) {
    super(id, customerId, name, hash, type, parentFolder, ancestors);
    this.playlist = playlist;
    this.duration = duration;
  }

  public static getDefaultEmptyPlaylist(): PlaylistModel {
    return new PlaylistModel('', '', '', '', ContentType.Playlist, [], 0);
  }

  fromJSON(playlistJSON: PopulatedPlaylistJSON): PlaylistModel {
    const playlist: PlaylistModel = PlaylistModel.getDefaultEmptyPlaylist();
    playlist.setCustomerId(playlistJSON.customerId);
    playlist.setName(playlistJSON.name);
    playlist.setFileType(playlistJSON.type);
    playlist.setHash(playlistJSON.hash);
    playlist.setParentFolder(playlistJSON.parentFolder);
    playlist.playlist = playlistJSON.playlist.map((value: PopulatedPlaylistItemJSON) => {
      const playlistContentItem: VideoModel = VideoModel.fromJSON(value.contentItem);
      return {
        contentItem: playlistContentItem,
        hideLabels: value.hideLabels,
      };
    });
    playlist.duration = playlist.playlist.reduce((sum: number, playlistItem: PlaylistItem) => {
      return sum + playlistItem.contentItem.duration;
    }, 0);
    playlist._id = playlistJSON._id;
    playlist.setCreatedAt();
    return playlist;
  }

  toBaseLayerPlaylistItems(): Array<LayerPlaylistItem> {
    return this.playlist.map(
      (playlistItem: PlaylistItem) =>
        new LayerPlaylistItem(
          playlistItem.contentItem._id,
          playlistItem.contentItem.name,
          playlistItem.contentItem.url,
          playlistItem.contentItem.previewUrl,
          playlistItem.contentItem.type,
          playlistItem.contentItem.height,
          playlistItem.contentItem.width,
          playlistItem.contentItem.duration,
          playlistItem.contentItem.fps,
          playlistItem.contentItem.offsetX,
          playlistItem.contentItem.offsetY,
          playlistItem.contentItem.zIndex,
          playlistItem.contentItem.checksum,
          playlistItem.hideLabels,
          ''
        )
    );
  }

  public static newPlaylistContentItemBuilder(
    playlistName: string,
    playlistItems: Array<PlaylistItem>,
    customerId: string,
    parentFolder?: string
  ): PlaylistModel {
    const playlist: PlaylistModel = this.getDefaultEmptyPlaylist();
    if (parentFolder) {
      playlist.parentFolder = parentFolder;
    }
    playlist.playlist = playlistItems;
    playlist.duration = playlistItems.reduce((sum: number, playlistItem: PlaylistItem) => {
      return sum + playlistItem.contentItem.duration;
    }, 0);
    playlist.setName(playlistName);
    playlist.setCustomerId(customerId);
    return playlist;
  }

  public toJSON(): NonPopulatedPlaylistWithIdJSON {
    return {
      ...super.toJSON(),
      playlist: this.playlist.map(
        (item: PlaylistItem): NonPopulatedPlaylistItemJSON => ({
          hideLabels: item.hideLabels,
          contentItem: item.contentItem._id,
        })
      ),
    };
  }

  public toNewJSON(): NonPopulatedPlaylistJSON {
    const { _id, ...newPlaylistJSON }: NonPopulatedPlaylistWithIdJSON = this.toJSON();
    return newPlaylistJSON;
  }

  public static fromJSON(playlistJSON: PopulatedPlaylistJSON): PlaylistModel {
    const playlist: PlaylistModel = PlaylistModel.getDefaultEmptyPlaylist();
    playlist.setCustomerId(playlistJSON.customerId);
    playlist.setName(playlistJSON.name);
    playlist.setFileType(playlistJSON.type);
    playlist.setHash(playlistJSON.hash);
    playlist.setParentFolder(playlistJSON.parentFolder);
    playlist.setAncestors(playlistJSON.ancestors);
    playlist._id = playlistJSON._id;
    playlist.setCreatedAt();
    playlist.playlist = playlistJSON.playlist.map((value: PopulatedPlaylistItemJSON): PlaylistItem => {
      const playlistContentItem: VideoModel = VideoModel.fromJSON(value.contentItem);
      playlistContentItem._id = value.contentItem._id;
      playlistContentItem.createdAt = new Date(parseInt(playlistContentItem._id.substring(0, 8), 16) * 1000);
      return {
        contentItem: playlistContentItem,
        hideLabels: value.hideLabels,
      };
    });
    playlist.duration = playlistJSON.playlist.reduce((sum: number, playlistItem: PopulatedPlaylistItemJSON) => {
      return sum + (playlistItem.contentItem.duration ?? 0);
    }, 0);
    return playlist;
  }

  public toBaseLayer(): BaseLayerModel {
    return new BaseLayerModel(
      this._id,
      this.name,
      '',
      '',
      this.type,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      '',
      undefined,
      this.playlist.map((playlistItem: PlaylistItem) =>
        playlistItem.contentItem.toPlaylistLayer(playlistItem.hideLabels)
      )
    );
  }

  public toScheduledLayer(): ScheduleLayerModel {
    return new ScheduleLayerModel(
      this.name,
      '',
      '',
      this.type,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      '',
      undefined,
      LayerVisibility.VISIBLE,
      []
    );
  }

  public clone(): PlaylistModel {
    return new PlaylistModel(
      this._id,
      this.customerId,
      this.name,
      this.hash || '',
      this.type,
      this.playlist,
      this.duration,
      this.parentFolder,
      this.ancestors
    );
  }
}
