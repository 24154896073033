
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  PublishingDeviceEvent,
  DevicePublishing,
  GondolaPublishing,
  GondolaTemplate,
  LayerPlaylistItem,
  Store,
} from '@client/models';
import { getCreatedAtFormatted, getRelativeTimeFromNow } from '@client/utils/DateTimeUtils';
import { PublishingDeviceEventState, EventState } from '@common/eventlog/types';
import { Optional } from '@common/types';
import NotificationTimeDisplay from '../NotificationTimeDisplay.vue';
import { scrollToNotification } from '@client/components/NotificationPanel/utils';
import PublishingNotificationEntry from '@client/components/NotificationPanel/Notifications/PublishingNotificationEntry.vue';
import { ContentType } from '@common/enums';
import NotificationTitle from '@client/components/NotificationPanel/NotificationTitle.vue';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import DevicePublishingScheduledContentModel from '@client/models/DeviceModels/DevicePublishingScheduledContentModel';
import { TranslateResult } from 'vue-i18n';
import { PublishingEventLog } from '@client/models/EventLogModels/PublishingEventLog/PublishingEventLog.model';
import { NotificationEntryMessage } from '@client/models/EventLogModels/EventLog.common';
import { getStoreDetailPath } from '@client/router/utils';
import PublishingNextRetryCountdown from '@client/components/NotificationPanel/Notifications/PublishingNextRetryCountdown.vue';

@Component({
  methods: {
    getCreatedAtFormatted,
    getRelativeTimeFromNow,
  },
  components: { NotificationTitle, PublishingNotificationEntry, NotificationTimeDisplay, PublishingNextRetryCountdown },
})
export default class PublishingNotification extends Vue {
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: PublishingEventLog;
  @Prop()
  private readToken!: string;
  private devicesExpanded: boolean = false;
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private storesStore: StoresStore = useStoresStore();

  get numberOfDevices(): number {
    return this.eventLog.devices.length;
  }

  get completedDeviceEvents(): Array<PublishingDeviceEvent> {
    return this.eventLog.deviceEvents.get(EventState.COMPLETED) || [];
  }

  get inProgressDeviceEvents(): Array<PublishingDeviceEvent> {
    return this.eventLog.deviceEvents.get(EventState.IN_PROGRESS) || [];
  }

  get acceptedDeviceEvents(): Array<PublishingDeviceEvent> {
    return this.eventLog.deviceEvents.get(EventState.ACCEPTED) || [];
  }

  get errorDeviceEvents(): Array<PublishingDeviceEvent> {
    return this.eventLog.deviceEvents.get(EventState.ERROR) || [];
  }

  get cancelledDeviceEvents(): Array<PublishingDeviceEvent> {
    return this.eventLog.deviceEvents.get(EventState.CANCELED) || [];
  }

  get dateToDisplay(): string | Date {
    return this.eventLog.devices?.[0]?.events?.[0]?.timestamp || this.eventLog.createdAt;
  }

  get notificationTitle(): string {
    if (this.gondolaPublishing.aisle) {
      return ` - ${this.$t(this.$i18nTranslationKeys.notifications.aisle.$path)} ${
        this.gondolaPublishing.aisle
      } - ${this.$t(this.$i18nTranslationKeys.notifications.section.$path)} ${this.gondolaPublishing?.positionInAisle}`;
    }
    return '';
  }

  get gondolaStore(): Optional<Store> {
    return this.storesStore.getStoreById(this.gondolaPublishing.storeId);
  }

  get gondolaPublishing(): GondolaPublishing {
    return this.eventLog.publishing;
  }

  get inProgressDevicesPercentage(): number {
    return (
      ((this.inProgressDeviceEvents.length + this.acceptedDeviceEvents.length + this.completedDeviceEvents.length) /
        this.numberOfDevices) *
      100
    );
  }

  get completedDevicesPercentage(): number {
    return this.cancelledDeviceEvents.length > 0
      ? 100
      : (this.completedDeviceEvents.length / this.numberOfDevices) * 100;
  }

  get notificationBadgesVisible(): boolean {
    return this.completedDeviceEvents.length !== this.eventLog.devices.length || this.devicesExpanded;
  }

  getDeviceFromPublishing(deviceLongId: string): Optional<DevicePublishing> {
    return this.gondolaPublishing?.railGrid
      ?.flat<DevicePublishing[][]>(1)
      .find((device: DevicePublishing) => device.longId === deviceLongId);
  }

  navigateToTemplate(): void {
    if (this.gondolaTemplate) {
      this.$router.push(`/template/${this.gondolaTemplate._id}`);
    }
  }

  get linkToStore(): string {
    if (!this.gondolaStore) {
      return '';
    }
    return getStoreDetailPath(
      this.gondolaStore._id,
      this.gondolaPublishing?.aisle,
      this.gondolaPublishing?.positionInAisle
    );
  }

  get notificationTitleIdentifier(): string {
    return this.gondolaStore?.name || this.gondolaPublishing?.storeIdAzure || this.eventLog.azureStoreId;
  }

  navigateToStore(): void {
    if (this.gondolaStore) {
      const link: string = getStoreDetailPath(
        this.gondolaStore._id,
        this.gondolaPublishing?.aisle,
        this.gondolaPublishing?.positionInAisle
      );
      if (this.$router.currentRoute.fullPath === link) {
        this.toggleDrawerVisibility();
        return;
      }
      this.$router.push(link);
    }
  }

  get gondolaTemplate(): Optional<GondolaTemplate> {
    const gondolaPublishing: GondolaPublishing = this.eventLog.publishing;
    if (!gondolaPublishing?.gondolaTemplateId) {
      return undefined;
    }
    return this.gondolaTemplatesStore.getById(gondolaPublishing.gondolaTemplateId);
  }

  get gondolaTemplateName(): TranslateResult {
    return (
      this.gondolaTemplate?.name || this.$t(this.$i18nTranslationKeys.notifications.publishedWithDeletedTemplate.$path)
    );
  }

  get areGondolaTemplatesFetched(): boolean {
    return this.gondolaTemplatesStore.fetched;
  }

  getHardwareModelFromDevicePublishing(devicePublishing: Optional<DevicePublishing>): string {
    return devicePublishing?.hardwareModel.identifier || '';
  }

  onNotificationClick(): void {
    if (!this.devicesExpanded) {
      this.devicesExpanded = true;
    }
  }

  onExpandToggleClick(): void {
    this.devicesExpanded = !this.devicesExpanded;
    if (!this.devicesExpanded) {
      scrollToNotification(this.eventLog._id);
    }
  }

  /**
   * Searches through the foreground content of the device and returns the appropriate message of the downloaded content with the given layerId
   * @param device Device publishing
   * @param layerId layer id to search for
   */
  getForegroundLayerDetails(device: DevicePublishing, layerId: string): NotificationEntryMessage | undefined {
    for (let i: number = 0; i < device.foregroundContent.length; i++) {
      if (
        device.foregroundContent[i].baseLayer.type !== ContentType.Playlist &&
        device.foregroundContent[i].baseLayer.layerId === layerId
      ) {
        return {
          message: `${this.$t(this.$i18nTranslationKeys.notifications.eventType.downloadingLabelContent.$path, {
            index: i + 1,
          })} :`,
          layer: device.foregroundContent[i].baseLayer,
        };
      } else {
        const itemInPlaylist: LayerPlaylistItem | undefined = device.foregroundContent[i].baseLayer.playlist.find(
          (playlistItem: LayerPlaylistItem) => playlistItem.layerId === layerId
        );
        if (itemInPlaylist) {
          return {
            message: `${this.$t(
              this.$i18nTranslationKeys.notifications.eventType.downloadingScheduledContentForLabel.$path,
              { index: i + 1 }
            )} :`,
            layer: itemInPlaylist,
          };
        }
      }
      const foregroundScheduledContent: DevicePublishingScheduledContentModel | undefined = device.foregroundContent[
        i
      ]?.scheduledContent?.find(
        (content: DevicePublishingScheduledContentModel) =>
          content.layer.layerId === layerId ||
          content.layer.playlist.some((playlistItem: LayerPlaylistItem) => playlistItem.layerId === layerId)
      );
      if (foregroundScheduledContent) {
        if (foregroundScheduledContent.layer.type === ContentType.Playlist) {
          return {
            message: `${this.$t(
              this.$i18nTranslationKeys.notifications.eventType.downloadingScheduledContentForLabel.$path,
              { index: i + 1 }
            )} :`,
            layer: foregroundScheduledContent.layer.playlist.find(
              (playlistItem: LayerPlaylistItem) => playlistItem.layerId === layerId
            ),
          };
        }
        return {
          message: `${this.$t(
            this.$i18nTranslationKeys.notifications.eventType.downloadingScheduledContentForLabel.$path,
            { index: i + 1 }
          )} :`,
          layer: foregroundScheduledContent.layer,
        };
      }
    }
    return undefined;
  }

  /**
   * Searches through the background content of the device and returns the appropriate message of the downloaded content with the given layerId
   * @param device Device publishing
   * @param layerId layer id to search for
   */
  getBackgroundLayerDetails(device: DevicePublishing, layerId: string): NotificationEntryMessage | undefined {
    if (
      device.backgroundContent[0]?.baseLayer?.layerId === layerId &&
      device.backgroundContent[0].baseLayer.type !== ContentType.Playlist
    ) {
      return {
        message: `${this.$t(this.$i18nTranslationKeys.notifications.eventType.downloadingBackgroundContent.$path)} :`,
        layer: device.backgroundContent[0]?.baseLayer,
      };
    } else {
      const itemInPlaylist: LayerPlaylistItem | undefined = device.backgroundContent[0]?.baseLayer?.playlist.find(
        (playlistItem: LayerPlaylistItem) => playlistItem.layerId === layerId
      );
      if (itemInPlaylist) {
        return {
          message: `${this.$t(this.$i18nTranslationKeys.notifications.eventType.downloadingBackgroundContent.$path)} :`,
          layer: itemInPlaylist,
        };
      }
    }
    const backgroundScheduledContent: DevicePublishingScheduledContentModel | undefined =
      device.backgroundContent[0]?.scheduledContent?.find(
        (content: DevicePublishingScheduledContentModel) =>
          content.layer.layerId === layerId ||
          content.layer.playlist.some((playlistItem: LayerPlaylistItem) => playlistItem.layerId === layerId)
      );
    if (backgroundScheduledContent) {
      if (backgroundScheduledContent.layer.type === ContentType.Playlist) {
        return {
          message: `${this.$t(
            this.$i18nTranslationKeys.notifications.eventType.downloadingScheduledContentForBackground.$path
          )} :`,
          layer: backgroundScheduledContent.layer.playlist.find(
            (playlistItem: LayerPlaylistItem) => playlistItem.layerId === layerId
          ),
        };
      }
      return {
        message: `${this.$t(
          this.$i18nTranslationKeys.notifications.eventType.downloadingScheduledContentForBackground.$path
        )} :`,
        layer: backgroundScheduledContent.layer,
      };
    }
  }

  getLayerDetails(deviceLongId: string, layerId: string): NotificationEntryMessage {
    const devicePublishing: Optional<DevicePublishing> = this.getDeviceFromPublishing(deviceLongId);
    if (!devicePublishing) {
      return {
        message: `${this.$t(this.$i18nTranslationKeys.notifications.eventType.syncingLayer.$path)}`,
        layer: undefined,
      };
    }
    const backgroundLayerDetails: NotificationEntryMessage | undefined = this.getBackgroundLayerDetails(
      devicePublishing,
      layerId
    );
    if (backgroundLayerDetails) {
      return backgroundLayerDetails;
    }
    const foregroundLayerDetails: NotificationEntryMessage | undefined = this.getForegroundLayerDetails(
      devicePublishing,
      layerId
    );
    if (foregroundLayerDetails) {
      return foregroundLayerDetails;
    }
    return {
      message: `${this.$t(this.$i18nTranslationKeys.notifications.eventType.syncingLayer.$path)}`,
      layer: undefined,
    };
  }

  getDeviceEventState(state: PublishingDeviceEventState | EventState): string {
    switch (state) {
      case PublishingDeviceEventState.SENT_TO_APIM:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.sentToAPIM.$path)}`;
      case EventState.ACCEPTED:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.accepted.$path)}`;
      case PublishingDeviceEventState.SENDING_TO_APIM:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.sendingToAPIM.$path)}`;
      case PublishingDeviceEventState.IN_PROGRESS:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.inProgress.$path)}`;
      case PublishingDeviceEventState.COMPLETED:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.completed.$path)}`;
      case PublishingDeviceEventState.ERROR:
        return `${this.$t(this.$i18nTranslationKeys.notifications.eventState.error.$path)}`;
      default:
        return '';
    }
  }

  get DeviceEventState(): typeof PublishingDeviceEventState {
    return PublishingDeviceEventState;
  }

  get publishingAttempts(): number {
    return this.eventLog.publishing.previousAttempts?.length || 0;
  }

  get isNextRetryAvailable(): boolean {
    return (
      !!this.eventLog.nextRunAt &&
      this.eventLog.nextRunAt > new Date() &&
      this.errorDeviceEvents.length > 0 &&
      (this.eventLog.publishing.previousAttempts?.length ?? 0) < 5
    );
  }
}
