import StoreAndSectionActiveHours from '@client/models/ActiveHoursModels/StoreAndSectionActiveHours';
import { ActiveHourItem, ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { Optional } from '@common/types';
import { defineStore, Store, StoreDefinition } from 'pinia';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  ActiveHourItemJSON,
  ActiveHoursScheduleJSON,
  ActiveHoursUpdateResultJSON,
  StoreAndSectionActiveHoursJSON,
} from '@common/active-hours/types';
import Config from '@client/utils/config';
import { Store as StoreModel } from '@client/models';
import SectionActiveHoursSchedule from '@client/models/ActiveHoursModels/SectionActiveHoursSchedule';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { ErrorResponse, ErrorType } from '@common/error/types';

export interface ActiveHoursActions {
  fetchActiveHours(storeId: string): Promise<void>;
  submitActiveHours(storeId: string, activeHoursSchedule: ActiveHoursSchedule): Promise<void>;
  submitSectionActiveHours(
    storeId: string,
    gondolaId: string,
    activeHoursSchedule: ActiveHoursSchedule
  ): Promise<boolean>;
  resetSectionActiveHours(storeId: string, gondolaId: string): Promise<void>;
  setUpdatingActiveHoursLoadingIndicator(status: boolean): void;
}

export interface ActiveHoursGetters {
  getActiveHoursByStoreId: (state: ActiveHoursState) => (storeId: string) => Optional<StoreAndSectionActiveHours>;
}
export interface ActiveHoursState {
  activeHoursScheduleMap: Map<string, StoreAndSectionActiveHours>;
  loadingIndicator: {
    update: boolean;
  };
  fetched: boolean;
}

export type ActiveHoursStoreDefinition = StoreDefinition<
  'activeHours',
  ActiveHoursState,
  ActiveHoursGetters,
  ActiveHoursActions
>;

export type ActiveHoursStore = Store<'activeHours', ActiveHoursState, ActiveHoursGetters, ActiveHoursActions>;

export const useActiveHoursStore: ActiveHoursStoreDefinition = defineStore('activeHours', {
  state: (): ActiveHoursState => ({
    activeHoursScheduleMap: new Map<string, StoreAndSectionActiveHours>(),
    loadingIndicator: {
      update: false,
    },
    fetched: false,
  }),
  getters: {
    getActiveHoursByStoreId:
      (state: ActiveHoursState) =>
      (storeId: string): Optional<StoreAndSectionActiveHours> => {
        return state.activeHoursScheduleMap.get(storeId);
      },
  },
  actions: {
    async fetchActiveHours(storeId: string): Promise<void> {
      const storesStore: StoresStore = useStoresStore();
      this.setUpdatingActiveHoursLoadingIndicator(true);
      const storeObject: Optional<StoreModel> = storesStore.getStoreById(storeId);
      if (!storeObject) {
        this.setUpdatingActiveHoursLoadingIndicator(false);
        return;
      }
      const hash: string = storeObject.hash;
      const response: AxiosResponse<StoreAndSectionActiveHoursJSON> = await axios.get(
        `${Config.getApiUrl()}/active-hours`,
        {
          params: {
            storeId,
            hash,
          },
        }
      );
      if (response.status !== 200) {
        this.setUpdatingActiveHoursLoadingIndicator(false);
        console.error(`Error syncing active hours for store with storeId ${storeId}`);
        return;
      }
      const storeAndSectionActiveHoursJSON: StoreAndSectionActiveHoursJSON = response.data;
      const storeAndSectionActiveHours: StoreAndSectionActiveHours =
        StoreAndSectionActiveHours.fromJSON(storeAndSectionActiveHoursJSON);
      const newHash: string = storeAndSectionActiveHoursJSON.storeActiveHours.storeHash;
      storesStore.updateStoreHash(storeId, newHash);
      this.activeHoursScheduleMap.set(storeId, storeAndSectionActiveHours);
      this.setUpdatingActiveHoursLoadingIndicator(false);
    },
    async submitActiveHours(storeId: string, activeHoursSchedule: ActiveHoursSchedule): Promise<void> {
      this.setUpdatingActiveHoursLoadingIndicator(true);
      const storesStore: StoresStore = useStoresStore();
      const activeHoursScheduleJSON: ActiveHoursScheduleJSON = activeHoursSchedule.map((item: ActiveHourItem) =>
        item.toJSON()
      );
      const storeObject: Optional<StoreModel> = storesStore.getStoreById(storeId);
      if (!storeObject) {
        return;
      }
      const hash: string = storeObject.hash;
      const response: AxiosResponse<ActiveHoursUpdateResultJSON> = await axios.post(
        `${Config.getApiUrl()}/active-hours`,
        { activeHoursSchedule: activeHoursScheduleJSON },
        {
          params: {
            storeId,
            hash,
          },
        }
      );
      if (response.status !== 200) {
        console.error(`Error updating active hours store with storeId ${storeId}`);
        return;
      }
      const storeActiveHoursJSON: ActiveHoursUpdateResultJSON = response.data;
      const storeActiveHours: ActiveHoursSchedule = storeActiveHoursJSON.activeHours.map((item: ActiveHourItemJSON) =>
        ActiveHourItem.fromJSON(item)
      );
      const newHash: string = storeActiveHoursJSON.storeHash;
      storesStore.updateStoreHash(storeId, newHash);
      const storeAndSectionActiveHours: Optional<StoreAndSectionActiveHours> = this.activeHoursScheduleMap.get(storeId);
      if (!storeAndSectionActiveHours) {
        console.error('Missing active hours');
        return;
      }
      storeAndSectionActiveHours.storeActiveHours = storeActiveHours;
      this.activeHoursScheduleMap.set(storeId, storeAndSectionActiveHours);
      this.setUpdatingActiveHoursLoadingIndicator(false);
    },
    async submitSectionActiveHours(
      storeId: string,
      gondolaId: string,
      activeHoursSchedule: ActiveHoursSchedule
    ): Promise<boolean> {
      const storesStore: StoresStore = useStoresStore();
      this.setUpdatingActiveHoursLoadingIndicator(true);
      const activeHoursScheduleJSON: ActiveHoursScheduleJSON = activeHoursSchedule.map((item: ActiveHourItem) =>
        item.toJSON()
      );
      const storeObject: Optional<StoreModel> = storesStore.getStoreById(storeId);
      if (!storeObject) {
        return true;
      }
      const hash: string = storeObject.hash;
      try {
        const response: AxiosResponse<ActiveHoursUpdateResultJSON> = await axios.post(
          `${Config.getApiUrl()}/active-hours/${gondolaId}`,
          { activeHoursSchedule: activeHoursScheduleJSON },
          {
            params: {
              storeId,
              hash,
            },
          }
        );
        if (response.status !== 200) {
          console.error(`Error updating active hours store with storeId ${storeId}`);
          return true;
        }
        const sectionActiveHoursJSON: ActiveHoursUpdateResultJSON = response.data;
        const sectionActiveHours: ActiveHoursSchedule = sectionActiveHoursJSON.activeHours.map(
          (item: ActiveHourItemJSON) => ActiveHourItem.fromJSON(item)
        );
        const newHash: string = sectionActiveHoursJSON.storeHash;
        storesStore.updateStoreHash(storeId, newHash);
        const storeAndSectionActiveHours: Optional<StoreAndSectionActiveHours> =
          this.activeHoursScheduleMap.get(storeId);
        if (!storeAndSectionActiveHours) {
          console.error('Missing active hours');
          return true;
        }
        const currentSectionActiveHours: Optional<SectionActiveHoursSchedule> =
          storeAndSectionActiveHours.sectionActiveHours.find(
            (item: SectionActiveHoursSchedule) => item.gondolaId === gondolaId
          );
        if (!currentSectionActiveHours) {
          storeAndSectionActiveHours.sectionActiveHours.push({
            gondolaId: gondolaId,
            activeHours: sectionActiveHours,
          });
        } else {
          currentSectionActiveHours.activeHours = sectionActiveHours;
        }
        this.setUpdatingActiveHoursLoadingIndicator(false);
        return true;
      } catch (e: unknown) {
        const error: AxiosError<ErrorResponse> = e as AxiosError<ErrorResponse>;
        this.setUpdatingActiveHoursLoadingIndicator(false);
        return error.response?.data.errorType.name !== ErrorType.NO_VALID_DEVICE.name;
      }
    },
    async resetSectionActiveHours(storeId: string, gondolaId: string): Promise<void> {
      const storesStore: StoresStore = useStoresStore();
      this.setUpdatingActiveHoursLoadingIndicator(true);
      const storeObject: Optional<StoreModel> = storesStore.getStoreById(storeId);
      if (!storeObject) {
        return;
      }
      const hash: string = storeObject.hash;
      const response: AxiosResponse<StoreAndSectionActiveHoursJSON> = await axios.delete(
        `${Config.getApiUrl()}/active-hours/${storeId}/gondola/${gondolaId}`,
        {
          params: {
            hash,
          },
        }
      );
      if (response.status !== 200) {
        console.error(
          `Error while deleting active hours for section with gondolaId ${gondolaId} in store with storeId ${storeId}`
        );
        return;
      }
      const storeAndSectionActiveHoursJSON: StoreAndSectionActiveHoursJSON = response.data;
      const storeAndSectionActiveHours: StoreAndSectionActiveHours =
        StoreAndSectionActiveHours.fromJSON(storeAndSectionActiveHoursJSON);
      const newHash: string = storeAndSectionActiveHoursJSON.storeActiveHours.storeHash;
      storesStore.updateStoreHash(storeId, newHash);
      this.activeHoursScheduleMap.set(storeId, storeAndSectionActiveHours);
      this.setUpdatingActiveHoursLoadingIndicator(false);
    },
    setUpdatingActiveHoursLoadingIndicator(status: boolean): void {
      this.loadingIndicator.update = status;
    },
  },
});
