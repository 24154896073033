
import { Component, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import spacetime from 'spacetime';
import router from '@client/router';
import { ViewActions } from '@client/enums';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { StoresStore, useStoresStore } from '@client/stores/stores';

@Component({})
export default class StoreListToolbar extends Vue {
  /* DECLARATIONS */
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private storesStore: StoresStore = useStoresStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  async addStore(): Promise<void> {
    const storeId: string = await this.storesStore.add(
      new Store('', '', this.appGlobalStore.customer, spacetime().timezone().name, [])
    );
    await router.push({
      path: `/stores/${storeId}`,
      query: { action: ViewActions.Add },
    });
  }
  /* GETTERS */
}
