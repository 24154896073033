import { BaseEventLog } from '../EventLog.model';
import { ActiveHoursConfigEventLogJSON, EventType } from '@common/eventlog/types';
import Store from '../../StoreModels/Store.model';
import { Optional } from '@common/types';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { ActiveHourItemJSON } from '@common/active-hours/types';
import ActiveHourItem from '../../ActiveHoursModels/ActiveHourItem.model';

export class ActiveHoursConfigEventLog extends BaseEventLog {
  gondolaId?: string;
  activeHoursConfig?: ActiveHoursSchedule;

  constructor(
    id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    store: Optional<Store>,
    customerId: string,
    createdAt: Date,
    updatedAt: Date,
    activeHoursConfig?: ActiveHoursSchedule,
    gondolaId?: string
  ) {
    super(id, schemaVersion, type, azureStoreId, store, customerId, createdAt, updatedAt);
    this.gondolaId = gondolaId;
    this.activeHoursConfig = activeHoursConfig;
  }

  public static fromJSON(eventLogJSON: ActiveHoursConfigEventLogJSON): ActiveHoursConfigEventLog {
    const {
      _id,
      azureStoreId,
      store,
      customerId,
      type,
      updatedAt,
      createdAt,
      schemaVersion,
      gondolaId,
      activeHoursConfig,
    }: ActiveHoursConfigEventLogJSON = eventLogJSON;
    return new ActiveHoursConfigEventLog(
      _id,
      schemaVersion,
      type,
      azureStoreId,
      store ? Store.fromJSON(store) : undefined,
      customerId,
      createdAt,
      updatedAt,
      activeHoursConfig?.map((item: ActiveHourItemJSON) => ActiveHourItem.fromJSON(item)),
      gondolaId
    );
  }
}
